<template>
  <nav-bar :fillBG="true" />
  <div id="privacy">
    <div id="page_1">
      <div id="p1dimg1">
        <img
          alt=""
          src="data:image/jpg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4nICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDL/2wBDAQkJCQwLDBgNDRgyIRwhMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjL/wAARCAAUAAQDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD3qiiigAooooA//9k="
          id="p1img1"
        />
      </div>

      <div id="id1_1">
        <p class="p0 ft0">Privacy Policy</p>
        <p class="p1 ft1">
          This website www.napps-solutions.com is operated by N Apps, LDA (“N
          Apps”, “we”, “our” or “us”). N Apps has created this Privacy Policy to
          explain the way we collect, use, protect, share, discloser and process
          your personal information while operating our website.
        </p>
        <p class="p2 ft2">Where We Store Your Information</p>
        <p class="p3 ft1">
          By submitting your personal data, you give consent to transfer,
          process, and store your personal data inside and/or outside of your
          residence. We shall take reasonable steps to ensure your data to be
          protected securely in accordance with this privacy policy.
        </p>
        <p class="p2 ft2">Consent</p>
        <p class="p4 ft2">How do you get my consent?</p>
        <p class="p5 ft1">
          When you provide us with personal information to complete a
          transaction, verify your credit card, make a purchase or refund a
          purchase, we imply that you consent to our collecting it and using it
          for that specific reason only.
        </p>
        <p class="p6 ft1">
          If we ask for your personal information for a secondary reason, like
          marketing, we will either ask you directly for your expressed consent
          or provide you with an opportunity to say no.
        </p>
        <p class="p7 ft2">How do I withdraw my consent?</p>
        <p class="p8 ft1">
          If after you opt-in, you change your mind, you may withdraw your
          consent for us to contact you, for the continued collection, use or
          disclosure of your information, at any time, by sending an email to
          our Data Protection Officer Nuno Batista thought
          <A href="mailto:support@napps-solutions.com"
            ><span class="ft3">support@napps-solutions.com</span></A
          ><A href="mailto:support@napps-solutions.com">.</A>
        </p>
        <p class="p2 ft2">Information We Collect:</p>
        <p class="p9 ft1">
          We collect information when you register as a user of our Website:
        </p>
        <p class="p10 ft6">
          <span class="ft4">o</span
          ><span class="ft5"
            >Types of Data We Collect from you: “Personal Data” means data that,
            by itself, allows someone to identify or contact you as an
            individual, including, for example, your name, address, telephone
            number, </span
          >e-mail address, as well as any other non-public information about you
          that is associated with or linked to any of the foregoing data.
          “Anonymous Data” means data that does not, by itself, permit the
          identification of individual persons and that is not associated with
          or linked to Personal Data. We collect Personal Data and Anonymous
          Data, as described below.
        </p>
        <p class="p11 ft1">
          <span class="ft7">o</span
          ><span class="ft8">Information You Provide to Us.</span>
        </p>
        <p class="p12 ft1">
          <span class="ft9">▪</span
          ><span class="ft10">We may collect your name, </span>e-mail address,
          ecommerce store’s URL and password (encrypted) when you create an
          account to log in to our network (“Account”).
        </p>
      </div>
    </div>
    <div id="page_2">
      <div id="p2dimg1">
        <img
          alt=""
          src="data:image/jpg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4nICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDL/2wBDAQkJCQwLDBgNDRgyIRwhMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjL/wAARCAAUAAQDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD3qiiigAooooA//9k="
          id="p2img1"
        />
      </div>

      <div id="id2_1">
        <p class="p14 ft1">
          <span class="ft9">▪</span
          ><span class="ft10"
            >When you subscribe to our Services, we will collect all information
            necessary to complete the transaction, including your name, credit
            card information, and billing information.</span
          >
        </p>
        <p class="p15 ft1">
          <span class="ft9">▪</span
          ><span class="ft10">If you subscribe to our </span>e-mail newsletter,
          we will collect your e-mail address.
        </p>
        <p class="p16 ft1">
          <span class="ft9">▪</span
          ><span class="ft10"
            >If you provide us feedback or contact us via </span
          >e-mail, we will collect your name and e-mail address, as well as any
          other content included in your e- mail to us, in order to send you a
          reply.
        </p>
        <p class="p17 ft1">
          <span class="ft9">▪</span
          ><span class="ft10"
            >We may also collect Personal Data at other points in our Site or
            Services that state that Personal Data is being collected.</span
          >
        </p>
        <p class="p18 ft1">
          <span class="ft7">o</span
          ><span class="ft8"
            >Information You Provide to Third Party Accounts. If you sign in to
            your Account via Woocommerce, Shopify or a </span
          >third-party e-commerce platform provider
        </p>
        <p class="p19 ft1">
          (“Third Party Account”), you are allowing us to access information and
          content stored by the Third Party Account, including, without
          limitation, information relating to the products you sell in your
          online store, and you are agreeing to the
        </p>
        <p class="p20 ft12">
          Third Party Account’s terms of use in your use of the Services. We may
          receive information from the Third Party Account to make it easier for
          you to create an Account on the Site and provide you with the
          Services. Any information that we collect from your Third Party
          Account may depend on the privacy settings you have selected with that
          Third Party Account, so please consult the Third Party
        </p>
        <p class="p21 ft1">Account’s privacy policy and data practices.</p>
        <p class="p9 ft1">
          <span class="ft7">o</span
          ><span class="ft8">Information Collected via Technology.</span>
        </p>
        <p class="p22 ft12">
          <span class="ft9">▪</span
          ><span class="ft13"
            >Information Collected by Our Servers. To make our Site, Application
            and Services more useful to you, our servers (which may be hosted by
            a third- party service provider) may collect information from your
            computer or device, including but not limited to:</span
          >
        </p>
        <p class="p23 ft1">
          <span class="ft9">▪</span
          ><span class="ft10"
            >The date and time of your visit and the web pages and content you
            view and links you click on while navigating within our
            Services;</span
          >
        </p>
        <p class="p24 ft1">
          <span class="ft9">▪</span
          ><span class="ft10"
            >Information about the type of content accessed via our
            Services;</span
          >
        </p>
        <p class="p25 ft1">
          <span class="ft9">▪</span
          ><span class="ft10"
            >The site you visited before and after visiting our Site;</span
          >
        </p>
        <p class="p26 ft12">
          <span class="ft9">▪</span
          ><span class="ft13"
            >Your Internet Protocol (IP) address (a numerical address assigned
            to your computer by your Internet service provider so that other
            computers connected to the Internet can communicate with your
            browser online) that can sometimes be used to derive your general
            geographic area;</span
          >
        </p>
      </div>
    </div>
    <div id="page_3">
      <div id="id3_1">
        <p class="p27 ft1">
          <span class="ft9">▪</span
          ><span class="ft10"
            >Search terms you enter using our Services or on a referral
            site;</span
          >
        </p>
        <p class="p28 ft1">
          <span class="ft9">▪</span
          ><span class="ft10">Unique identifiers, including </span>non-global
          mobile device identification numbers;
        </p>
        <p class="p29 ft12">
          <span class="ft9">▪</span
          ><span class="ft13"
            >Information about your device such as your device type, screen
            size, browser type, language and other settings, memory capacity, </span
          >plug-ins, Internet domain, TCP configuration parameters, operating
          system, carrier code, time zone and the names, versions and package
          IDs of software installed on your device; and
        </p>
        <p class="p30 ft1">
          <span class="ft9">▪</span
          ><span class="ft10"
            >Information collected through cookies, pixel tags and other
            tracking technologies (see additional descriptions of these terms
            below).</span
          >
        </p>
        <p class="p31 ft12">
          <span class="ft9">▪</span
          ><span class="ft13"
            >Cookies. Like many online services, we use cookies to collect
            information. “Cookies” are small pieces of information that a
            website sends to your computer’s hard drive while you are viewing
            the website or application. We may use both session Cookies (which
            expire once you close your web browser) and persistent Cookies
            (which stay on your computer until you delete them) to provide you
            with a more personal and interactive experience on our Site and
            Services.</span
          >
        </p>
        <p class="p32 ft1">
          <span class="ft9">▪</span
          ><span class="ft10"
            >Pixel Tags. In addition, we use “Pixel Tags” (also referred to as
            clear Gifs,</span
          >
        </p>
        <p class="p33 ft6">
          Web beacons, or Web bugs). Pixel Tags are tiny graphic images with a
          unique identifier, similar in function to Cookies, that are used to
          track online movements of Web users. In contrast to Cookies, which are
          stored on a user’s computer hard drive, Pixel Tags are embedded
          invisibly in Web pages and e-mail messages. We may use Pixel Tags to
          collect information about your interactions with our email messages,
          such as the links you click on and whether you open or forward a
          message, the date and time of these interactions and the device you
          use to read emails.
        </p>
        <p class="p34 ft12">
          <span class="ft9">▪</span
          ><span class="ft13">Analytics Services. We use </span>third-party
          analytics services (“Analytics Services”), to help analyse how users
          use our Site, Application and Services. The information generated by
          the Cookies or other technologies about your use of our Site,
          Application and Services (the “Analytics Information”) is transmitted
          to the Analytics Services. The Analytics
        </p>
        <p class="p35 ft12">
          Services use Analytics Information to compile reports on user
          activity. The Analytics Services may also transfer the Analytics
          Information to third parties where required to do so by law, or where
          such third parties process Analytics Information on their behalf. Each
          Analytics Service’s ability to use and share Analytics Information is
          restricted by such Analytics Service’s terms of use and privacy
          policy. By using our Site, you
        </p>
      </div>
    </div>
    <div id="page_4">
      <div id="id4_1">
        <p class="p36 ft1">
          consent to the processing of data about you by Analytics Services in
          the manner and for the purposes set out above. For a full list of
          Analytics Services, please contact us at
          <A href="mailto:support@napps-solutions.com"
            ><span class="ft3">support@napps-solutions.com</span></A
          >
        </p>
        <p class="p37 ft1">
          <span class="ft9">▪</span
          ><span class="ft10"
            >Information Collected from You About Others.</span
          >
        </p>
        <p class="p38 ft6">
          <span class="ft9">▪</span
          ><span class="ft16"
            >If a customer purchases your products through a mobile application
            that was developed using our Services, we will collect information
            about such purchase, including name, </span
          >email-address, and products purchased (“Customer Data”). We rely upon
          you to obtain whatever consents from the third party that may be
          required by law to allow us to collect such information. You or the
          third party may contact us at
          <A href="mailto:support@napps-solutions.com"
            ><span class="ft17">support@napps-solutions.com</span></A
          >
          to request the removal of this information from our database.
        </p>
        <p class="p39 ft2">How We Use Your Information:</p>
        <p class="p40 ft1">
          We use the information we collect in various ways; our objective is to
          give you good service without any kind of hassle and legally binding.
          Using information includes:
        </p>
        <p class="p41 ft1">
          <span class="ft9">▪</span
          ><span class="ft10">Provide support to you</span>
        </p>
        <p class="p42 ft1">
          <span class="ft9">▪</span
          ><span class="ft10">Verify your identity to prevent fraud</span>
        </p>
        <p class="p11 ft1">
          <span class="ft9">▪</span
          ><span class="ft10">Provide, operate, and maintain our website</span>
        </p>
        <p class="p11 ft1">
          <span class="ft9">▪</span
          ><span class="ft10"
            >Improve, personalize, and expand our website</span
          >
        </p>
        <p class="p42 ft1">
          <span class="ft9">▪</span
          ><span class="ft10"
            >Understand and analyze how you use our website</span
          >
        </p>
        <p class="p11 ft1">
          <span class="ft9">▪</span
          ><span class="ft10"
            >Develop and protect new products, services, features, and
            functionality</span
          >
        </p>
        <p class="p43 ft1">
          <span class="ft9">▪</span
          ><span class="ft10"
            >Promote any of our services, promotion to you which you may have
            the interest, it will be notified to you after agreeing on the </span
          >opt-out option
        </p>
        <p class="p44 ft1">
          <span class="ft9">▪</span
          ><span class="ft10"
            >Send you communications by email. We may use email to share
            information about our site or to respond to support inquiries.</span
          >
        </p>
        <p class="p45 ft1">
          <span class="ft4">o</span><span class="ft18">Legal Binding:</span>
        </p>
        <p class="p46 ft1">
          <span class="ft9">▪</span
          ><span class="ft10"
            >Necessary to use for any legal purpose or inquire.</span
          >
        </p>
        <p class="p47 ft1">
          <span class="ft4">o</span><span class="ft18">Others:</span>
        </p>
        <p class="p48 ft1">
          <span class="ft9">▪</span
          ><span class="ft10"
            >User Testimonials. We often receive testimonials and comments from
            users who have had positive experiences with our products and
            Services. We occasionally publish such content.</span
          >
        </p>
      </div>
    </div>
    <div id="page_5">
      <div id="id5_1">
        <p class="p49 ft12">
          <span class="ft9">▪</span
          ><span class="ft13"
            >Creation of Anonymous Data. We may create Anonymous Data records
            from Personal Data and Customer Data by excluding information (such
            as name) that makes the data personally identifiable. We reserve the
            right to use Anonymous Data for any purpose and disclose Anonymous
            Data to third parties in our sole discretion.</span
          >
        </p>
        <p class="p50 ft2">Disclosure of Your Personal Data:</p>
        <p class="p51 ft1">
          We disclose your Personal Data as described below and as described
          elsewhere in this Privacy Policy.
        </p>
        <p class="p52 ft1">
          <span class="ft7">o</span><span class="ft8">Third-Party</span> Service
          Providers and Partners. We may share your Personal Data with payment
          processors and other third-party service providers and/or partners
          that assist us in the operation of our Services.
        </p>
        <p class="p53 ft12">
          <span class="ft7">o</span
          ><span class="ft19"
            >Affiliates. We may share some or all of your Personal Data with our
            parent company, subsidiaries, joint ventures, or other companies
            under common control with N Apps (“Affiliates”), in which case we
            will require our Affiliates to honour this Privacy Policy.</span
          >
        </p>
        <p class="p54 ft6">
          <span class="ft7">o</span
          ><span class="ft20"
            >Corporate Restructuring. We may share some or all of your Personal
            Data in connection with or during negotiation of any merger,
            financing, acquisition or dissolution transaction or proceeding
            involving the sale, transfer, or divestiture of all or a portion of
            our business or assets. In the event of an insolvency, bankruptcy,
            or receivership, Personal Data may also be transferred as a business
            asset. If another company acquires our company, business, or assets,
            that company will possess the Personal Data collected by us and will
            assume the rights and obligations regarding your Personal Data as
            described in this Privacy Policy.</span
          >
        </p>
        <p class="p55 ft6">
          <span class="ft7">o</span
          ><span class="ft20"
            >Other Disclosures. Regardless of any choices you make regarding
            your Personal Data (as described below), N Apps may disclose
            Personal Data if it believes in good faith that such disclosure is
            appropriate (a) in connection with any legal investigation or
            proceeding; (b) to comply with relevant laws or to respond to
            subpoenas or warrants served on N Apps; (c) to protect or defend the
            rights or property of N Apps, its affiliates or its users; and/or
            (d) to investigate or assist in preventing any violation or
            potential violation of the law, this Privacy Policy, or our Terms of
            Use.</span
          >
        </p>
        <p class="p39 ft2">
          Your Rights Regarding Personal Information of Yours:
        </p>
        <p class="p9 ft1">
          <span class="ft2">Access: </span>You have the right to access your
          personal data/information.
        </p>
        <p class="p9 ft1">
          <span class="ft2">Correction: </span>You have the right to correct
          your personal data/information.
        </p>
      </div>
    </div>
    <div id="page_6">
      <div id="id6_1">
        <p class="p13 ft1">
          <span class="ft2">Delete: </span>You have the right to delete your
          personal data/information.
        </p>
        <p class="p56 ft1">
          Other than stated above, in www.napps-solutions.com your Personal
          Information/data shall be protected by
          <span class="ft2"
            >CCPA (California Consumer Privacy Act) Privacy Rights </span
          >and <span class="ft2">GDPR Data Protection Rights </span>as long as
          we store your personal information/data.
        </p>
        <p class="p2 ft2">
          CCPA (California Consumer Privacy Act) Privacy Rights:
        </p>
        <p class="p4 ft1">
          Under the CCPA, among other rights, California consumers have the
          right to:
        </p>
        <p class="p57 ft12">
          <span class="ft4">o</span
          ><span class="ft21"
            >Request that a business that collects a consumer's personal data
            disclose the categories and specific pieces of personal data that a
            business has collected about consumers.</span
          >
        </p>
        <p class="p58 ft1">
          <span class="ft4">o</span
          ><span class="ft18"
            >Request that a business delete any personal data about the consumer
            that a business has collected.</span
          >
        </p>
        <p class="p59 ft1">
          <span class="ft4">o</span
          ><span class="ft18"
            >Request that a business that sells a consumer's personal data, not
            sell the consumer's personal data.</span
          >
        </p>
        <p class="p60 ft1">
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us.
        </p>
        <p class="p61 ft2">GDPR Data Protection Rights:</p>
        <p class="p4 ft1">
          If you are a resident of EEA, the following rights are entitled to
          you:
        </p>
        <p class="p62 ft1">
          <span class="ft4">o</span
          ><span class="ft18"
            >The right to access – You have the right to request copies of your
            personal data. We may charge you a small fee for this service.</span
          >
        </p>
        <p class="p63 ft12">
          <span class="ft4">o</span
          ><span class="ft21"
            >The right to rectification – You have the right to request that we
            correct any information you believe is inaccurate. You also have the
            right to request that we complete the information you believe is
            incomplete.</span
          >
        </p>
        <p class="p64 ft1">
          <span class="ft4">o</span
          ><span class="ft18"
            >The right to erasure – You have the right to request that we erase
            your personal data, under certain conditions.</span
          >
        </p>
      </div>
    </div>
    <div id="page_7">
      <div id="id7_1">
        <p class="p65 ft1">
          <span class="ft4">o</span
          ><span class="ft18"
            >The right to restrict processing – You have the right to request
            that we restrict the processing of your personal data, under certain
            conditions.</span
          >
        </p>
        <p class="p66 ft1">
          <span class="ft4">o</span
          ><span class="ft18"
            >The right to object to processing – You have the right to object to
            our processing of your personal data, under certain
            conditions.</span
          >
        </p>
        <p class="p67 ft12">
          <span class="ft4">o</span
          ><span class="ft21"
            >The right to data portability – You have the right to request that
            we transfer the data that we have collected to another organization,
            or directly to you, under certain conditions.</span
          >
        </p>
        <p class="p68 ft1">
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us.
        </p>
        <p class="p61 ft2">Your Choices Regarding Information:</p>
        <p class="p9 ft1">
          You have several choices regarding the use of information on our
          Service:
        </p>
        <p class="p69 ft6">
          <span class="ft7">o</span
          ><span class="ft20"
            >Email Communications: We may periodically send you free newsletters
            and e- mails that directly promote the use of our Site, Application,
            or Services. When you receive newsletters or promotional
            communications from us, you may indicate a preference to stop
            receiving further communications from us and you will have the
            opportunity to </span
          >“opt-out” by following the unsubscribe instructions provided in the
          e-mail you receive or by contacting us directly (please see contact
          information above). Despite your indicated preferences for commercial
          e-mail messages, we may continue to send you account or
          service-related communications, including notices of any updates to
          our Terms of Use or Privacy Policy.
        </p>
        <p class="p70 ft6">
          <span class="ft7">o</span
          ><span class="ft20"
            >Cookies: If you decide at any time that you no longer wish to
            accept Cookies for any of the purposes described above, then you can
            instruct your browser, by changing its settings, to stop accepting
            Cookies or to prompt you before accepting a Cookie from the websites
            you visit. Consult your browser’s technical information. If you do
            not accept Cookies, however, you may not be able to use all portions
            of the Service or all functionality of the Services. If you have any
            questions about how to disable or modify Cookies, please let us know
            at the contact information provided above.</span
          >
        </p>
        <p class="p71 ft1">
          <span class="ft7">o</span><span class="ft8">De-Linking</span> Third
          Party Accounts: If you decide at any time that you no longer wish to
          have your Third Party Account linked to your Account, please refer to
          the privacy settings of the Third Party Account to determine how you
          may adjust our
        </p>
      </div>
    </div>
    <div id="page_8">
      <div id="id8_1">
        <p class="p72 ft1">
          permissions and manage the interactivity between our Services and your
          Third Party Account(s). Please note that by de-linking your Account
          from a Third Party Account, you may not be able to access features and
          functions of the Services.
        </p>
        <p class="p2 ft2">Data Retention</p>
        <p class="p73 ft1">
          When you get a service from us, we will maintain your Information for
          our records unless and until you ask us to delete this information.
        </p>
        <p class="p7 ft2">Changes</p>
        <p class="p74 ft6">
          You are expected to recheck our privacy policy frequently. We may
          update this privacy policy from time to time in order to reflect. For
          example, changes to our practices or for other legal or regulatory
          reasons. We reserve the right to modify this privacy policy at any
          time. Changes will take effect immediately upon their posting on the
          website. If we make material changes to this policy, we will notify
          you here that it has been updated, so that you are aware of what
          information we collect, how we use it, and under what circumstances.
        </p>
        <p class="p39 ft2">Data Protection Officer</p>
        <p class="p9 ft1">
          Our Data Protection Officer’s name is Nuno Batista and can be
          contacted by support@napps-
        </p>
        <p class="p75 ft1">solutions.com</p>
        <p class="p9 ft2">Questions and Contact Information</p>
        <p class="p76 ft1">
          If you would like to: access, correct, amend or delete any personal
          information we have about you or simply want more information contact
          our Data Protection Officer or
          <A href="mailto:support@napps-solutions.com"
            ><span class="ft3">support@napps- solutions.com</span></A
          ><A href="mailto:support@napps-solutions.com">.</A>
        </p>
      </div>
    </div>
  </div>
  <footer2 :minify="true" />
</template>
<script>
import { defineComponent } from "vue";
import NavBar from "@/components/Navbar/Main.vue";
import Footer2 from "@/components/Footer/Main.vue";

export default defineComponent({
  components: {
    NavBar,
    Footer2,
  },
  data: function () {
    return {};
  },
  unmounted() {
    window.scrollTo(0, 0);
  },
  mounted() {},
  methods: {},
  computed: {},
  setup() {
    return {};
  },
});
</script>

<style scoped>
#privacy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

body {
  margin-top: 0px;
  margin-left: 0px;
}

#page_1 {
  position: relative;
  overflow: hidden;
  margin: 100px 0px 12px 96px;
  padding: 0px;
  border: none;
  width: 720px;
}
#page_1 #id1_1 {
  border: none;
  margin: 0px 0px 0px 0px;
  padding: 0px;
  border: none;
  width: 720px;
  overflow: hidden;
}
#page_1 #id1_2 {
  border: none;
  margin: 89px 0px 0px 176px;
  padding: 0px;
  border: none;
  width: 544px;
  overflow: hidden;
}

#page_1 #p1dimg1 {
  position: absolute;
  top: 499px;
  left: 253px;
  z-index: -1;
  width: 4px;
  height: 20px;
}
#page_1 #p1dimg1 #p1img1 {
  width: 4px;
  height: 20px;
}

#page_2 {
  position: relative;
  overflow: hidden;
  margin: 99px 0px 12px 168px;
  padding: 0px;
  border: none;
  width: 648px;
}
#page_2 #id2_1 {
  border: none;
  margin: 0px 0px 0px 0px;
  padding: 0px;
  border: none;
  width: 648px;
  overflow: hidden;
}
#page_2 #id2_2 {
  border: none;
  margin: 75px 0px 0px 104px;
  padding: 0px;
  border: none;
  width: 544px;
  overflow: hidden;
}

#page_2 #p2dimg1 {
  position: absolute;
  top: 272px;
  left: 43px;
  z-index: -1;
  width: 3px;
  height: 19px;
}
#page_2 #p2dimg1 #p2img1 {
  width: 3px;
  height: 19px;
}

#page_3 {
  position: relative;
  overflow: hidden;
  margin: 99px 0px 12px 216px;
  padding: 0px;
  border: none;
  width: 600px;
}
#page_3 #id3_1 {
  border: none;
  margin: 0px 0px 0px 0px;
  padding: 0px;
  border: none;
  width: 600px;
  overflow: hidden;
}
#page_3 #id3_2 {
  border: none;
  margin: 76px 0px 0px 56px;
  padding: 0px;
  border: none;
  width: 544px;
  overflow: hidden;
}

#page_4 {
  position: relative;
  overflow: hidden;
  margin: 99px 0px 12px 96px;
  padding: 0px;
  border: none;
  width: 720px;
}
#page_4 #id4_1 {
  border: none;
  margin: 0px 0px 0px 0px;
  padding: 0px;
  border: none;
  width: 720px;
  overflow: hidden;
}
#page_4 #id4_2 {
  border: none;
  margin: 85px 0px 0px 176px;
  padding: 0px;
  border: none;
  width: 544px;
  overflow: hidden;
}

#page_5 {
  position: relative;
  overflow: hidden;
  margin: 99px 0px 12px 96px;
  padding: 0px;
  border: none;
  width: 720px;
}
#page_5 #id5_1 {
  border: none;
  margin: 0px 0px 0px 0px;
  padding: 0px;
  border: none;
  width: 720px;
  overflow: hidden;
}
#page_5 #id5_2 {
  border: none;
  margin: 71px 0px 0px 176px;
  padding: 0px;
  border: none;
  width: 544px;
  overflow: hidden;
}

#page_6 {
  position: relative;
  overflow: hidden;
  margin: 99px 0px 12px 96px;
  padding: 0px;
  border: none;
  width: 720px;
}
#page_6 #id6_1 {
  border: none;
  margin: 0px 0px 0px 0px;
  padding: 0px;
  border: none;
  width: 720px;
  overflow: hidden;
}
#page_6 #id6_2 {
  border: none;
  margin: 116px 0px 0px 176px;
  padding: 0px;
  border: none;
  width: 544px;
  overflow: hidden;
}

#page_7 {
  position: relative;
  overflow: hidden;
  margin: 98px 0px 12px 96px;
  padding: 0px;
  border: none;
  width: 720px;
}
#page_7 #id7_1 {
  border: none;
  margin: 0px 0px 0px 0px;
  padding: 0px;
  border: none;
  width: 720px;
  overflow: hidden;
}
#page_7 #id7_2 {
  border: none;
  margin: 86px 0px 0px 176px;
  padding: 0px;
  border: none;
  width: 544px;
  overflow: hidden;
}

#page_8 {
  position: relative;
  overflow: hidden;
  margin: 99px 0px 12px 96px;
  padding: 0px;
  border: none;
  width: 720px;
}
#page_8 #id8_1 {
  border: none;
  margin: 0px 0px 0px 0px;
  padding: 0px;
  border: none;
  width: 720px;
  overflow: hidden;
}
#page_8 #id8_2 {
  border: none;
  margin: 402px 0px 0px 176px;
  padding: 0px;
  border: none;
  width: 544px;
  overflow: hidden;
}

.ft0 {
  font: bold 21px "GothamBook";
  line-height: 26px;
}
.ft1 {
  font: 16px "GothamBook";
  line-height: 19px;
}
.ft2 {
  font: bold 16px "GothamBook";
  line-height: 19px;
}
.ft3 {
  font: 16px "GothamBook";
  text-decoration: underline;
  color: #0563c1;
  line-height: 19px;
}
.ft4 {
  font: 16px "GothamBook";
  line-height: 18px;
}
.ft5 {
  font: 16px "GothamBook";
  margin-left: 14px;
  line-height: 21px;
}
.ft6 {
  font: 16px "GothamBook";
  line-height: 21px;
}
.ft7 {
  font: 13px "GothamBook";
  line-height: 16px;
}
.ft8 {
  font: 16px "GothamBook";
  margin-left: 16px;
  line-height: 19px;
}
.ft9 {
  font: 13px "Arial";
  line-height: 16px;
}
.ft10 {
  font: 16px "GothamBook";
  margin-left: 18px;
  line-height: 19px;
}
.ft11 {
  font: 8px "GothamBook";
  color: #0563c1;
  line-height: 10px;
}
.ft12 {
  font: 16px "GothamBook";
  line-height: 20px;
}
.ft13 {
  font: 16px "GothamBook";
  margin-left: 18px;
  line-height: 20px;
}
.ft14 {
  font: 8px "GothamBook";
  color: #fafafa;
  line-height: 10px;
}
.ft15 {
  font: 8px "GothamBook";
  line-height: 10px;
}
.ft16 {
  font: 16px "GothamBook";
  margin-left: 18px;
  line-height: 21px;
}
.ft17 {
  font: 16px "GothamBook";
  text-decoration: underline;
  color: #0563c1;
  line-height: 21px;
}
.ft18 {
  font: 16px "GothamBook";
  margin-left: 14px;
  line-height: 19px;
}
.ft19 {
  font: 16px "GothamBook";
  margin-left: 16px;
  line-height: 20px;
}
.ft20 {
  font: 16px "GothamBook";
  margin-left: 16px;
  line-height: 21px;
}
.ft21 {
  font: 16px "GothamBook";
  margin-left: 14px;
  line-height: 20px;
}

.p0 {
  text-align: left;
  padding-left: 252px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p1 {
  text-align: justify;
  padding-right: 110px;
  margin-top: 12px;
  margin-bottom: 0px;
}
.p2 {
  text-align: left;
  margin-top: 17px;
  margin-bottom: 0px;
}
.p3 {
  text-align: left;
  padding-right: 98px;
  margin-top: 13px;
  margin-bottom: 0px;
}
.p4 {
  text-align: left;
  margin-top: 12px;
  margin-bottom: 0px;
}
.p5 {
  text-align: left;
  padding-right: 105px;
  margin-top: 13px;
  margin-bottom: 0px;
}
.p6 {
  text-align: left;
  padding-right: 119px;
  margin-top: 27px;
  margin-bottom: 0px;
}
.p7 {
  text-align: left;
  margin-top: 15px;
  margin-bottom: 0px;
}
.p8 {
  text-align: left;
  padding-right: 101px;
  margin-top: 23px;
  margin-bottom: 0px;
}
.p9 {
  text-align: left;
  margin-top: 13px;
  margin-bottom: 0px;
}
.p10 {
  text-align: left;
  padding-left: 48px;
  padding-right: 100px;
  margin-top: 11px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p11 {
  text-align: left;
  padding-left: 72px;
  margin-top: 13px;
  margin-bottom: 0px;
}
.p12 {
  text-align: left;
  padding-left: 144px;
  padding-right: 110px;
  margin-top: 13px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p13 {
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p14 {
  text-align: left;
  padding-left: 72px;
  padding-right: 110px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p15 {
  text-align: left;
  padding-left: 72px;
  padding-right: 136px;
  margin-top: 17px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p16 {
  text-align: left;
  padding-left: 72px;
  padding-right: 98px;
  margin-top: 15px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p17 {
  text-align: left;
  padding-left: 72px;
  padding-right: 109px;
  margin-top: 17px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p18 {
  text-align: left;
  padding-left: 24px;
  padding-right: 112px;
  margin-top: 15px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p19 {
  text-align: left;
  padding-left: 24px;
  padding-right: 100px;
  margin-top: 4px;
  margin-bottom: 0px;
}
.p20 {
  text-align: left;
  padding-left: 24px;
  padding-right: 107px;
  margin-top: 6px;
  margin-bottom: 0px;
}
.p21 {
  text-align: left;
  padding-left: 24px;
  margin-top: 5px;
  margin-bottom: 0px;
}
.p22 {
  text-align: left;
  padding-left: 72px;
  padding-right: 99px;
  margin-top: 13px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p23 {
  text-align: left;
  padding-left: 120px;
  padding-right: 121px;
  margin-top: 15px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p24 {
  text-align: left;
  padding-left: 96px;
  margin-top: 17px;
  margin-bottom: 0px;
}
.p25 {
  text-align: left;
  padding-left: 96px;
  margin-top: 13px;
  margin-bottom: 0px;
}
.p26 {
  text-align: left;
  padding-left: 120px;
  padding-right: 102px;
  margin-top: 12px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p27 {
  text-align: left;
  padding-left: 48px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p28 {
  text-align: left;
  padding-left: 72px;
  padding-right: 178px;
  margin-top: 13px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p29 {
  text-align: left;
  padding-left: 72px;
  padding-right: 96px;
  margin-top: 15px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p30 {
  text-align: left;
  padding-left: 72px;
  padding-right: 109px;
  margin-top: 16px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p31 {
  text-align: left;
  padding-left: 24px;
  padding-right: 98px;
  margin-top: 17px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p32 {
  text-align: left;
  margin-top: 18px;
  margin-bottom: 0px;
}
.p33 {
  text-align: left;
  padding-left: 24px;
  padding-right: 98px;
  margin-top: 2px;
  margin-bottom: 0px;
}
.p34 {
  text-align: left;
  padding-left: 24px;
  padding-right: 98px;
  margin-top: 11px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p35 {
  text-align: left;
  padding-left: 24px;
  padding-right: 96px;
  margin-top: 6px;
  margin-bottom: 0px;
}
.p36 {
  text-align: left;
  padding-left: 144px;
  padding-right: 111px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p37 {
  text-align: left;
  padding-left: 120px;
  margin-top: 17px;
  margin-bottom: 0px;
}
.p38 {
  text-align: left;
  padding-left: 192px;
  padding-right: 99px;
  margin-top: 13px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p39 {
  text-align: left;
  margin-top: 43px;
  margin-bottom: 0px;
}
.p40 {
  text-align: left;
  padding-right: 126px;
  margin-top: 13px;
  margin-bottom: 0px;
}
.p41 {
  text-align: left;
  padding-left: 72px;
  margin-top: 15px;
  margin-bottom: 0px;
}
.p42 {
  text-align: left;
  padding-left: 72px;
  margin-top: 12px;
  margin-bottom: 0px;
}
.p43 {
  text-align: left;
  padding-left: 96px;
  padding-right: 100px;
  margin-top: 13px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p44 {
  text-align: left;
  padding-left: 96px;
  padding-right: 130px;
  margin-top: 15px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p45 {
  text-align: left;
  padding-left: 24px;
  margin-top: 13px;
  margin-bottom: 0px;
}
.p46 {
  text-align: left;
  padding-left: 72px;
  margin-top: 14px;
  margin-bottom: 0px;
}
.p47 {
  text-align: left;
  padding-left: 24px;
  margin-top: 12px;
  margin-bottom: 0px;
}
.p48 {
  text-align: left;
  padding-left: 96px;
  padding-right: 100px;
  margin-top: 14px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p49 {
  text-align: left;
  padding-left: 96px;
  padding-right: 108px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p50 {
  text-align: left;
  margin-top: 16px;
  margin-bottom: 0px;
}
.p51 {
  text-align: left;
  padding-right: 111px;
  margin-top: 13px;
  margin-bottom: 0px;
}
.p52 {
  text-align: left;
  padding-left: 96px;
  padding-right: 97px;
  margin-top: 15px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p53 {
  text-align: left;
  padding-left: 96px;
  padding-right: 112px;
  margin-top: 17px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p54 {
  text-align: left;
  padding-left: 96px;
  padding-right: 102px;
  margin-top: 15px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p55 {
  text-align: left;
  padding-left: 96px;
  padding-right: 105px;
  margin-top: 11px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p56 {
  text-align: justify;
  padding-right: 98px;
  margin-top: 45px;
  margin-bottom: 0px;
}
.p57 {
  text-align: left;
  padding-left: 72px;
  padding-right: 115px;
  margin-top: 12px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p58 {
  text-align: left;
  padding-left: 72px;
  padding-right: 153px;
  margin-top: 45px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p59 {
  text-align: left;
  padding-left: 72px;
  padding-right: 170px;
  margin-top: 47px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p60 {
  text-align: left;
  padding-right: 107px;
  margin-top: 16px;
  margin-bottom: 0px;
}
.p61 {
  text-align: left;
  margin-top: 47px;
  margin-bottom: 0px;
}
.p62 {
  text-align: left;
  padding-left: 72px;
  padding-right: 104px;
  margin-top: 12px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p63 {
  text-align: left;
  padding-left: 72px;
  padding-right: 127px;
  margin-top: 46px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p64 {
  text-align: left;
  padding-left: 72px;
  padding-right: 129px;
  margin-top: 46px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p65 {
  text-align: left;
  padding-left: 72px;
  padding-right: 115px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p66 {
  text-align: left;
  padding-left: 72px;
  padding-right: 108px;
  margin-top: 47px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p67 {
  text-align: left;
  padding-left: 72px;
  padding-right: 97px;
  margin-top: 46px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p68 {
  text-align: left;
  padding-right: 107px;
  margin-top: 15px;
  margin-bottom: 0px;
}
.p69 {
  text-align: left;
  padding-left: 96px;
  padding-right: 99px;
  margin-top: 12px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p70 {
  text-align: left;
  padding-left: 96px;
  padding-right: 103px;
  margin-top: 12px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p71 {
  text-align: left;
  padding-left: 96px;
  padding-right: 98px;
  margin-top: 11px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p72 {
  text-align: left;
  padding-left: 96px;
  padding-right: 104px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p73 {
  text-align: left;
  padding-right: 113px;
  margin-top: 13px;
  margin-bottom: 0px;
}
.p74 {
  text-align: left;
  padding-right: 101px;
  margin-top: 12px;
  margin-bottom: 0px;
}
.p75 {
  text-align: left;
  margin-top: 2px;
  margin-bottom: 0px;
}
.p76 {
  text-align: justify;
  padding-right: 110px;
  margin-top: 13px;
  margin-bottom: 0px;
}

@media only screen and (max-width: 750px) {
  #page_1 {
    margin: 0px;
    max-width: 100% !important;
  }
  #page_2 {
    margin: 0px;
    max-width: 100% !important;
  }
  #page_3 {
    margin: 0px;
    max-width: 100% !important;
  }
  #page_4 {
    margin: 0px;
    max-width: 100% !important;
  }
  #page_5 {
    margin: 0px;
    max-width: 100% !important;
  }
  #page_6 {
    margin: 0px;
    max-width: 100% !important;
  }
  #page_7 {
    margin: 0px;
    max-width: 100% !important;
  }
  #page_8 {
    margin: 0px;
    max-width: 100% !important;
  }
  #id1_1 {
    max-width: 100% !important;
    padding: 20px 20px;
  }
  #id2_1 {
    max-width: 100% !important;
    padding: 20px 20px;
  }
  .p1 {
    padding: 20px;
    padding-right: 0px;
  }
}
</style>