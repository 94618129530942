<template>
  <div class="main-content">
    <div v-if="showVideo" class="video-box">
      <div v-click-away="handleFocusOut" class="video-main">
        <div id="player">
          <iframe
            src="https://www.youtube.com/embed/JeccZZTLMDc"
            loading="lazy"
            allowfullscreen
            allowtransparency
            allow="autoplay"
          ></iframe>
        </div>
      </div>
    </div>

    <video
      preload="none"
      loop
      muted
      playsinline
      loading="lazy"
      id="main-video"
      class="web web-main"
    >
      <source
        src="https://d3dx13u2x4z410.cloudfront.net/web-video.mp4"
        type="video/mp4"
      />
    </video>
    <video
      preload="none"
      loop
      muted
      playsinline
      loading="lazy"
      id="main-video"
      class="mobile mobile-main"
    >
      <source
        src="https://d3dx13u2x4z410.cloudfront.net/mobile-video.mp4"
        type="video/mp4"
      />
    </video>
    <div class="bg-home-mobile"></div>
    <div class="container-home">
      <nav-bar :fillBG="fillBG" style="background: none" />
      <div class="main-home">
        <div class="header">
          <div class="col">
            <span class="top-text">{{
              $t("home.message_home_top_title")
            }}</span>
            <h1 class="title-header">{{ $t("home.message_home_title1") }}</h1>
            <span class="description-header">{{
              $t("home.message_home_middle1")
            }}</span>
            <div class="button-line">
              <start-now-button />
              <div @click="watchVideo" class="line-button">
                <play-button /><span>{{ $t("home.watch_video") }}</span>
              </div>
            </div>
            <div class="credit-card">
              <check-mark style="fill: #263238" /><span>{{
                $t("home.credit_card")
              }}</span>
            </div>
          </div>
          <div class="col"></div>
        </div>
      </div>
    </div>
    <div class="line-text">
      <div>
        {{ $t("home.request_demo_title") }}
      </div>
      <router-link :to="'/contactus'"
        ><div class="request-button">
          {{ $t("home.request_demo_button") }}
        </div></router-link
      >
    </div>
    <div class="ecommerce-main-out">
      <div class="ecommerce-main">
        <h2 class="headline-ecommerce">
          {{ $t("home.ecommerce_plataform_title") }}
        </h2>
        <h3 class="description-ecommerce">{{
          $t("home.ecommerce_plataform_description")
        }}</h3>
        <div class="plat-info">
          <div class="row-plat">
            <div class="box-logo">
              <shopify class="shopify-logo" />
            </div>
            <span>{{ $t("home.ecommerce_plataform_shopify") }}</span>
          </div>
          <div class="row-plat">
            <div class="box-logo">
              <woocommerce class="woocommerce-logo" />
            </div>
            <span>{{ $t("home.ecommerce_plataform_woocommerce") }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex mx-auto product-section">
      <div class="flex w-full">
        <div class="text-column">
          <div class="section-height" style="height: 10%; width: 10px"></div>
          <div id="notierity-section" class="text-section">
            <div class="inner-section">
              <h4
                style="text-align: left"
                class="text-section-main-title font-gothammedium"
              >
                {{ $t("home.message_advantages") }}
              </h4>
              <div
                class="h-1 line-black w-24 opacity-75 mt-4 rounded anim-hidden"
              ></div>
              <h5
                style="text-align: left"
                class="text-section-title font-gothammedium"
              >
                {{ $t("home.message_home_brand1") }}
              </h5>
              <h5
                style="text-align: left"
                class="text-section-subtitle font-gothambook"
              >
                {{ $t("home.message_home_brand2") }}
              </h5>
            </div>
          </div>
          <div id="ux-section" class="text-section">
            <div class="inner-section">
              <div
                class="h-1 line-yellow w-24 opacity-75 mt-4 rounded anim-hidden"
              ></div>
              <h5 style="text-align: left" class="text-section-title">
                {{ $t("home.message_home_experience1") }}
              </h5>
              <h5 style="text-align: left" class="text-section-subtitle">
                {{ $t("home.message_home_experience2") }}
              </h5>
            </div>
          </div>
          <div id="convert-section" class="text-section">
            <div class="inner-section">
              <div
                class="h-1 line-blue w-24 opacity-75 mt-4 rounded anim-hidden"
              ></div>
              <h5 style="text-align: left" class="text-section-title">
                {{ $t("home.message_home_convert1") }}
              </h5>
              <h5 style="text-align: left" class="text-section-subtitle">
                {{ $t("home.message_home_convert2") }}
              </h5>
            </div>
          </div>
        </div>
        <div class="phone-column">
          <div class="section-height" style="height: 10%; width: 10px"></div>
          <div class="phone-box">
            <div style="position: relative; height: 600px">
              <video
                class="phone-element one"
                preload="none"
                loop
                muted
                playsinline
              >
                <source
                  src="https://d3dx13u2x4z410.cloudfront.net/Notoriedade.mp4"
                  type="video/mp4"
                />
              </video>
              <video
                class="phone-element two"
                preload="none"
                loop
                muted
                playsinline
              >
                <source
                  src="https://d3dx13u2x4z410.cloudfront.net/UX-Experience.mp4"
                  type="video/mp4"
                />
              </video>
              <video
                class="phone-element three"
                preload="none"
                loop
                muted
                playsinline
              >
                <source
                  src="https://d3dx13u2x4z410.cloudfront.net/Checkout_Video.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="product-section-mobile">
      <div class="notierity-section-mobile">
        <div class="inner-section-mobile">
          <div
            class="h-1 line-black w-24 opacity-75 mt-4 rounded anim-hidden"
          ></div>
          <h5
            style="text-align: left"
            class="text-section-title font-gothammedium"
          >
            {{ $t("home.message_home_brand1") }}
          </h5>
          <h5
            style="text-align: left"
            class="text-section-subtitle font-gothambook"
          >
            {{ $t("home.message_home_brand2") }}
          </h5>
          <div class="video-box-mobile dark-bg">
            <video class="phone-element" preload="none" loop muted playsinline>
              <source
                src="https://d3dx13u2x4z410.cloudfront.net/Notoriedade.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
      <div class="ux-section-mobile">
        <div class="inner-section-mobile">
          <div
            class="h-1 line-yellow w-24 opacity-75 mt-4 rounded anim-hidden"
          ></div>
          <h5
            style="text-align: left"
            class="text-section-title font-gothammedium"
          >
            {{ $t("home.message_home_experience1") }}
          </h5>
          <h5
            style="text-align: left"
            class="text-section-subtitle font-gothambook"
          >
            {{ $t("home.message_home_experience2") }}
          </h5>
          <div class="video-box-mobile yellow-bg">
            <video class="phone-element" preload="none" loop muted playsinline>
              <source
                src="https://d3dx13u2x4z410.cloudfront.net/UX-Experience.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
      <div class="convert-section-mobile">
        <div class="inner-section-mobile">
          <div
            class="h-1 line-blue w-24 opacity-75 mt-4 rounded anim-hidden"
          ></div>
          <h5
            style="text-align: left"
            class="text-section-title font-gothammedium"
          >
            {{ $t("home.message_home_convert1") }}
          </h5>
          <h5
            style="text-align: left"
            class="text-section-subtitle font-gothambook"
          >
            {{ $t("home.message_home_convert2") }}
          </h5>
          <div class="video-box-mobile blue-bg">
            <video class="phone-element" preload="none" loop muted playsinline>
              <source
                src="https://d3dx13u2x4z410.cloudfront.net/Checkout_Video.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
    </div>
    <div class="notification-section">
      <h2 class="noti-section-title">
        {{ $t("home.message_home_push1") }}
      </h2>
      <p class="noti-section-description">
        {{ $t("home.message_home_push2") }}
      </p>
      <video preload="none" loop muted playsinline id="notification-video">
        <source src="https://nappscdn.s3.eu-west-3.amazonaws.com/notificationvideo.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="booknow-section">
      <h3 class="demo-title">
        {{ $t("home.message_dashboard_title1") }} <br />
        {{ $t("home.message_dashboard_title2") }}
      </h3>
    </div>
    <div class="dashboard-section">
      <div class="square square-ten"></div>
      <div class="square square-eleven"></div>
      <div class="square square-twelve"></div>
      <div class="square square-thirteen"></div>
      <div class="square square-fourteen"></div>
      <div class="square square-fifteen"></div>
      <div class="square square-sixteen"></div>
      <div class="square square-seventeen"></div>
      <div class="square square-heighteen"></div>
      <div class="dashboard-innersection">
        <h1 class="title-dashboard">
          {{ $t("home.message_explore_dashboard") }}
        </h1>
        <div class="dashboard-menu">
          <div
            @click="clickedDashItem('design')"
            class="dashboard-item"
            :class="isDesignActive"
          >
            <design-icon class="dashboard-icon" /> <span>Design</span>
          </div>
          <div
            @click="clickedDashItem('notification')"
            class="dashboard-item"
            :class="isNotificationActive"
          >
            <notification-icon class="dashboard-icon" />
            <span>Notification</span>
          </div>
          <div
            @click="clickedDashItem('analytics')"
            class="dashboard-item"
            :class="isAnalyticsActive"
          >
            <analytics-icon class="dashboard-icon" /> <span>Analytics</span>
          </div>
          <div
            @click="clickedDashItem('settings')"
            class="dashboard-item"
            :class="isSettingsActive"
          >
            <settings-icon class="dashboard-icon" /> <span>Settings</span>
          </div>
        </div>
      </div>
      <div class="dashboard-content">
        <div class="grid-container">
          <div id="grid" class="dashboard-grid">
            <div class="row-one grid-item device-1">
              <img
                alt="Frame Dashboard"
                width="818px"
                height="auto"
                class="device-frame"
                src="https://d3dx13u2x4z410.cloudfront.net/frame-dashboard.svg"
              />
              <img
                alt="Edit your Mobile App Components"
                class="showcase"
                loading="lazy"
                src="https://d3dx13u2x4z410.cloudfront.net/dashboard01.png"
              />
            </div>
            <div class="row-one grid-item device-2">
              <img
                alt="Frame Dashboard"
                width="818px"
                height="auto"
                class="device-frame"
                src="https://d3dx13u2x4z410.cloudfront.net/frame-dashboard.svg"
              />
              <img
                 alt="Build your Mobile App"
                class="showcase"
                loading="lazy"
                src="https://d3dx13u2x4z410.cloudfront.net/dashboard02.png"
              />
            </div>
            <div class="row-one grid-item device-3">
              <img
                alt="Frame Dashboard"
                width="818px"
                height="auto"
                class="device-frame"
                src="https://d3dx13u2x4z410.cloudfront.net/frame-dashboard.svg"
              />
              <img
                alt="Track your Push Notifications"
                class="showcase"
                loading="lazy"
                src="https://d3dx13u2x4z410.cloudfront.net/dashboard03.png"
              />
            </div>
            <div class="row-one grid-item device-4">
              <img
                alt="Frame Dashboard"
                width="818px"
                height="auto"
                class="device-frame"
                src="https://d3dx13u2x4z410.cloudfront.net/frame-dashboard.svg"
              />
              <img
                alt="Send Push Notifications to your users"
                class="showcase"
                loading="lazy"
                src="https://d3dx13u2x4z410.cloudfront.net/dashboard04.png"
              />
            </div>
            <div class="row-one grid-item device-5">
              <img
                alt="Frame Dashboard"
                width="818px"
                height="auto"
                class="device-frame"
                src="https://d3dx13u2x4z410.cloudfront.net/frame-dashboard.svg"
              />
              <img
                alt="Analyse your customers shopping behaviour"
                class="showcase"
                loading="lazy"
                src="https://d3dx13u2x4z410.cloudfront.net/dashboard05.png"
              />
            </div>
            <div class="row-one grid-item device-6">
              <img
                alt="Frame Dashboard"
                width="818px"
                height="auto"
                class="device-frame"
                src="https://d3dx13u2x4z410.cloudfront.net/frame-dashboard.svg"
              />
              <img
                alt="Analyse your customers shopping behaviour"
                class="showcase"
                loading="lazy"
                src="https://d3dx13u2x4z410.cloudfront.net/dashboard06.png"
              />
            </div>
            <div class="row-one grid-item device-7">
              <img
                alt="Frame Dashboard"
                width="818px"
                height="auto"
                class="device-frame"
                src="https://d3dx13u2x4z410.cloudfront.net/frame-dashboard.svg"
              />
              <img
                alt="Configure your app customizations"
                class="showcase"
                loading="lazy"
                src="https://d3dx13u2x4z410.cloudfront.net/dashboard07.png"
              />
            </div>
            <div class="row-one grid-item device-8">
              <img
                alt="Frame Dashboard"
                width="818px"
                height="auto"
                class="device-frame"
                src="https://d3dx13u2x4z410.cloudfront.net/frame-dashboard.svg"
              />
              <img
                alt="Turn on your mobile app features"
                class="showcase"
                loading="lazy"
                src="https://d3dx13u2x4z410.cloudfront.net/dashboard08.png"
              />
            </div>
            <div class="row-one grid-item device-9">
              <img
                alt="Frame Dashboard"
                class="device-frame"
                src="https://d3dx13u2x4z410.cloudfront.net/frame-dashboard.svg"
              />
              <img
                alt="Fill your customer information"
                class="showcase"
                loading="lazy"
                src="https://d3dx13u2x4z410.cloudfront.net/dashboard09.png"
              />
            </div>
            <div class="headline-item-container headline-design">
              <h4 class="font-gothambold text-2xl text-mainBrand md:mt-20">{{ $t("home.dashboard_design_title") }}</h4>
              <p class="">
                {{ $t("home.dashboard_design_description") }}
              </p>
            </div>
            <div class="headline-item-container headline-notification">
              <h4 class="font-gothambold text-2xl text-mainBrand md:mt-20">{{ $t("home.dashboard_notification_title") }}</h4>
              <p>
                {{ $t("home.dashboard_notification_description") }}
              </p>
            </div>
            <div class="headline-item-container headline-analytics">
              <h4 class="font-gothambold text-2xl text-mainBrand md:mt-20">{{ $t("home.dashboard_analytics_title") }}</h4>
              <p>
                {{ $t("home.dashboard_analytics_description") }}
              </p>
            </div>
            <div class="headline-item-container headline-settings">
              <h4 class="font-gothambold text-2xl text-mainBrand md:mt-20">{{ $t("home.dashboard_settings_title") }}</h4>
              <p>
                {{ $t("home.dashboard_settings_description") }}
              </p>
            </div>
            <div class="row-two device-15">
              <img
                alt="Iphone Frame"
                class="iphone-frame"
                width="366px"
                height="auto" 
                src="https://d3dx13u2x4z410.cloudfront.net/iphone-frame.svg"
              />
              <img
                class="iphone-showcase"
                alt="Mobile App Home Screen"
                data-srcset="https://d3dx13u2x4z410.cloudfront.net/mobile_01.PNG"
                loading="lazy"
                srcset="
                  https://d3dx13u2x4z410.cloudfront.net/mobile_01.PNG
                "
                data-ll-status="loaded"
              />
            </div>
            <div class="row-two device-16">
              <img
                alt="Iphone Frame"
                class="iphone-frame"
                width="366px"
                height="auto" 
                src="https://d3dx13u2x4z410.cloudfront.net/iphone-frame.svg"
              />
              <img
                class="iphone-showcase"
                alt="Mobile App Notification Received"
                data-srcset="https://d3dx13u2x4z410.cloudfront.net/mobile_03.PNG"
                loading="lazy"
                srcset="
                  https://d3dx13u2x4z410.cloudfront.net/mobile_03.PNG
                "
                data-ll-status="loaded"
              />
            </div>
            <div class="row-two device-17">
              <img
                alt="Iphone Frame"
                class="iphone-frame"
                width="366px"
                height="auto" 
                src="https://d3dx13u2x4z410.cloudfront.net/iphone-frame.svg"
              />
              <img
                class="iphone-showcase"
                alt="Mobile App Checkout Process"
                data-srcset="https://d3dx13u2x4z410.cloudfront.net/mobile_02.PNG"
                loading="lazy"
                srcset="
                  https://d3dx13u2x4z410.cloudfront.net/mobile_02.PNG
                "
                data-ll-status="loaded"
              />
            </div>
            <div class="row-two device-18">
              <img
                alt="Iphone Frame"
                class="iphone-frame"
                width="366px"
                height="auto" 
                src="https://d3dx13u2x4z410.cloudfront.net/iphone-frame.svg"
              />
              <img
                class="iphone-showcase"
                alt="Mobile App Product Reviews"
                data-srcset="https://d3dx13u2x4z410.cloudfront.net/mobile_04.PNG"
                loading="lazy"
                srcset="
                  https://d3dx13u2x4z410.cloudfront.net/mobile_04.PNG
                "
                data-ll-status="loaded"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer2 />
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import NavBar from "@/components/Navbar/Main.vue";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import StartNowButton from "@/components/StartNowButton.vue";
import PlayButton from "@/components/Icons/PlayButton.vue";
import CheckMark from "@/components/Icons/CheckMark.vue";
import Shopify from "@/components/Icons/ShopifyLogo.vue";
import Woocommerce from "@/components/Icons/Woocommerce.vue";
import ArrowHome from "../components/Icons/ArrowHome.vue";
import Footer2 from "@/components/Footer/Main.vue";
import DesignIcon from "@/components/Icons/DesignIcon.vue";
import NotificationIcon from "@/components/Icons/NotificationIcon.vue";
import AnalyticsIcon from "@/components/Icons/AnalyticsIcon.vue";
import SettingsIcon from "@/components/Icons/SettingsIcon.vue";
import CloseHome from "@/components/Icons/CloseHome.vue";
import Plyr from "plyr";

// const Plyr = () => import('plyr');

export default {
  components: {
    NavBar,
    StartNowButton,
    PlayButton,
    CheckMark,
    Woocommerce,
    Shopify,
    ArrowHome,
    Footer2,
    DesignIcon,
    NotificationIcon,
    AnalyticsIcon,
    SettingsIcon,
    CloseHome,
  },
  data() {
    return {
      nome: null,
      showNotAvailable: false,
      dashActive: "design",
      showVideo: false,
      fillBG: false,
      player: null,
    };
  },
  methods: {
    handleFocusOut() {
      if(!this.showVideo) {
        return
      }
      this.player.pause();
      window.onscroll = function () {};
      this.showVideo = false;
      this.player = null;
    },
    closePop() {
      gsap.to("body", { overflow: "auto" });
      this.showNotAvailable = false;
    },
    clickedDashItem(value) {
      var grid = document.getElementById("grid");
      if (value == "design") {
        if (this.detectMobile()) {
          grid.style.setProperty("left", "0px");
        } else {
          grid.style.setProperty("left", this.getLeftValue(0));
        }
      } else if (value == "notification") {
        if (this.detectMobile()) {
          grid.style.setProperty("left", "-100vw");
        } else {
          grid.style.setProperty("left", this.getLeftValue(1));
        }
      } else if (value == "analytics") {
        if (this.detectMobile()) {
          grid.style.setProperty("left", "-200vw");
        } else {
          grid.style.setProperty("left", this.getLeftValue(2));
        }
      } else if (value == "settings") {
        if (this.detectMobile()) {
          grid.style.setProperty("left", "-300vw");
        } else {
          grid.style.setProperty("left", this.getLeftValue(3));
        }
      }
      this.dashActive = value;
    },
    watchVideo() {
      window.scrollTo(0, 0);
      this.showVideo = !this.showVideo;

      setTimeout(() => {
        if(!this.player) {
          this.player = new Plyr("#player", {
            autoplay: true
          });
          window.player = this.player
        }

        if (!this.showVideo) {
          this.player.pause();
          window.onscroll = function () {};
        } else {
          // setTimeout(() => {
          //   this.player.play();
          // }, 2000)
          var x = window.scrollX;
          var y = window.scrollY;
          window.onscroll = function () {
            window.scrollTo(x, y);
          };
        }
      }, 200)
    },
    detectMobile() {
      return window.innerWidth <= 1028;
    },
    getLeftValue(multiplier) {
      var value1100 = -700;
      var diff = window.innerWidth - 1100;
      diff = diff / 2;
      diff = -700 + diff + multiplier * -1800;
      return diff.toString() + "px";
    },
  },
  computed: {
    isLoggedIn() {
      return useStore().getters["auth/isLoggedIn"];
    },
    isShopConfigured() {
      return useStore().getters["auth/isShopConfigured"];
    },
    isDesignActive() {
      if (this.dashActive == "design") return "selected";
    },
    isNotificationActive() {
      if (this.dashActive == "notification") return "selected";
    },
    isAnalyticsActive() {
      if (this.dashActive == "analytics") return "selected";
    },
    isSettingsActive() {
      if (this.dashActive == "settings") return "selected";
    },
  },
  setup() {
    const closeImage = () => {
      closePop();
    };
    return {
      closeImage,
    };
  },
  unmounted() {
    window.scrollTo(0, 0);
    ScrollTrigger.getAll().forEach((element) => {
      element.kill();
    });
  },
  mounted() {
    let vid = document.getElementById("main-video");
    vid.play();

    var grid = document.getElementById("grid");
    if (this.detectMobile()) {
      grid.style.setProperty("left", "0px");
    } else {
      grid.style.setProperty("left", this.getLeftValue(0));
    }

    window.scrollTo(0, 0);
    const router = useRouter();
    this.showNotAvailable =
      router.currentRoute.value.query.hasOwnProperty("notAvailable") &&
      this.isLoggedIn;

    if (this.showNotAvailable) {
      gsap.to("body", { overflow: "hidden" });
    }

    ScrollTrigger.create({
      trigger: ".main-home",
      start: "top -10%",
      end: "bottom 130%",
      onEnter: () => {
        this.fillBG = true;
      },
      onEnterBack: () => {
        this.fillBG = false;
      },
    });

    ScrollTrigger.matchMedia({
      "(max-width: 1024px)": function () {
        ScrollTrigger.create({
          trigger: ".container-home",
          start: "top 0%",
          end: "bottom 20%",
          onEnter: () => {
             const mobileVideo = document.getElementsByClassName("mobile-main");
             for (let i = 0; i < mobileVideo.length; i++) {
              const video = mobileVideo[i];
              if (video.play) {
                video.play();
              }
            }
          },
          onLeave: () => {
            const mobileVideo = document.getElementsByClassName("mobile-main");
             for (let i = 0; i < mobileVideo.length; i++) {
              const video = mobileVideo[i];
              if (video.play) {
                video.pause();
              }
            }
          },
          onEnterBack: () => {
             const mobileVideo = document.getElementsByClassName("mobile-main");
             for (let i = 0; i < mobileVideo.length; i++) {
              const video = mobileVideo[i];
              if (video.play) {
                video.play();
              }
            }
          },
        });
      },
      "(min-width: 1024px)": function () {
        ScrollTrigger.create({
          trigger: ".container-home",
          start: "top 0%",
          end: "bottom 60%",
          onEnter: () => {
             const webVideo = document.getElementsByClassName("web");
             for (let i = 0; i < webVideo.length; i++) {
              const video = webVideo[i];
              if (video.play) {
                video.play();
              }
            }
          },
          onLeave: () => {
            const webVideo = document.getElementsByClassName("web");
             for (let i = 0; i < webVideo.length; i++) {
              const video = webVideo[i];
              if (video.play) {
                video.pause();
              }
            }
          },
          onEnterBack: () => {
             const webVideo = document.getElementsByClassName("web");
             for (let i = 0; i < webVideo.length; i++) {
              const video = webVideo[i];
              if (video.play) {
                video.play();
              }
            }
          },
        });
      },
    });

    ScrollTrigger.create({
      trigger: "#notification-video",
      start: "top 60%",
      end: "bottom 10%",
      onEnter: () => {
        const notificationVideo = document.getElementById("notification-video");
        notificationVideo.play();
      },
      onLeave: () => {
        const notificationVideo = document.getElementById("notification-video");
        notificationVideo.pause();
      },
      onEnterBack: () => {
        const notificationVideo = document.getElementById("notification-video");
        notificationVideo.play();
      },
    });

    ScrollTrigger.create({
      trigger: ".phone-box",
      start: "top 20%",
      end: "bottom 70%",
      endTrigger: ".phone-column",
      markers: false,
      onLeave: () => {
        gsap.to("#convert-section", { opacity: 0, duration: 0.4 });
        gsap.to(".phone-column", { backgroundColor: "#F8F8F8", duration: 0.4 });
        gsap.to(".one", { opacity: "0", duration: 0.4 });
        gsap.to(".two", { opacity: "0", duration: 0.4 });
        gsap.to(".three", { opacity: "0", duration: 0.4 });
      },
      onEnterBack: () => {
        gsap.to("#convert-section", { opacity: 1, duration: 0.4 });
        gsap.to(".one", { opacity: "0.2", delay: 0.2, duration: 0.4 });
        gsap.to(".two", { opacity: "0.2", delay: 0.2, duration: 0.4 });
        gsap.to(".three", { opacity: "1", delay: 0.2, duration: 0.4 });
        gsap.to(".phone-column", {
          backgroundColor: "#517DA4",
          delay: 0.2,
          duration: 0.4,
        });
      },
    });

    ScrollTrigger.create({
      trigger: ".product-section",
      start: "top 70%",
      onEnter: () => {
        gsap.to(".phone-column", { backgroundColor: "#263238", duration: 0.4 });
      },
      onLeave: () => {
        gsap.to(".phone-column", { backgroundColor: "#F8F8F8", duration: 0.4 });
      },
    });

    ScrollTrigger.matchMedia({
      "(max-width: 1599px)": function () {
        ScrollTrigger.create({
          trigger: ".phone-box",
          start: "top 10%",
          end: "bottom 100%",
          endTrigger: ".phone-column",
          pin: true,
          markers: false,
          onLeave: () => {
            const elements = document.getElementsByClassName("three");
            for (let i = 0; i < elements.length; i++) {
              const video = elements[i];
              if (video.play) {
                video.pause();
              }
            }
          },
          onEnterBack: () => {
            const elements = document.getElementsByClassName("three");
            for (let i = 0; i < elements.length; i++) {
              const video = elements[i];
              if (video.pause) {
                video.play();
              }
            }
          },
        });
      },
    });

    ScrollTrigger.matchMedia({
      "(min-width: 1600px)": function () {
        ScrollTrigger.create({
          trigger: ".phone-box",
          start: "top 20%",
          end: "bottom 100%",
          endTrigger: ".phone-column",
          pin: true,
          markers: false,
          onLeave: () => {
            const elements = document.getElementsByClassName("three");
            for (let i = 0; i < elements.length; i++) {
              const video = elements[i];
              if (video.play) {
                video.pause();
              }
            }
          },
          onEnterBack: () => {
            const elements = document.getElementsByClassName("three");
            for (let i = 0; i < elements.length; i++) {
              const video = elements[i];
              if (video.pause) {
                video.play();
              }
            }
          },
        });
        ScrollTrigger.create({
          trigger: ".dashboard-menu",
          start: "top -5%",
          end: "top -9%",
          markers: false,
          onEnter: () => {
            gsap.to(".dashboard-menu", { zIndex: "9999", duration: 0.0 });
            gsap.to(".dashboard-menu", { top: "8%", duration: 0.3 });
            gsap.to(".dashboard-menu", { position: "fixed", duration: 0.3 });
          },
          onLeaveBack: () => {
            gsap.to(".dashboard-menu", { zIndex: "9999", duration: 0.0 });
            gsap.to(".dashboard-menu", { top: "-10%", duration: 0.4 });
            gsap.to(".dashboard-menu", { position: "relative", duration: 0.4 });
          },
        });
        ScrollTrigger.create({
          trigger: ".footer-container",
          start: "top 40%",
          end: "top 100%",
          markers: false,
          onEnter: () => {
            gsap.to(".dashboard-menu", { zIndex: "9999", duration: 0.0 });
            gsap.to(".dashboard-menu", { top: "-10%", duration: 0.4 });
            gsap.to(".dashboard-menu", { position: "relative", duration: 0.4 });
          },
          onLeaveBack: () => {
            gsap.to(".dashboard-menu", { zIndex: "9999", duration: 0.0 });
            gsap.to(".dashboard-menu", { top: "8%", duration: 0.3 });
            gsap.to(".dashboard-menu", { position: "fixed", duration: 0.3 });
          },
        });
      },
      "(min-width: 1340px)": function () {
        ScrollTrigger.create({
          trigger: ".dashboard-menu",
          start: "top -5%",
          end: "top -9%",
          markers: false,
          onEnter: () => {
            gsap.to(".dashboard-menu", { zIndex: "9999", duration: 0.0 });
            gsap.to(".dashboard-menu", { top: "8%", duration: 0.3 });
            gsap.to(".dashboard-menu", { position: "fixed", duration: 0.3 });
          },
          onLeaveBack: () => {
            gsap.to(".dashboard-menu", { zIndex: "9999", duration: 0.0 });
            gsap.to(".dashboard-menu", { top: "-10%", duration: 0.4 });
            gsap.to(".dashboard-menu", { position: "relative", duration: 0.4 });
          },
        });
        ScrollTrigger.create({
          trigger: ".footer-container",
          start: "top 40%",
          end: "top 100%",
          markers: false,
          onEnter: () => {
            gsap.to(".dashboard-menu", { zIndex: "9999", duration: 0.0 });
            gsap.to(".dashboard-menu", { top: "-10%", duration: 0.4 });
            gsap.to(".dashboard-menu", { position: "relative", duration: 0.4 });
          },
          onLeaveBack: () => {
            gsap.to(".dashboard-menu", { zIndex: "9999", duration: 0.0 });
            gsap.to(".dashboard-menu", { top: "8%", duration: 0.3 });
            gsap.to(".dashboard-menu", { position: "fixed", duration: 0.3 });
          },
        });
      },
      "(min-width: 1000px)": function () {
        ScrollTrigger.create({
          trigger: ".dashboard-menu",
          start: "top -5%",
          end: "top -9%",
          markers: false,
          onEnter: () => {
            gsap.to(".dashboard-menu", { zIndex: "998", duration: 0.0 });
            gsap.to(".dashboard-menu", { top: "8%", duration: 0.3 });
            gsap.to(".dashboard-menu", { position: "fixed", duration: 0.3 });
          },
          onLeaveBack: () => {
            gsap.to(".dashboard-menu", { zIndex: "998", duration: 0.0 });
            gsap.to(".dashboard-menu", { top: "-7%", duration: 0.4 });
            gsap.to(".dashboard-menu", { position: "relative", duration: 0.4 });
          },
        });
        ScrollTrigger.create({
          trigger: ".footer-container",
          start: "top 40%",
          end: "top 100%",
          markers: false,
          onEnter: () => {
            gsap.to(".dashboard-menu", { zIndex: "998", duration: 0.0 });
            gsap.to(".dashboard-menu", { top: "-7%", duration: 0.4 });
            gsap.to(".dashboard-menu", { position: "relative", duration: 0.4 });
          },
          onLeaveBack: () => {
            gsap.to(".dashboard-menu", { zIndex: "998", duration: 0.0 });
            gsap.to(".dashboard-menu", { top: "8%", duration: 0.3 });
            gsap.to(".dashboard-menu", { position: "fixed", duration: 0.3 });
          },
        });
      },
      "(max-width: 760px), (min-width: 420px)": function () {
        ScrollTrigger.create({
          trigger: ".dashboard-content",
          start: "top -20%",
          end: "top -20%",
          markers: false,
          onEnter: () => {
            gsap.to(".dashboard-menu", { zIndex: "998", duration: 0.0 });
            gsap.to(".dashboard-menu", { top: "7%", duration: 0.3 });
            gsap.to(".dashboard-menu", { position: "fixed", duration: 0.3 });
          },
          onLeaveBack: () => {
            gsap.to(".dashboard-menu", { zIndex: "998", duration: 0.0 });
            gsap.to(".dashboard-menu", { top: "20%", duration: 0.4 });
            gsap.to(".dashboard-menu", { position: "relative", duration: 0.4 });
          },
        });
        ScrollTrigger.create({
          trigger: ".footer-container",
          start: "top 13%",
          end: "top 100%",
          markers: false,
          onEnter: () => {
            gsap.to(".dashboard-menu", { zIndex: "998", duration: 0.0 });
            gsap.to(".dashboard-menu", { top: "0", duration: 0.4 });
            gsap.to(".dashboard-menu", { position: "relative", duration: 0.4 });
          },
          onLeaveBack: () => {
            gsap.to(".dashboard-menu", { zIndex: "998", duration: 0.0 });
            gsap.to(".dashboard-menu", { top: "8%", duration: 0.3 });
            gsap.to(".dashboard-menu", { position: "fixed", duration: 0.3 });
          },
        });
      },
    });

    ScrollTrigger.create({
      trigger: ".product-section",
      start: "top 35%",
      markers: false,
      onEnter: () => {
        const elements = document.getElementsByClassName("one");
        for (let i = 0; i < elements.length; i++) {
          const video = elements[i];
          if (video.paused) {
            video.play();
          }
        }
        gsap.to(".one", { opacity: 1, duration: 0.4 });
      },
      onLeaveBack: () => {
        gsap.to(".one", { opacity: 0, duration: 0.4 });
        gsap.to(".phone-column", { backgroundColor: "#F8F8F8", duration: 0.4 });
        const elements = document.getElementsByClassName("one");
        for (let i = 0; i < elements.length; i++) {
          const video = elements[i];
          if (video.play) {
            video.pause();
          }
        }
      },
    });

    ScrollTrigger.create({
      trigger: "#ux-section",
      start: "top 50%",
      onEnter: () => {
        const elements = document.getElementsByClassName("one");
        for (let i = 0; i < elements.length; i++) {
          const video = elements[i];
          if (video.play) {
            video.pause();
          }
        }
        const elementsTwo = document.getElementsByClassName("two");
        for (let i = 0; i < elementsTwo.length; i++) {
          const video = elementsTwo[i];
          if (video.paused) {
            video.play();
          }
        }
        gsap.to(".phone-column", { backgroundColor: "#FBBC04", duration: 0.4 });
        gsap.to(".one", { opacity: "0.2", duration: 0.4 });
        gsap.to(".two", { opacity: "1", duration: 0.4 });
        gsap.to("#notierity-section", { opacity: 0, duration: 0.7 });
        gsap.to("#ux-section", { opacity: 1, duration: 0.7 });
      },
      onLeaveBack: () => {
        gsap.to(".one", { opacity: "1", duration: 0.4 });
        gsap.to(".two", { opacity: "0", duration: 0.4 });
        gsap.to(".phone-column", { backgroundColor: "#263238", duration: 0.4 });
        gsap.to("#notierity-section", { opacity: 1, duration: 0.7 });
        gsap.to("#ux-section", { opacity: 0, duration: 0.7 });
        const elements = document.getElementsByClassName("two");
        for (let i = 0; i < elements.length; i++) {
          const video = elements[i];
          if (video.play) {
            video.pause();
          }
        }
        const elementsTwo = document.getElementsByClassName("one");
        for (let i = 0; i < elementsTwo.length; i++) {
          const video = elementsTwo[i];
          if (video.paused) {
            video.play();
          }
        }
      },
      markers: false,
    });

    ScrollTrigger.create({
      trigger: "#convert-section",
      start: "top 50%",
      onEnter: () => {
        const elements = document.getElementsByClassName("two");
        for (let i = 0; i < elements.length; i++) {
          const video = elements[i];
          if (video.play) {
            video.pause();
          }
        }
        const elementsTwo = document.getElementsByClassName("three");
        for (let i = 0; i < elementsTwo.length; i++) {
          const video = elementsTwo[i];
          if (video.paused) {
            video.play();
          }
        }
        gsap.to(".two", { opacity: "0.2", duration: 0.5 });
        gsap.to(".three", { opacity: "1", duration: 0.5 });
        gsap.to(".phone-column", { backgroundColor: "#517DA4", duration: 0.5 });
        gsap.to("#convert-section", { opacity: 1, duration: 0.4 });
        gsap.to("#ux-section", { opacity: 0, duration: 0.4 });
      },
      onLeaveBack: () => {
        gsap.to(".one", { opacity: "1", duration: 0.5 });
        gsap.to(".two", { opacity: "1", duration: 0.5 });
        gsap.to(".three", { opacity: "0", duration: 0.5 });
        gsap.to(".phone-column", { backgroundColor: "#FBBC04", duration: 0.5 });
        gsap.to("#convert-section", { opacity: 0, duration: 0.4 });
        gsap.to("#ux-section", { opacity: 1, duration: 0.4 });
        const elements = document.getElementsByClassName("three");
        for (let i = 0; i < elements.length; i++) {
          const video = elements[i];
          if (video.play) {
            video.pause();
          }
        }
        const elementsTwo = document.getElementsByClassName("two");
        for (let i = 0; i < elementsTwo.length; i++) {
          const video = elementsTwo[i];
          if (video.paused) {
            video.play();
          }
        }
      },
    });

    ScrollTrigger.create({
      trigger: ".line-text",
      start: "top 50%",
      onEnter: () => {
        const elements = document.getElementsByClassName("phone-element");
        for (let i = 0; i < elements.length; i++) {
          const video = elements[i];
          if (video.paused) {
            video.play();
          }
        }
      },
    });

    window.x = ScrollTrigger;
  },
};
</script>

<style scoped>
.container-home {
  height: calc(56.2708vw);
}

#main-video {
  position: absolute;
  z-index: -1;
  height: calc(56.2708vw);
}

#notification-video {
  height: calc(35vw);
}

.convert-section {
  opacity: 0;
}

.ux-section {
  opacity: 0;
}

.video-box {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  flex-direction: row;
  background-color: rgba(0, 0, 0, 0.377);
  z-index: 200;
}

.video-main {
  width: 70%;
  height: auto;
}

.close-icon {
  fill: white;
  margin-top: 5px;
  margin-left: 5px;
  cursor: pointer;
  height: 15px;
  width: auto;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.booknow-section {
  overflow: hidden;
  padding: 120px 200px;
}

.square {
  border: 1px solid #263238;
  border-radius: 4px;
  position: absolute;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 1s;
  opacity: 1;
}

.one-square {
  border: 4px solid #263238;
  border-radius: 10px;
  height: 160px;
  width: 160px;
  top: 16%;
  left: 2%;
  transform: rotate(20deg);
}

.two-square {
  border: 5px solid #b0cbd8;
  border-radius: 15px;
  height: 220px;
  width: 220px;
  top: 22%;
  left: 91%;
  transform: rotate(-60deg);
}

.three-square {
  border: 4px solid #263238;
  border-radius: 10px;
  height: 150px;
  width: 150px;
  top: 80%;
  left: 92%;
  transform: rotate(97deg);
}

.one {
  opacity: 0;
  position: fixed;
  right: 30%;
  top: 0%;
}
.two {
  opacity: 0;
  position: fixed;
  right: 32%;
  top: 2%;
}
.three {
  opacity: 0;
  position: fixed;
  right: 34%;
  top: 4%;
}
.main-home {
  height: 94%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1536px;
  margin: auto;
}

.col {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.button-line {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

.top-text {
  text-transform: uppercase;
  color: #fbbc04;
  font-family: GothamBook;
  font-size: 18px;
  margin-bottom: 5px;
}

.title-header {
  font-family: GothamBold;
  text-transform: uppercase;
  color: #263238;
  font-size: 45px;
  line-height: 55px;
}

.description-header {
  color: #263238;
  font-family: GothamBook;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.button-trial {
  background-color: #263238;
  padding: 12px;
  color: white;
  border-radius: 5px;
  font-family: GothamBook, "sans-serif";
  margin-right: 25px;
  width: 50%;
  text-transform: uppercase;
}
.line-button {
  display: flex;
  flex-direction: row;
  color: #263238;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: GothamBook, "sans-serif";
  margin-left: 25px;
  cursor: pointer;
}
.line-button > svg {
  margin-right: 10px;
  width: 50px;
  height: auto;
}
.credit-card {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #263238;
  font-family: GothamBook, "sans-serif";
}
.credit-card > svg {
  margin-right: 10px;
}

.line-text {
  text-transform: uppercase;
  background-color: #ffffff;
  font-family: GothamBold, "sans-serif";
  font-size: 30px;
  padding: 80px 0px;
  gap: 50px;
  color: #263238;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.request-button {
  border: 3px solid #fbbc04;
  font-family: GothamMedium, "sans-serif";
  border-radius: 10px;
  padding: 7px 50px;
  font-size: 21px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}

.request-button:hover {
  background-color: #fbbc04;
}

.yellow {
  color: #fbbc04;
  margin-left: 5px;
}

.ecommerce-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
  max-width: 1284px;
  margin: auto;
  padding: 50px;
}

.ecommerce-main-out {
  background-color: #f8f8f8;
}

.headline-ecommerce {
  text-transform: uppercase;
  font-size: 40px;
  text-align: center;
  font-family: GothamBold, "sans-serif";
  color: #263238;
  margin-bottom: 15px;
}

.description-ecommerce {
  font-family: GothamMedium, "sans-serif";
  text-align: center;
  color: #263238;
  font-size: 22px;
}

.plat-info {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.row-plat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
}
.row-plat > span {
  color: #787878;
  text-align: center;
  font-family: GothamBook, "sans-serif";
  font-size: 18px;
}
.shopify-logo {
  width: 280px;
}
.woocommerce-logo {
  width: 340px;
}
.box-logo {
  display: flex;
  align-items: center;
  max-height: 108px;
  min-height: 108px;
  margin-bottom: 10px;
}

.text-column {
  background-color: #f8f8f8;
  flex: 1;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: column;
  align-items: center;
}

.phone-column {
  flex: 1;
  -webkit-box-flex: 1;
  background-color: #f8f8f8;
}

.text-section {
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  grid-template-columns: 1fr;
  height: 110vh;
  max-width: 500px;
}

.text-section-main-title {
  margin-bottom: 150px;
  font-size: 45px;
  font-family: GothamMedium, "sans-serif";
  color: #404040;
  white-space: pre;
}

.text-section-title {
  padding-top: 20px;
  padding-bottom: 0px;
  font-size: 40px;
  font-family: GothamMedium, "sans-serif";
  color: #404040;
  white-space: pre;
}
.text-section-subtitle {
  padding-top: 0px;
  padding-bottom: 8px;
  font-size: 20px;
  font-family: GothamBook, "sans-serif";
  color: #404040;
}

.inner-section {
  grid-row: 2;
}

.phone-box {
  display: inline-block;
  width: 100%;
}

.phone-element {
  border-radius: 8px;
  width: auto;
  height: 600px;
}

.number-section {
  color: #534c6f;
  opacity: 0.5;
}

.line-black {
  background-color: #263238;
}

.line-yellow {
  background-color: #fbbc04;
}

.line-blue {
  background-color: #517da4;
}

.notification-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  padding: 100px 0px;
}

.noti-section-title {
  max-width: 1000px;
  color: #263238;
  font-family: GothamMedium;
  font-size: 35px;
  text-align: center;
  line-height: 40px;
  margin-bottom: 20px;
}

.noti-section-description {
  max-width: 1000px;
  color: #263238;
  font-family: GothamBook;
  font-size: 20px;
  text-align: center;
}

.notification-inner-section {
  max-width: 1248px;
  margin: auto;
  height: 100%;
}

.notification-title {
  font-family: GothamBold;
  text-transform: uppercase;
  color: #263238;
  font-size: 40px;
  line-height: 55px;
  text-align: center;
}

.notification-text {
  color: #263238;
  font-family: GothamBook;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.row-notification {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 76%;
  margin-top: 150px;
}

.row-notification > div {
  flex: 1 1;
}

.row-notification > div > svg {
  width: 100%;
  height: 500px;
}

.title-row {
  padding-top: 20px;
  padding-bottom: 0px;
  font-size: 35px;
  font-family: GothamMedium, "sans-serif";
  color: white;
  white-space: pre;
}

.description-row {
  padding-top: 0px;
  padding-bottom: 8px;
  font-size: 18px;
  font-family: GothamBook, "sans-serif";
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
}

.button-notification {
  background-color: white;
  padding: 12px;
  color: #263238;
  border: 1px solid #263238;
  border-radius: 5px;
  font-family: GothamBook, "sans-serif";
  margin-right: 25px;
  width: 300px;
  text-transform: uppercase;
  margin-right: 0px;
}

.demo-title {
  font-family: GothamBold;
  color: #263238;
  font-size: 40px;
  text-align: center;
}

.demo-description {
  color: #263238;
  font-family: GothamBook;
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 60px;
  padding-left: 50px;
  padding-right: 50px;
  text-align: center;
}

.demo-button {
  background-color: white;
  padding: 12px;
  color: #263238;
  border: 2px solid #263238;
  border-radius: 5px;
  font-family: GothamBook, "sans-serif";
  margin-right: 25px;
  width: 300px;
  transition: 0.2s transform ease-in-out;
}

.one-square-notification {
  border: 1px solid white;
  border-radius: 6px;
  height: 100px;
  width: 100px;
  top: 55%;
  right: 4%;
  transform: rotate(120deg);
}

.two-square-notification {
  border: 1px solid white;
  border-radius: 5px;
  height: 50px;
  width: 50px;
  top: 88%;
  right: 3%;
  transform: rotate(120deg);
}

.three-square-notification {
  border: 1px solid white;
  border-radius: 5px;
  height: 60px;
  width: 60px;
  top: 76%;
  right: 50%;
  transform: rotate(120deg);
}

.four-square-notification {
  border: 1px solid white;
  border-radius: 5px;
  height: 60px;
  width: 60px;
  top: 82%;
  left: 10%;
  transform: rotate(120deg);
}

.five-square-notification {
  border: 1px solid white;
  border-radius: 3px;
  height: 20px;
  width: 20px;
  top: 94%;
  left: 55%;
  transform: rotate(120deg);
}

.six-square-notification {
  border: 1px solid white;
  border-radius: 3px;
  height: 20px;
  width: 20px;
  top: 90%;
  left: 35%;
  transform: rotate(120deg);
}

.seven-square-notification {
  border: 1px solid white;
  border-radius: 3px;
  height: 15px;
  width: 15px;
  top: 56%;
  left: 79%;
  transform: rotate(120deg);
}

.eight-square-notification {
  border: 1px solid white;
  border-radius: 2px;
  height: 10px;
  width: 10px;
  top: 92%;
  left: 76%;
  transform: rotate(120deg);
}

.nine-square-notification {
  border: 1px solid white;
  border-radius: 3px;
  height: 50px;
  width: 50px;
  top: 60%;
  left: 4%;
  transform: rotate(120deg);
}

.booknow-innersection {
  max-width: 1248px;
  margin: auto;
  height: 100%;
  padding: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.arrow-booknow {
  width: auto;
  height: 230px;
  position: absolute;
  top: 45%;
  z-index: -1;
}

.dashboard-section {
  position: relative;
}

.dashboard-innersection {
  background-color: #263238;
  margin: 150px;
  height: 1000px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
}

.title-dashboard {
  font-family: GothamMedium;
  color: white;
  font-size: 40px;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 70px;
}

.dashboard-menu {
  background-color: white;
  padding: 10px;
  border-radius: 20px;
  width: 850px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 1000;
  box-shadow: 0 1px 2px rgb(0 0 0 / 0%), 0 19px 5px rgb(0 0 0 / 1%),
    0 4px 5px rgb(0 0 0 / 0%), 0px 0px 6px rgb(0 0 0 / 10%),
    0 16px 32px rgb(0 0 0 / 1%), 0 2px 4px rgb(0 0 0 / 0%);
}

.dashboard-item {
  padding: 10px 20px;
  border-radius: 30px;
  color: #263238;
  font-family: GothamMedium;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  cursor: pointer;
}

.dashboard-item:hover {
  background-color: #fbbd0434;
}

.selected:hover {
  background-color: #fbbd0479;
}

.dashboard-item > span {
  line-height: 0px;
}

.selected {
  background-color: #fbbd0479;
}

.dashboard-icon {
  margin-right: 5px;
  height: 25px;
}

.square-ten {
  border: 1px solid white;
  border-radius: 5px;
  height: 100px;
  width: 100px;
  top: 26%;
  right: 14%;
  transform: rotate(20deg);
}

.square-eleven {
  border: 1px solid white;
  border-radius: 4px;
  height: 60px;
  width: 60px;
  top: 12%;
  left: 12%;
  transform: rotate(20deg);
}

.square-twelve {
  border: 1px solid white;
  border-radius: 5px;
  height: 80px;
  width: 80px;
  top: 6%;
  left: 72%;
  transform: rotate(20deg);
}

.square-thirteen {
  border: 1px solid white;
  border-radius: 5px;
  height: 70px;
  width: 70px;
  top: 23%;
  left: 23%;
  transform: rotate(20deg);
}

.square-fourteen {
  border: 1px solid white;
  border-radius: 5px;
  height: 70px;
  width: 70px;
  top: 23%;
  left: 23%;
  transform: rotate(20deg);
}

.square-fifteen {
  border: 1px solid white;
  border-radius: 2px;
  height: 15px;
  width: 15px;
  top: 31%;
  right: 28%;
  transform: rotate(20deg);
}

.square-sixteen {
  border: 1px solid white;
  border-radius: 2px;
  height: 15px;
  width: 15px;
  top: 7%;
  right: 74%;
  transform: rotate(20deg);
}

.square-seventeen {
  border: 1px solid white;
  border-radius: 3px;
  height: 50px;
  width: 50px;
  top: 47%;
  left: 12%;
  transform: rotate(20deg);
}

.square-heighteen {
  border: 1px solid white;
  border-radius: 3px;
  height: 30px;
  width: 30px;
  top: 53%;
  right: 15%;
  transform: rotate(20deg);
}

.dashboard-content {
  position: relative;
  margin-top: -750px;
}

.grid-container {
  overflow: hidden;
}

.dashboard-grid {
  display: grid;
  position: relative;
  grid-template-columns: repeat(60, 160px);
  grid-template-rows: repeat(6, 160px);
  grid-gap: 20px;
  align-items: start;
  justify-items: center;
  left: -360px;
  transition: left 1.2s cubic-bezier(0.25, 0.1, 0.25, 1) 0s;
}

.grid-item {
  grid-column-end: span 2;
  position: relative;
}

.row-one {
  align-self: start;
  justify-self: start;
}

.device-frame {
  max-width: none;
  position: relative;
  z-index: 10;
  width: 818px;
  height: auto;
  left: 0;
}

.iphone-frame {
  max-width: none;
  position: relative;
  z-index: 12;
  width: 366px;
  left: 0;
}

.iphone-showcase {
  position: absolute;
  top: 15px;
  left: 15px;
  bottom: 164px;
  width: 227px;
  z-index: 11;
}

.showcase {
  position: absolute;
  top: 21px;
  left: 22px;
  bottom: 140px;
  width: 657px;
}

.device-1 {
  grid-column-start: 1;
  display: block;
}
.device-2 {
  grid-column-start: 6;
  display: block;
}
.device-3 {
  grid-column-start: 11;
  display: block;
}
.device-4 {
  grid-column-start: 16;
  display: block;
}
.device-5 {
  grid-column-start: 21;
  display: block;
}
.device-6 {
  grid-column-start: 26;
  display: block;
}
.device-7 {
  grid-column-start: 31;
  display: block;
}
.device-8 {
  grid-column-start: 36;
  display: block;
}
.device-9 {
  grid-column-start: 41;
  display: block;
}

.device-15 {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 14;
  grid-row: 2 / span 4;
  align-self: end;
  top: 60px;
  left: 0px;
}

.device-16 {
  position: relative;
  grid-column-start: 23;
  grid-column-end: 17;
  grid-row: 2 / span 4;
  align-self: end;
  top: 60px;
  left: 40px;
}

.device-17 {
  position: relative;
  grid-column-start: 23;
  grid-column-end: 31;
  grid-row: 2 / span 4;
  align-self: end;
  top: 60px;
  left: 40px;
}

.device-18 {
  position: relative;
  grid-column-start: 36;
  grid-column-end: 44;
  grid-row: 2 / span 4;
  align-self: end;
  top: 60px;
  left: 40px;
}

.headline-item-container {
  text-align: left;
  padding: 0;
  max-width: 450px;
  justify-self: center;
  align-self: center;
}

.headline-design {
  grid-column: 8 / span 3;
  grid-row: 4 / span 2;
}

.headline-notification {
  grid-column: 16 / span 3;
  grid-row: 4 / span 2;
}

.headline-analytics {
  grid-column: 28 / span 3;
  grid-row: 4 / span 2;
}

.headline-settings {
  grid-column: 36 / span 3;
  grid-row: 4 / span 2;
}

.headline-item-container > h1 {
  font-family: GothamMedium;
  color: #263238;
  font-size: 40px;
}

.headline-item-container > p {
  font-size: 20px;
  line-height: 32px;
  color: #263238;
  font-family: GothamBook;
}

.yellow-bg {
  background-color: #fbbc04;
}

.dark-bg {
  background-color: #263238;
}

.blue-bg {
  background-color: #517da4;
}

.video-box-mobile {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 30px;
  margin-bottom: 100px;
}

@media only screen and (min-width: 1024px) {
  .web {
    display: flex;
  }
  .mobile {
    display: none;
  }
}

@media only screen and (min-width: 1240px) {
  .title-dashboard {
    margin-top: 150px;
    margin-bottom: 100px;
    font-size: 50px;
  }
  .dashboard-grid {
    left: -300px;
  }
  .product-section-mobile {
    display: none;
  }
}

@media only screen and (min-width: 1400px) {
  .dashboard-grid {
    left: -480px;
  }
  .dashboard-content {
    margin-top: -750px;
  }
  .dashboard-menu {
    position: relative;
    top: -10%;
  }
  .product-section {
    display: block;
  }
}

@media only screen and (max-width: 1536px) {
  .header {
    margin-right: 50px;
    margin-left: 50px;
  }
  .inner-section {
    max-width: 80%;
  }
  .headline-settings {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 1400px) {
  .dashboard-innersection {
    margin: 50px;
  }

  .dashboard-content {
    margin-top: -50%;
  }
  .dashboard-menu {
    position: relative;
    top: -10%;
  }
  .phone-element {
    height: 450px;
    margin: auto;
  }
  .inner-section-mobile {
    margin: 50px;
  }
  .row-notification {
    max-width: 1024px;
    margin: auto;
  }
  .col {
    width: 60%;
  }
}

@media only screen and (max-width: 1256px) {
  .product-section {
    display: none;
  }
  .product-section-mobile {
    display: block;
  }
  .header {
    margin: 0;
    margin-top: 50px;
    padding: 20px;
  }
  .dashboard-content {
    margin-top: -65%;
  }
  .dashboard-menu {
    top: -5%;
  }
  .title-header {
    font-size: 40px;
  }
}

@media only screen and (max-width: 1028px) {
  .col {
    width: 50%;
  }
  #notification-video {
    height: auto !important;
    min-width: 100vw;
  }
  .web {
    display: none;
  }
  .mobile {
    display: flex;
  }
  .header {
    margin-left: 10px;
    margin-right: 10px;
  }

  .video-main {
    width: 95%;
  }

  .col {
    width: 100%;
  }

  .title-header {
    font-size: 40px;
  }

  .description-header {
    font-size: 18px;
  }

  .button-line {
    flex-direction: column;
  }

  .button-trial {
    width: 80%;
  }

  .line-button {
    justify-content: flex-start;
    margin-top: 20px;
    margin-left: 0px;
  }

  .line-button > svg {
    width: 40px;
  }

  .credit-card {
    margin-left: 3px;
  }

  .line-text {
    display: inline-block;
    height: auto;
    padding: 30px 20px;
    text-align: center;
    margin: auto;
  }

  .ecommerce-main {
    padding: 20px;
    height: auto;
  }

  .plat-info {
    flex-direction: column;
  }

  .row-plat {
    padding: 30px;
  }

  .notification-inner-section {
    padding: 20px;
  }

  .notification-title {
    font-size: 25px;
    line-height: 35px;
  }

  .notification-text {
    font-size: 18px;
  }

  .row-notification {
    flex-direction: column;
  }

  .title-row {
    white-space: normal;
    text-align: center;
  }

  .title-dashboard {
    margin-top: 30px;
    margin-bottom: 20px;
    position: relative;
    top: 15%;
  }

  .description-row {
    text-align: center;
  }

  .row-notification > div {
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .booknow-innersection {
    padding: 20px;
  }

  .demo-title {
    font-size: 35px;
  }

  .demo-description {
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .arrow-booknow {
    height: 120px;
    transform: rotate(13deg);
    top: 54%;
    left: -14%;
  }

  .dashboard-section {
    overflow: hidden;
  }

  .dashboard-menu {
    position: relative;
    width: 90%;
    top: 20%;
  }

  .dashboard-item {
    margin-right: 0px;
  }

  .one-square {
    width: 70px;
    height: 70px;
    top: 4%;
    left: 3%;
    border: 2px solid #263238;
  }

  .two-square {
    border: 3px solid #b0cbd8;
    border-radius: 5px;
    height: 70px;
    width: 70px;
    top: 3%;
    left: 91%;
  }

  .three-square {
    border: 4px solid #263238;
    border-radius: 10px;
    height: 90px;
    width: 90px;
    top: 70%;
    left: 87%;
    transform: rotate(-170deg);
  }

  .one-square-notification {
    width: 70px;
    height: 70px;
    top: 50%;
    right: 4%;
  }
  .four-square-notification {
    top: 90%;
    left: 10%;
  }
  .seven-square-notification {
    top: 54%;
    left: 14%;
  }
  .three-square-notification {
    display: none;
  }
  .dashboard-innersection {
    margin: 0px;
    height: 55vh;
    border-radius: 0px;
  }
  .dashboard-content {
    margin-top: -750px;
  }
  .dashboard-grid {
    display: grid;
    position: relative;
    left: 0;
    grid-template-columns: repeat(8, 50vw);
    grid-template-rows: repeat(4, auto);
    align-items: center;
    justify-items: center;
    gap: 0px;
  }
  .grid-item {
    grid-column-end: span 2;
  }
  .device-frame {
    width: 110%;
    left: 2%;
  }
  .showcase {
    position: absolute;
    top: 3%;
    width: 90%;
    left: 5%;
    bottom: 20%;
  }
  .device-1 {
    display: none;
  }
  .device-2 {
    grid-column-start: 1;
  }
  .device-3 {
    display: none;
  }
  .device-4 {
    grid-column-start: 3;
  }
  .device-5 {
    display: none;
  }
  .device-6 {
    grid-column-start: 5;
  }
  .device-7 {
    display: none;
  }
  .device-8 {
    grid-column-start: 7;
  }
  .device-15 {
    grid-column-start: 1;
    grid-row: 4 / span 4;
    top: 0px;
    grid-column-end: 3;
    left: 50px;
  }
  .headline-item-container {
    padding: 20px;
  }
  .headline-design {
    grid-row: 2 / span 2;
    grid-column: 1 / span 2;
  }
  .headline-notification {
    grid-row: 2 / span 2;
    grid-column: 3 / span 2;
  }
  .headline-analytics {
    grid-row: 2 / span 2;
    grid-column: 5 / span 2;
  }
  .headline-settings {
    grid-row: 2 / span 2;
    grid-column: 7 / span 2;
  }
  .device-16 {
    grid-column-start: 5;
    grid-row: 4 / span 4;
    top: 0px;
    grid-column-end: 3;
    left: 50px;
  }
  .device-17 {
    grid-column-start: 7;
    grid-row: 4 / span 4;
    top: 0px;
    grid-column-end: 5;
    left: 50px;
  }
  .device-18 {
    grid-column-start: 9;
    grid-row: 4 / span 4;
    top: 0px;
    grid-column-end: 7;
    left: 50px;
  }
  .square-eleven {
    width: 50px;
    height: 50px;
    top: 1%;
  }
  .square-twelve {
    width: 60px;
    height: 60px;
    top: 3%;
  }
  .square-thirteen {
    height: 40px;
    width: 40px;
    top: 30%;
    left: 93%;
  }
  .square-fourteen {
    height: 30px;
    width: 30px;
    top: 23%;
    left: 0%;
  }
  .square-sixteen {
    top: 15%;
    right: 84%;
  }
  .square-seventeen {
    height: 20px;
    width: 20px;
    top: 12%;
    left: 87%;
  }
  .text-section-title {
    white-space: normal;
    font-size: 30px;
  }
  .text-section-subtitle {
    font-size: 18px;
  }
  .inner-section-mobile {
    margin: 30px;
  }
  .dashboard-content {
    margin-top: -20%;
  }
  .line-text {
    display: flex;
    padding: 100px 0px;
    gap: 0px;
  }
  .request-button {
    width: 300px;
    margin-top: 0px;
  }

  #main-video {
    margin-top: -15%;
    height: auto;
    width: 100%;
  }

  .container-home {
    height: 140vw;
    position: relative;
    z-index: 2;
  }

  .header {
    margin: 0;
    margin-top: 50px;
    padding: 20px;
  }

  .ecommerce-main {
    padding: 100px 0;
  }

  .row-plat {
    padding: 40px 30px;
  }
}

@media only screen and (max-width: 768px) {
  .dashboard-item > span {
    display: none;
  }
  .header {
    margin: 0;
    margin-top: 50px;
  }
  .container-home {
    height: calc(140vw);
    position: relative;
    z-index: 20;
  }
  #main-video {
    margin-top: -20%;
    height: auto;
    width: 100%;
  }
  .title-header {
    font-size: 35px;
    line-height: 45px;
  }
  .header {
    padding: 0 10px;
  }
  .bg-home-mobile {
    height: calc(135vh);
    width: 100%;
    background-color: #f8f8f8 !important;
    position: absolute;
    top: 0;
    z-index: -2;
    left: 0;
  }
  .booknow-section {
    padding: 0px;
  }
  .main-content {
    background: none;
  }
  .line-text {
    font-size: 24px;
  }
  .request-button {
    font-size: 18px;
    margin-top: 20px;
    padding: 7px 40px;
  }
  .headline-ecommerce {
    font-size: 30px;
  }
  .description-ecommerce {
    font-size: 20px;
  }
  .noti-section-title {
    font-size: 30px;
    padding: 0 20px;
  }
  .noti-section-description {
    font-size: 18px;
    padding: 0 20px;
  }
  .booknow-section {
    padding: 150px 20px;
  }
  .dashboard-menu {
    top: 15%;
  }
}

@media only screen and (max-width: 600px) {
  .container-home {
    height: calc(270.667vw);
  }
  .dashboard-content {
    margin-top: -30%;
  }
  .ecommerce-main {
    width: 100%;
    overflow: hidden;
  }

  #main-video {
    margin-top: 80%;
    height: auto;
    width: 100%;
  }

  .title-dashboard {
    top: 0%;
    font-size: 35px;
  }

  .dashboard-menu {
    top: 0% !important;
  }

  #main-video {
    margin-top: 110%;
  }

  .header {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .dashboard-item {
    padding: 10px 14px;
  }
  .dashboard-menu {
    top: 0%;
    width: 95%;
  }
  .demo-button {
    width: 200px;
    margin-right: 0px;
  }
}

@media only screen and (max-width: 450px) {
  .header {
    width: 100%;
  }
  .title-header {
    font-size: 30px;
    line-height: 40px;
  }
  .dashboard-innersection {
    height: 40vh;
  }
  .title-dashboard {
    font-size: 30px;
  }
  .demo-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 380px) {
  .main-button {
    width: 95%;
    font-size: 14px;
    padding: 12px 30px;
  }
  .container-home {
    height: calc(370.667vw);
  }
  #main-video {
    margin-top: 210%;
  }
  .woocommerce-logo {
    width: 300px;
  }
  .shopify-logo {
    width: 280px;
  }
  .inner-section-mobile {
    margin: 20px;
  }
  .booknow-section {
    padding: 100px 10px;
  }
}

@media only screen and (max-width: 340px) {
  .dashboard-content {
    margin-top: -50%;
  }
  .dashboard-menu {
    top: 0%;
  }
  .headline-ecommerce {
    font-size: 20px;
  }
}
</style>
