<template>
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.9894 29.4224C19.6431 29.4224 19.2969 29.4124 18.9488 29.3937C12.8594 29.0531 7.2525 25.7943 3.94938 20.6762C3.575 20.0962 3.74188 19.3225 4.32188 18.9481C4.90313 18.5737 5.67563 18.7412 6.05 19.3206C8.92 23.7693 13.7944 26.6018 19.0881 26.8974C24.7856 27.2181 30.3631 24.4956 33.6306 19.7968C33.7394 19.6406 33.8456 19.4818 33.9494 19.3206C34.3231 18.7406 35.0963 18.5737 35.6775 18.9475C36.2575 19.3218 36.4244 20.095 36.0506 20.6756C35.9319 20.86 35.8094 21.0425 35.6838 21.2231C32.1238 26.3431 26.1838 29.4224 19.9894 29.4224Z" fill="#263238"/>
<path d="M35.0012 21.2482C34.5905 21.2482 34.1887 21.0463 33.9493 20.6757C31.0793 16.227 26.2049 13.3945 20.9112 13.0988C15.2055 12.7732 9.63555 15.5007 6.36867 20.1995C6.25992 20.3557 6.15367 20.5145 6.04992 20.6757C5.67617 21.2557 4.90242 21.4226 4.3218 21.0488C3.7418 20.6745 3.57492 19.9013 3.94867 19.3207C4.06742 19.1363 4.18992 18.9538 4.31555 18.7732C8.07492 13.367 14.4843 10.2438 21.0505 10.6026C27.1399 10.9432 32.7468 14.202 36.0499 19.3201C36.4243 19.9001 36.2574 20.6738 35.6774 21.0482C35.468 21.1838 35.233 21.2482 35.0012 21.2482Z" fill="#263238"/>
<path d="M20 24.998C17.2431 24.998 15 22.7549 15 19.998C15 17.2412 17.2431 14.998 20 14.998C22.7569 14.998 25 17.2412 25 19.998C25 22.7549 22.7569 24.998 20 24.998ZM20 17.498C18.645 17.498 17.5 18.643 17.5 19.998C17.5 21.353 18.645 22.498 20 22.498C21.355 22.498 22.5 21.353 22.5 19.998C22.5 18.643 21.355 17.498 20 17.498Z" fill="#263238"/>
</svg>

</template>
<script>
export default {
    name: 'NotificationIcon',
    data() {
        return {}
    },
    props: {
        fill: {
            default: '--color-primary'
        }
    },
}
</script>