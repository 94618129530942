<template>
    <svg width="163px" height="auto" viewBox="0 0 133 43" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- Generator: Sketch 59.1 (86144) - https://sketch.com -->
        <title>Apple Dev</title>
        <desc>Created with Sketch.</desc>
        <g id="Apple-Dev" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Apple_Developer_brandmark" transform="translate(2.000000, 8.000000)" fill="#000000" fill-rule="nonzero">
                <g id="Group" transform="translate(25.920000, 5.280000)">
                    <path d="M6.24290405,0.216240672 C11.2702478,0.216240672 14.2468103,3.30999019 14.2468103,8.6420219 C14.2468103,13.9857719 11.2819666,17.1266313 6.24290405,17.1266313 L0.27805848,17.1266313 L0.27805848,0.216240672 L6.24290405,0.216240672 Z M2.90305848,14.852959 L5.9968103,14.852959 C9.58274779,14.852959 11.5632166,12.6615528 11.5632166,8.66545901 C11.5632166,4.68108403 9.57102907,2.47795901 5.9968103,2.47795901 L2.90305848,2.47795901 L2.90305848,14.852959 L2.90305848,14.852959 Z" id="Shape"></path>
                    <path d="M26.6965759,13.5053028 C26.3567322,15.743584 24.1770447,17.2789746 21.3879822,17.2789746 C17.8020447,17.2789746 15.5754822,14.8763965 15.5754822,11.0209277 C15.5754822,7.15374024 17.8137634,4.64592773 21.2825134,4.64592773 C24.6926697,4.64592773 26.8372009,6.98967773 26.8372009,10.727959 L26.8372009,11.5951464 L18.1301697,11.5951464 L18.1301697,11.7474902 C18.1301697,13.8568652 19.4543884,15.2396777 21.4465759,15.2396777 C22.8528259,15.2396777 23.9543884,14.5717089 24.2942322,13.5053027 L26.6965759,13.5053027 L26.6965759,13.5053028 Z M18.1418884,9.82561522 L24.3059509,9.82561522 C24.2473572,7.93889645 23.0403259,6.69670896 21.2825135,6.69670896 C19.5364197,6.69670896 18.2707947,7.96233398 18.1418884,9.82561522 Z" id="Shape"></path>
                    <polygon id="Path" points="34.5877478 17.1266309 31.8689978 17.1266309 27.4275915 4.79827152 30.122904 4.79827152 33.216654 14.6537403 33.263529 14.6537403 36.3572754 4.79827152 39.0057129 4.79827152"></polygon>
                    <path d="M50.4769629,13.5053028 C50.1371192,15.743584 47.9574317,17.2789746 45.1683692,17.2789746 C41.5824317,17.2789746 39.3558692,14.8763965 39.3558692,11.0209277 C39.3558692,7.15374024 41.5941504,4.64592773 45.0629004,4.64592773 C48.4730567,4.64592773 50.6175879,6.98967773 50.6175879,10.727959 L50.6175879,11.5951464 L41.9105567,11.5951464 L41.9105567,11.7474902 C41.9105567,13.8568652 43.2347754,15.2396777 45.2269629,15.2396777 C46.6332129,15.2396777 47.7347754,14.5717089 48.0746192,13.5053027 L50.4769629,13.5053027 L50.4769629,13.5053028 Z M41.9222754,9.82561522 L48.0863379,9.82561522 C48.0277441,7.93889645 46.8207129,6.69670896 45.0629004,6.69670896 C43.3168067,6.69670896 42.0511816,7.96233398 41.9222754,9.82561522 Z" id="Shape"></path>
                    <polygon id="Path" points="52.6683691 0.216240672 55.1996191 0.216240672 55.1996191 17.1266309 52.6683691 17.1266309"></polygon>
                    <path d="M57.0586816,10.962334 C57.0586816,7.08342773 59.3438379,4.64592773 62.9063379,4.64592773 C66.4805567,4.64592773 68.7539941,7.08342773 68.7539941,10.962334 C68.7539941,14.852959 66.4922754,17.2789746 62.9063379,17.2789746 C59.3204004,17.2789746 57.0586816,14.852959 57.0586816,10.962334 Z M66.1758691,10.962334 C66.1758691,8.30217773 64.9571191,6.73186522 62.9063379,6.73186522 C60.8555567,6.73186522 59.6368067,8.31389645 59.6368067,10.962334 C59.6368067,13.634209 60.8555567,15.1928028 62.9063379,15.1928028 C64.9571191,15.1928028 66.1758691,13.634209 66.1758691,10.962334 Z" id="Shape"></path>
                    <path d="M82.0843067,10.962334 C82.0843067,14.7943652 80.0335254,17.2555371 76.9397754,17.2555371 C75.1819629,17.2555371 73.7874317,16.4703808 73.0608692,15.0990527 L73.0022754,15.0990527 L73.0022754,21.2047558 L70.4710254,21.2047558 L70.4710254,4.79827147 L72.9202442,4.79827147 L72.9202442,6.8490527 L72.9671192,6.8490527 C73.6702442,5.52483394 75.1702442,4.66936522 76.8929004,4.66936522 C80.0218067,4.66936522 82.0843067,7.14202147 82.0843067,10.962334 Z M79.4827441,10.962334 C79.4827441,8.46624024 78.1936816,6.82561522 76.2249316,6.82561522 C74.2913379,6.82561522 72.9905567,8.50139645 72.9905567,10.962334 C72.9905567,13.446709 74.2913379,15.1107715 76.2249316,15.1107715 C78.1936816,15.1107715 79.4827441,13.4818652 79.4827441,10.962334 Z" id="Shape"></path>
                    <path d="M94.5038379,13.5053028 C94.1639941,15.743584 91.9843067,17.2789746 89.1952441,17.2789746 C85.6093067,17.2789746 83.3827441,14.8763965 83.3827441,11.0209277 C83.3827441,7.15374024 85.6210254,4.64592773 89.0897754,4.64592773 C92.4999316,4.64592773 94.6444629,6.98967773 94.6444629,10.727959 L94.6444629,11.5951464 L85.9374316,11.5951464 L85.9374316,11.7474902 C85.9374316,13.8568652 87.2616504,15.2396777 89.2538379,15.2396777 C90.6600879,15.2396777 91.7616504,14.5717089 92.1014941,13.5053027 L94.5038379,13.5053027 L94.5038379,13.5053028 Z M85.9491504,9.82561522 L92.1132129,9.82561522 C92.0546192,7.93889645 90.8475879,6.69670896 89.0897754,6.69670896 C87.3436816,6.69670896 86.0780567,7.96233398 85.9491504,9.82561522 Z" id="Shape"></path>
                    <path d="M96.4814941,4.79827147 L98.8955567,4.79827147 L98.8955567,6.89592773 L98.9541504,6.89592773 C99.3408692,5.5013965 100.465869,4.66936522 101.918994,4.66936522 C102.282275,4.66936522 102.586963,4.71624019 102.786182,4.76311522 L102.786182,7.1303027 C102.586963,7.04827147 102.14165,6.97795896 101.649463,6.97795896 C100.020557,6.97795896 99.0127441,8.07952147 99.0127441,9.81389645 L99.0127441,17.1266309 L96.4814941,17.1266309 L96.4814941,4.79827147 L96.4814941,4.79827147 Z" id="Path"></path>
                </g>
                <g id="XMLID_5_">
                    <path d="M12.7008792,3.60642288 C13.4770532,2.63559816 14.0037607,1.33205784 13.8648468,0 C12.7286343,0.056497872 11.3421057,0.749597616 10.5393713,1.72118467 C9.81860318,2.55319886 9.18066648,3.91131422 9.34697294,5.18753232 C10.6224289,5.29817184 11.8967065,4.5500125 12.7008792,3.60642288" id="XMLID_14_"></path>
                    <path d="M13.8503531,5.43671952 C11.998082,5.32638518 10.423201,6.48797011 9.5386441,6.48797011 C8.65360291,6.48797011 7.29906235,5.49231854 5.83401442,5.5191504 C3.92716368,5.5471573 2.15782493,6.6253093 1.19017958,8.34006869 C-0.800104512,11.7704343 0.664942848,16.8588721 2.60038819,19.6527173 C3.5402855,21.0349183 4.67301691,22.5568268 6.16557048,22.5021607 C7.57577923,22.4468207 8.12852155,21.5890265 9.84269448,21.5890265 C11.555604,21.5890265 12.0535081,22.5021607 13.5463038,22.4744989 C15.0943699,22.4468207 16.0621876,21.0916059 17.0020661,19.7080573 C18.0803047,18.1324164 18.5216951,16.6110096 18.5494593,16.5274896 C18.521695,16.4998279 15.5643531,15.3653174 15.5369331,11.9634595 C15.5090132,9.11503512 17.8587745,7.76016638 17.9693665,7.6761457 C16.6424359,5.71360886 14.5691336,5.49231859 13.8503531,5.43671952" id="XMLID_6_"></path>
                </g>
            </g>
        </g>
    </svg>
</template>
<script>
export default {
    name: 'AppleDev',
    data() {
        return {}
    },
    props: {
        fill: {
            default: '--color-primary'
        }
    },
}
</script>