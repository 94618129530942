<template>
  <nav-bar :fillBG="true" />
  <div id="terms" class="container mx-auto">
    <div id="page_1" class="page">
      <div id="id1_1">
        <p class="p0 ft0">Terms and Conditions</p>
        <p class="p1 ft1">
          This website www.napps-solutions.com is operated by N Apps, LDA (“N
          Apps”, “we”, “our” or “us”). N Apps, LDA offers this website,
          including all information, tools, services and mobile app available
          from this site to you, the user, conditioned upon your acceptance of
          all terms, conditions, policies and notices stated here. By using our
          app, site and/ or subscribing, purchasing something from us, you
          engage in our “Service” and agree to be bound by the following terms
          and conditions (“Terms of Service”, “Terms”), including those
          additional terms and conditions and policies referenced herein and/or
          available by hyperlink. These Terms of Service apply to all users of
          the site, app including without limitation users who are browsers,
          vendors, customers, subscriber, merchants, and/ or contributors of
          content. Please read these Terms of Service carefully before accessing
          or using our website and/or app. By accessing or using any part of the
          site, you agree to be bound by these Terms of Service. If you do not
          agree to all the terms and conditions of this agreement, then you must
          not access the website nor use any services. If these Terms of Service
          are considered an offer, acceptance is expressly limited to these
          Terms of Service. Any new features or tools which are added to the
          current store shall also be subject to the Terms of Service. You can
          review the most current version of the Terms of Service at any time on
          this page. We reserve the right to update, change or replace any part
          of these Terms of Service by posting updates and/or changes to our
          website. You are expected to check this page periodically for changes.
          Your continued use of or access to the website following the posting
          of any changes constitutes acceptance of those changes.
        </p>
        <p class="p2 ft2">1. INTELLECTUAL PROPERTY RIGHTS</p>
        <p class="p1 ft1">
          Unless otherwise stated, N Apps and/or its licensors own the
          intellectual property rights for all material on N Apps. All
          intellectual property rights are reserved. Subject to your compliance
          with the Terms, N Apps grants you a limited non-exclusive,
          non-transferable, non-sublicensable, revocable license to download,
          install and use a copy of the N Apps Application on a single mobile
          device or computer that you own or control and to run such copy of the
          N Apps Application solely for your own personal or internal business
          purposes. Furthermore, with respect to any N Apps Application or App
          accessed through or downloaded from the Apple App Store, Android
          Google Play or any other legitimate Store. You must not:
        </p>
        <p class="p3 ft2">
          <span class="ft2">1.1.</span
          ><span class="ft3">Republish material from N Apps;</span>
        </p>
        <p class="p4 ft2">
          <span class="ft2">1.2.</span
          ><span class="ft3">Sell, rent, or </span>sub-license material from N
          Apps;
        </p>
        <p class="p4 ft2">
          <span class="ft2">1.3.</span
          ><span class="ft3"
            >Reproduce, duplicate or copy material from N Apps;</span
          >
        </p>
        <p class="p2 ft2">
          <span class="ft2">1.4.</span
          ><span class="ft3">Redistribute content from N Apps;</span>
        </p>
        <p class="p5 ft4">
          Notwithstanding anything to the contrary herein, you acknowledge and
          agree that you shall have no ownership or other property interest in
          your Account or any App, and you further acknowledge and agree that,
          except with respect to Your Content, all rights in and to your Account
          and Apps are and shall forever be owned by and inure to the benefit of
          N Apps.
        </p>
      </div>
    </div>
    <div id="page_2" class="page">
      <div id="p2dimg1">
        <img
          alt=""
          src="data:image/jpg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4nICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDL/2wBDAQkJCQwLDBgNDRgyIRwhMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjL/wAARCAAUAAQDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD3qiiigAooooA//9k="
          id="p2img1"
        />
      </div>

      <div id="id2_1">
        <p class="p7 ft4">
          N Apps name, logo, and all related names, logos, product and service
          names, designs, and slogans are trademarks of N Apps or N Apps
          licensors. You must not use such marks without N Apps prior written
          permission. All other names, logos, product and service names,
          designs, and slogans on the Website are the trademarks of their
          respective owners.
        </p>
        <p class="p8 ft2">2. Our Service</p>
        <p class="p9 ft4">
          We provide service as a mobile app development platform designed to
          allow you and/or or your business to create and develop Apps operated
          by Apple App Store. We provide you with tools, including designs,
          templates, and layouts, to create and publish your App(s) through the
          Apple App Store, Android Google Play and any other legitimate App
          store. Once completed, we will, on your behalf, upload your Apps on
          our developer accounts on the desired Store and we will help you
          manage the Apps throughout the term of these Terms.
        </p>
        <p class="p10 ft2">3. THIRD-PARTY LINKS</p>
        <p class="p11 ft1">
          Certain content, products and services available via our Service may
          include materials from third-parties. Third-party links on this site
          may direct you to third-party websites that are not affiliated with
          us. We are not responsible for examining or evaluating the content or
          accuracy and we do not warrant and will not have any liability or
          responsibility for any third-party materials or websites, or for any
          other materials, products, or services of third-parties. We are not
          liable for any harm or damages related to the purchase or use of
          goods, services, resources, content, or any other transactions made in
          connection with any third-party websites. Please review carefully the
          third-party's policies and practices and make sure you understand them
          before you engage in any transaction. Complaints, claims, concerns, or
          questions regarding third-party products should be directed to the
          third-party.
        </p>
        <p class="p12 ft2">4. ACCOUNT REGISTRATION</p>
        <p class="p13 ft2">
          <span class="ft2">4.1.</span
          ><span class="ft3">Direct Registration:</span>
        </p>
        <p class="p14 ft4">
          To become a User, you must register and create an Account on N Apps.
          It is a condition that all of the information you provide on the
          Website is correct, complete and current. For purposes of the Terms, a
          “Registered User” is a user who has registered an account on the
          Website or N Apps Application (“Account”), or has a valid Shopify,
          Woocommerce or other third-party e- commerce platform account through
          which you may connect to the Website or N Apps Application (each such
          account, a “Third-Party Account”).
        </p>
        <p class="p10 ft2">
          <span class="ft2">4.2.</span
          ><span class="ft3">Registration through </span>Third-Party Website:
        </p>
        <p class="p15 ft2">
          If you access the N Apps Properties through a Third-Party Account, you
          are allowing N Apps to access your Third-Party Account, as is
          permitted under the applicable terms and conditions that govern your
          use of each Third-Party Account. You represent that you are entitled
          to grant N
        </p>
      </div>
    </div>
    <div id="page_3" class="page">
      <div id="id3_1">
        <p class="p16 ft1">
          Apps access to your Third-Party Account (including, but not limited
          to, for use for the purposes described herein) without breach by you
          of any of the terms and conditions that govern your use of the
          applicable Third-Party Account and without obligating N Apps to pay
          any fees or making N Apps subject to any usage limitations imposed by
          such third-party service providers. By granting N Apps access to any
          Third-Party Accounts, you understand that N Apps may access, make
          available and store (if applicable) any information, data, text,
          software, music, sound, photographs, graphics, video, messages, tags
          and/or other materials accessible through N Apps Properties that you
          have provided to and stored in your Third-Party Account (“Third- Party
          Account Content”) so that it is available on and through N Apps
          Properties via your Account. Unless otherwise specified in the Terms,
          all Third-Party Account Content shall be considered to be Your Content
          (as defined below) for all purposes of the Terms. Depending on the
          Third-Party Accounts you choose and subject to the privacy settings
          that you have set in such Third-Party Accounts, personally
          identifiable information that you post to your Third-Party Accounts
          may be available on and through your Account on N Apps Properties.
          Please note that if a Third-Party Account or associated service
          becomes unavailable or N Apps’s access to such Third-Party Account is
          terminated by the third-party service provider, then Third-Party
          Account Content will no longer be available on and through N Apps
          Properties. N Apps makes no effort to review any Third-Party Account
          Content for any purpose, including but not limited to, for accuracy,
          legality or noninfringement, and N Apps is not responsible for any
          Third-Party Account Content as described above.
        </p>
        <p class="p17 ft4">
          Your Account and Developed Apps. Notwithstanding anything to the
          contrary herein, you acknowledge and agree that you shall have no
          ownership or other property interest in your Account or any App, and
          you further acknowledge and agree that, except with respect to Your
          Content, all rights in and to your Account and Apps are and shall
          forever be owned by and inure to the benefit of N Apps.
        </p>
        <p class="p10 ft2">5. Your Responsibility</p>
        <p class="p18 ft1">
          You shall responsible for all of the contents that you provide. You
          acknowledge that all files, materials, data, text, audio, video,
          images or other content, including N Apps Properties (“Content”), are
          the sole responsibility of the party from whom such Content
          originated. This means that you, and not N Apps, are entirely
          responsible for all Content that you upload, post, e-mail, transmit or
          otherwise make available (“Make Available”) through N Apps Properties
          (“Your Content”), and that you and other Users of N Apps Properties,
          and not N Apps, are similarly responsible for all Content they Make
          Available through N Apps Properties (“User Content”).
        </p>
        <p class="p19 ft2">
          You acknowledge that N Apps has no obligation to pre-screen Content
          (including, but not limited to, User Content), although N Apps
          reserves the right in its sole discretion to pre-screen, refuse or
          remove any Content.
        </p>
      </div>
    </div>
    <div id="page_4" class="page">
      <div id="id4_1">
        <p class="p20 ft4">
          Unless expressly agreed to by N Apps in writing elsewhere, N Apps has
          no obligation to store any of Your Content that you Make Available on
          N Apps Properties. N Apps has no responsibility or liability for the
          deletion or accuracy of any Content, including Your Content; the
          failure to store, transmit or receive transmission of Content; or the
          security, privacy, storage, or transmission of other communications
          originating with or involving use of N Apps Properties.
        </p>
        <p class="p21 ft2">6. USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</p>
        <p class="p1 ft1">
          If, at our request, you send certain specific submissions (for example
          contest entries) or without a request from us you send creative ideas,
          suggestions, proposals, plans, or other materials, whether online, by
          email, by postal mail, or otherwise (collectively, 'comments'), you
          agree that we may, at any time, without restriction, edit, copy,
          publish, distribute, translate and otherwise use in any medium any
          comments that you forward to us. We are and shall be under no
          obligation (1) to maintain any comments in confidence; (2) to pay
          compensation for any comments; or (3) to respond to any comments. We
          may, but have no obligation to, monitor, edit or remove content that
          we determine in our sole discretion are unlawful, offensive,
          threatening, libellous, defamatory, pornographic, obscene or otherwise
          objectionable or violates any party’s intellectual property or these
          Terms of Service. You agree that your comments will not violate any
          right of any third-party, including copyright, trademark, privacy,
          personality or other personal or proprietary right. You further agree
          that your comments will not contain libellous or otherwise unlawful,
          abusive or obscene material, or contain any computer virus or other
          malware that could in any way affect the operation of the Service or
          any related website. You may not use a false e-mail address, pretend
          to be someone other than yourself, or otherwise mislead us or
          third-parties as to the origin of any comments. You are solely
          responsible for any comments you make and their accuracy. We take no
          responsibility and assume no liability for any comments posted by you
          or any third-party.
        </p>
        <p class="p2 ft2">7. PROHIBITED USES</p>
        <p class="p22 ft1">
          In addition to other prohibitions as set forth in the Terms of
          Service, you are prohibited from using the site, app or its content:
          (a) for any unlawful purpose; (b) to solicit others to perform or
          participate in any unlawful acts; (c) to violate any international,
          federal, provincial or state regulations, rules, laws, or local
          ordinances; (d) to infringe upon or violate our intellectual property
          rights or the intellectual property rights of others; (e) to harass,
          abuse, insult, harm, defame, slander, disparage, intimidate, or
          discriminate based on gender, sexual orientation, religion, ethnicity,
          race, age, national origin, or disability; (f) to submit false or
          misleading information; (g) to upload or transmit viruses or any other
          type of malicious code that will or may be used in any way that will
          affect the functionality or operation of the Service or of any related
          website, other websites, or the Internet; (h) to collect or track the
          personal information of others; (i) to spam, phish, pharm, pretext,
          spider, crawl, or scrape; (j) for any obscene or immoral purpose; or
          (k) to interfere with or circumvent the security features of the
          Service or any related website, other websites, or the Internet. We
          reserve the right to terminate your use of the Service or any related
          website for violating any of the prohibited uses.
        </p>
      </div>
    </div>
    <div id="page_5" class="page">
      <p class="p23 ft2">8. BILLING TERMS AND BILLING DISPUTE RESOLUTION</p>
      <p class="p22 ft1">
        We reserve the right to refuse any order and/or service you place with
        us. We may, in our sole discretion, limit or cancel or ban purchased
        and/or subscription per person, or per order. In the event that we make
        a change to or cancel an order, we may attempt to notify you by
        contacting the e-mail and/or billing address/phone number provided at
        the time the order was made. You agree to pay all fees or charges to
        your Account in accordance with the fees, charges and billing terms in
        effect at the time a fee or charge is due and payable. You must provide
        N Apps with a valid credit card (Visa, MasterCard, or any other issuer
        accepted by us) or PayPal account (“Payment Provider”), or purchase
        order information as a condition to signing up for the Services. You
        agree to provide current, complete and accurate purchase and account
        information for all purchases made at our website. You agree to promptly
        update your account and other information, including your email address
        and credit card numbers and expiration dates, so that we can complete
        your transactions and contact you as needed. N Apps’s fees are net of
        any applicable Sales Tax. If any Services, or payments for any Services,
        under the Terms are subject to Sales Tax in any jurisdiction and you
        have not remitted the applicable Sales Tax to N Apps, you will be
        responsible for the payment of such Sales Tax and any related penalties
        or interest to the relevant tax authority, and you will indemnify N Apps
        for any liability or expense we may incur in connection with such Sales
        Taxes.
      </p>
      <p class="p24 ft2">
        If any dispute arises regarding the payment issue. You must notify us in
        writing within fourteen
      </p>
      <p class="p25 ft2">
        <span class="ft2">(14)</span
        ><span class="ft7"
          >days after receiving your credit card statement, if you do not
          respect the time laps or such dispute will be deemed waived. Billing
          disputes should be notified to us at support@napps-
          solutions.com</span
        >
      </p>
      <p class="p10 ft2">
        <span class="ft2">9.</span><span class="ft8">INDEMNIFICATION</span>
      </p>
      <p class="p26 ft4">
        You agree to indemnify, defend and hold harmless N Apps, LDA and our
        parent, subsidiaries, affiliates, partners, officers, directors, agents,
        contractors, licensors, service providers, subcontractors, suppliers,
        interns and employees, harmless from any claim or demand, including
        reasonable attorneys’ fees, made by any third-party due to or arising
        out of your breach of these Terms of Service or the documents they
        incorporate by reference, or your violation of any law or the rights of
        a third-party.
      </p>
      <p class="p10 ft2">
        10. DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY
      </p>
      <p class="p22 ft1">
        We do not guarantee, represent or warrant that our properties or any
        apps will meet your requirements, your use of our service will be
        uninterrupted, timely, secure or error-free. We do not warrant that the
        results that may be obtained from the use of our properties or any apps
        will be accurate or reliable. You agree that from time to time we may
        remove the service for indefinite periods of time or cancel the service
        at any time, without notice to you. You expressly agree that your use
        of, or inability to use, the service is at your sole risk. The service
        and all products and services delivered to you through the service are
        (except as expressly stated by us) provided 'as is' and 'as available'
        for your use, without any representation, warranties or
      </p>
    </div>
    <div id="page_6" class="page">
      <div id="id6_1">
        <p class="p20 ft1">
          conditions of any kind, either express or implied, including all
          implied warranties or conditions of merchantability, merchantable
          quality, fitness for a particular purpose, durability, title, and
          non-infringement. In no case shall N Apps, LDA, our directors,
          officers, employees, affiliates, agents, contractors, interns,
          suppliers, service providers or licensors be liable for any injury,
          loss, claim, or any direct, indirect, incidental, punitive, special,
          or consequential damages of any kind, including, without limitation
          lost profits, lost revenue, lost savings, loss of data, replacement
          costs, or any similar damages, whether based in contract, tort
          (including negligence), strict liability or otherwise, arising from
          your use of any of the service or any products procured using the
          service, or for any other claim related in any way to your use of the
          service or any product, including, but not limited to, any errors or
          omissions in any content, or any loss or damage of any kind incurred
          as a result of the use of the service or any content (or product)
          posted, transmitted, or otherwise made available via the service, even
          if advised of their possibility. Because some states or jurisdictions
          do not allow the exclusion or the limitation of liability for
          consequential or incidental damages, in such states or jurisdictions,
          our liability shall be limited to the maximum extent permitted by law.
        </p>
        <p class="p2 ft2">11. DMCA</p>
        <p class="p28 ft2">
          N Apps voluntarily complies with the Digital Millennium Copyright Act
          (DMCA). Please note that, under the DMCA, any person who knowingly
          makes material misrepresentations in a notification of claimed
          infringement or in a counter-notification may be liable for damages.
        </p>
        <p class="p10 ft2">12. TERMINATION</p>
        <p class="p29 ft1">
          The obligations and liabilities of the parties incurred prior to the
          termination date shall survive the termination of this agreement for
          all purposes. These Terms of Service are effective unless and until
          terminated by either you or us. You may terminate these Terms of
          Service at any time by notifying us that you no longer wish to use our
          Services, or when you cease using our site. If in our sole judgment
          you fail, or we suspect that you have failed, to comply with any term
          or provision of these Terms of Service, we also may terminate this
          agreement at any time without notice and you will remain liable for
          all amounts due up to and including the date of termination; and/or
          accordingly may deny you access to our Services (or any part thereof).
        </p>
        <p class="p2 ft2">13. SEVERABILITY</p>
        <p class="p22 ft4">
          In the event that any provision of these Terms of Service is
          determined to be unlawful, void or unenforceable, such provision shall
          nonetheless be enforceable to the fullest extent permitted by
          applicable law, and the unenforceable portion shall be deemed to be
          severed from these Terms of Service, such determination shall not
          affect the validity and enforceability of any other remaining
          provisions.
        </p>
        <p class="p21 ft2">14. ENTIRE AGREEMENT</p>
        <p class="p29 ft2">
          The failure of us to exercise or enforce any right or provision of
          these Terms of Service shall not constitute a waiver of such right or
          provision. These Terms of Service and any policies or
        </p>
      </div>
    </div>
    <div id="page_7" class="page">
      <div id="id7_1">
        <p class="p16 ft1">
          operating rules posted by us on this site or in respect to The Service
          constitutes the entire agreement and understanding between you and us
          and govern your use of the Service, superseding any prior or
          contemporaneous agreements, communications and proposals, whether oral
          or written, between you and us (including, but not limited to, any
          prior versions of the Terms of Service). Any ambiguities in the
          interpretation of these Terms of Service shall not be construed
          against the drafting party.
        </p>
        <p class="p3 ft2">15. GOVERNING LAW</p>
        <p class="p30 ft2">
          These Terms of Service and any separate agreements whereby we provide
          you Services shall be governed by and construed in accordance with the
          laws of Portugal.
        </p>
        <p class="p8 ft2">16. CHANGES TO TERMS OF SERVICE</p>
        <p class="p31 ft4">
          You can review the most current version of the Terms of Service at any
          time at this page. We reserve the right, at our sole discretion, to
          update, change or replace any part of these Terms of Service by
          posting updates and changes to our website. It is your responsibility
          to check our website periodically for changes. Your continued use of
          or access to our website or the Service following the posting of any
          changes to these Terms of Service constitutes acceptance of those
          changes.
        </p>
        <p class="p10 ft2">17. CONTACT INFORMATION</p>
        <p class="p32 ft2">
          Questions about the Terms of Service should be sent to us at
          support@napps-solutions.com.
        </p>
      </div>
    </div>
  </div>
  <footer2 :minify="true" />
</template>
<script>
import { defineComponent } from "vue";
import NavBar from "@/components/Navbar/Main.vue";
import Footer2 from "@/components/Footer/Main.vue";

export default defineComponent({
  components: {
    NavBar,
    Footer2,
  },
  data: function () {
    return {};
  },
  unmounted() {
    window.scrollTo(0, 0);
  },
  mounted() {},
  methods: {},
  computed: {},
  setup() {
    return {};
  },
});
</script>

<style scoped>
.page {
  margin-top: 20px;
}

@media screen and (max-width: 660px) {
  .page {
    margin: 0px 6px;
  }
}
body {
  margin-top: 0px;
  margin-left: 0px;
}

#terms {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#page_1 {
  position: relative;
  overflow: hidden;
  padding: 0px;
  border: none;
}
#page_1 #id1_1 {
  border: none;
  margin: 0px 0px 0px 0px;
  padding: 0px;
  border: none;
  overflow: hidden;
}
#page_1 #id1_2 {
  border: none;
  margin: 74px 0px 0px 176px;
  padding: 0px;
  border: none;
  overflow: hidden;
}

#page_2 {
  position: relative;
  overflow: hidden;
  padding: 0px;
  border: none;
}
#page_2 #id2_1 {
  border: none;
  margin: 0px 0px 0px 0px;
  padding: 0px;
  border: none;

  overflow: hidden;
}
#page_2 #id2_2 {
  border: none;
  margin: 86px 0px 0px 176px;
  padding: 0px;
  border: none;
  overflow: hidden;
}

#page_2 #p2dimg1 {
  position: absolute;
  top: 683px;
  left: 339px;
  z-index: -1;
  width: 4px;
  height: 20px;
}
#page_2 #p2dimg1 #p2img1 {
  width: 4px;
  height: 20px;
}

#page_3 {
  position: relative;
  overflow: hidden;
  padding: 0px;
  border: none;
}
#page_3 #id3_1 {
  border: none;
  margin: 0px 0px 0px 0px;
  padding: 0px;
  border: none;

  overflow: hidden;
}
#page_3 #id3_2 {
  border: none;
  margin: 118px 0px 0px 176px;
  padding: 0px;
  border: none;

  overflow: hidden;
}

#page_4 {
  position: relative;
  overflow: hidden;
  padding: 0px;
  border: none;
}
#page_4 #id4_1 {
  border: none;
  margin: 0px 0px 0px 0px;
  padding: 0px;
  border: none;

  overflow: hidden;
}
#page_4 #id4_2 {
  border: none;
  margin: 71px 0px 0px 176px;
  padding: 0px;
  border: none;

  overflow: hidden;
}

#page_5 {
  position: relative;
  overflow: hidden;
  padding: 0px;
  border: none;
}

#page_6 {
  position: relative;
  overflow: hidden;
  padding: 0px;
  border: none;
}
#page_6 #id6_1 {
  border: none;
  margin: 0px 0px 0px 0px;
  padding: 0px;
  border: none;
  overflow: hidden;
}
#page_6 #id6_2 {
  border: none;
  margin: 74px 0px 0px 176px;
  padding: 0px;
  border: none;
  overflow: hidden;
}

#page_7 {
  position: relative;
  overflow: hidden;
  padding: 0px;
  border: none;
}
#page_7 #id7_1 {
  border: none;
  margin: 0px 0px 0px 0px;
  padding: 0px;
  border: none;
  overflow: hidden;
}
#page_7 #id7_2 {
  border: none;
  padding: 0px;
  border: none;
  overflow: hidden;
}

.ft0 {
  font: bold 16px "GothamBook";
  line-height: 19px;
}
.ft1 {
  font: 16px "GothamBook";
  line-height: 21px;
}
.ft2 {
  font: 16px "GothamBook";
  line-height: 19px;
}
.ft3 {
  font: 16px "GothamBook";
  margin-left: 48px;
  line-height: 19px;
}
.ft4 {
  font: 16px "GothamBook";
  line-height: 20px;
}
.ft5 {
  font: 8px "GothamBook";
  line-height: 10px;
}
.ft6 {
  font: 8px "GothamBook";
  color: #fafafa;
  line-height: 10px;
}
.ft7 {
  font: 16px "GothamBook";
  margin-left: 4px;
  line-height: 19px;
}
.ft8 {
  font: 16px "GothamBook";
  margin-left: 12px;
  line-height: 19px;
}

.p0 {
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p1 {
  text-align: left;
  margin-top: 13px;
  margin-bottom: 0px;
}
.p2 {
  text-align: left;
  padding-left: 24px;
  margin-top: 12px;
  margin-bottom: 0px;
}
.p3 {
  text-align: left;
  padding-left: 24px;
  margin-top: 11px;
  margin-bottom: 0px;
}
.p4 {
  text-align: left;
  padding-left: 24px;
  margin-top: 13px;
  margin-bottom: 0px;
}
.p5 {
  text-align: left;
  margin-top: 13px;
  margin-bottom: 0px;
}
.p6 {
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p7 {
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p8 {
  text-align: left;
  padding-left: 24px;
  margin-top: 15px;
  margin-bottom: 0px;
}
.p9 {
  text-align: left;
  margin-top: 13px;
  margin-bottom: 0px;
}
.p10 {
  text-align: left;
  padding-left: 24px;
  margin-top: 17px;
  margin-bottom: 0px;
}
.p11 {
  text-align: left;
  margin-top: 13px;
  margin-bottom: 0px;
}
.p12 {
  text-align: left;
  padding-left: 24px;
  margin-top: 43px;
  margin-bottom: 0px;
}
.p13 {
  text-align: left;
  padding-left: 24px;
  margin-top: 23px;
  margin-bottom: 0px;
}
.p14 {
  text-align: left;
  margin-top: 13px;
  margin-bottom: 0px;
}
.p15 {
  text-align: justify;
  margin-top: 13px;
  margin-bottom: 0px;
  text-indent: 4px;
}
.p16 {
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p17 {
  text-align: left;
  margin-top: 12px;
  margin-bottom: 0px;
}
.p18 {
  text-align: left;
  margin-top: 12px;
  margin-bottom: 0px;
}
.p19 {
  text-align: left;
  margin-top: 12px;
  margin-bottom: 0px;
}
.p20 {
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p21 {
  text-align: left;
  padding-left: 24px;
  margin-top: 16px;
  margin-bottom: 0px;
}
.p22 {
  text-align: left;
  margin-top: 13px;
  margin-bottom: 0px;
}
.p23 {
  text-align: left;
  padding-left: 24px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p24 {
  text-align: left;
  margin-top: 12px;
  margin-bottom: 0px;
}
.p25 {
  text-align: left;
  margin-top: 2px;
  margin-bottom: 0px;
}
.p26 {
  text-align: left;
  margin-top: 13px;
  margin-bottom: 0px;
}
.p27 {
  text-align: left;
  padding-left: 176px;
  margin-top: 70px;
  margin-bottom: 0px;
}
.p28 {
  text-align: justify;
  margin-top: 13px;
  margin-bottom: 0px;
}
.p29 {
  text-align: left;
  margin-top: 12px;
  margin-bottom: 0px;
}
.p30 {
  text-align: left;
  margin-top: 13px;
  margin-bottom: 0px;
}
.p31 {
  text-align: left;
  margin-top: 13px;
  margin-bottom: 0px;
}
.p32 {
  text-align: left;
  margin-top: 13px;
  margin-bottom: 0px;
}
</style>