<template>
  <section>
    <div class="container max-w-full mx-auto py-24 px-6">
      <div class="max-w-full md:max-w-6xl mx-auto my-3 md:px-8">
        <div class="relative flex flex-col md:flex-row items-center box-pricing">
          <div
            class="w-11/12 max-w-sm sm:w-3/5 lg:w-1/3 sm:my-5 my-8 relative z-0 rounded-lg shadow-lg md:-mr-4 price-box"
          >
            <div
              class="bg-white py-4 text-black rounded-lg shadow-lg overflow-hidden"
            >
              <div
                class="flex flex-col items-center max-w-sm mx-auto mt-2 text-black px-8 lg:px-6"
              >
                <plan-start class="m-9 mb-16" />
                <div class="flex flex-row">
                  <span class="text-7xl font-bold sm:text-md">99€</span>
                  <span class="month-span" >/mo</span>
                </div>
                <div
                  class="h-1 mx-auto green w-24 opacity-75 mt-1 rounded mb-4"
                ></div>
                <span class="iva-included mb-4 text-gray-700" >{{ $t("pricing.iva") }}</span>
              </div>
              <div class="flex flex-wrap gap-y-4 my-3 px-6 items-destak">
                <span class="text-gray-700 text-lg ml-3"
                  >Push notifications</span
                >
                <span class="text-gray-700 text-lg ml-3"
                  >Last seen products</span
                >
                <span class="text-gray-700 text-lg ml-3"
                  >Search, filter & sort</span
                >
                <span class="text-gray-700 text-lg ml-3"
                  >User account & order follow up</span
                >
              </div>
            </div>
          </div>
          <div
            class="w-full max-w-md sm:w-2/3 lg:w-1/3 sm:my-5 my-8 relative z-10 bg-white rounded-lg shadow-lg price-box"
          >
            <div
              class="bg-white py-10 text-black rounded-lg shadow-lg overflow-hidden"
            >
              <div
                class="flex flex-col items-center text-sm sm:text-md max-w-sm mx-auto mt-2 text-black px-8 lg:px-6"
              >
                <plan-business class="m-9 mb-16" />
                <div class="flex flex-row">
                  <span class="text-7xl font-bold sm:text-md">299€</span>
                  <span class="month-span" >/mo</span>
                </div>
                <div
                  class="h-1 mx-auto blue w-24 opacity-75 mt-1 rounded mb-4"
                ></div>
                <span class="iva-included mb-4 text-gray-700" >{{ $t("pricing.iva") }}</span>
              </div>
              <div class="flex flex-col items-start gap-y-4 my-3 px-6 items-destak">
                <span class="text-gray-700 text-lg ml-3"
                  >Schedule and auto notifications</span
                >
                <span class="text-gray-700 text-lg ml-3">Analytics</span>
                <span class="text-gray-700 text-lg ml-3"
                  >Schedule & multiple homes</span
                >
                <span class="text-gray-700 text-lg ml-3">Related products</span>
              </div>
            </div>
          </div>
          <div
            class="w-11/12 max-w-sm sm:w-3/5 lg:w-1/3 sm:my-5 my-8 relative z-0 rounded-lg shadow-lg md:-mr-4 price-box"
          >
            <div
              class="bg-white py-4 text-black rounded-lg shadow-lg overflow-hidden"
            >
              <div
                class="flex flex-col items-center text-sm sm:text-md max-w-sm mx-auto mt-2 text-black px-8 lg:px-6"
              >
                <plan-enterprise class="m-9 mb-16" />
                <div class="flex flex-row">
                  <span class="text-7xl font-bold sm:text-md">SOON</span>
                </div>
                <div
                  class="h-1 mx-auto red w-24 opacity-75 mt-1 rounded mb-4"
                ></div>
                  <span class="iva-included mb-4 text-gray-700" >{{ $t("pricing.iva") }}</span>
              </div>
              <div class="flex flex-wrap gap-y-4 my-3 px-6 items-destak">
                <span class="text-gray-700 text-lg ml-3"
                  >Customized blocks & layout</span
                >
                <span class="text-gray-700 text-lg ml-3"
                  >Availability in physical store</span
                >
                <span class="text-gray-700 text-lg ml-3">Online Chat</span>
                <span class="text-gray-700 text-lg ml-3"
                  >Dedicated account manager</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { defineComponent } from "vue";
import PlanStart from "./Logos/Plan_Start.vue";
import PlanBusiness from "./Logos/Plan_Business.vue";
import PlanEnterprise from "./Logos/Plan_Enterprise.vue";

export default defineComponent({
  components: {
    PlanStart,
    PlanBusiness,
    PlanEnterprise,
  },
  setup() {
    return {};
  },
});
</script>

<style scoped>
.blue {
  background-color: #b0cbd8;
}
.red {
  background-color: #ff9796;
}
.green {
  background-color: #e1f4de;
}
.starting-container {
  position: relative;
  width: 100%;
}
.starting-text {
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  color: #b5b5b5;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  text-align: center;
}
.price-box {
  width: 100% !important;
}
.text-gray-700 {
  font-family: GothamBook;
}
.text-7xl {
  font-family: GothamMedium;
}
.month-span {
  font-family: GothamBook;
}
.starting-text {
  font-family: GothamBook;
}

.iva-included {
  font-family: GothamBook;
  font-size: 14px;
}

@media only screen and (max-width: 1024px){
  .box-pricing {
    flex-direction: column;
  }
  .price-box {
      min-width: 60%;
  }
  .items-destak {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
