<template>
  <nav-bar :fillBG="fillBG" style="background: none" />
  <div
    id="main-section"
    class="
      flex flex-col
      gap-5
      mt-24
      items-center
      justify-center
      py-5
      content-1
      md:max-w-6xl
      mx-auto
    "
  >
    <div class="h-1 bg-yellow w-24 opacity-75 mt-4 rounded"></div>
    <p class="title-banner">
      {{ $t("pricing.title") }}
    </p>
    <p class="desc-banner">
      {{ $t("pricing.description") }}
    </p>
    <start-now-button class="startnow-button" style="height: auto" />
  </div>
  <horizontal-display />

  <div
    v-on:click="tableHidden = !tableHidden"
    style="cursor: pointer"
    class="mb-20 flex flex-row gap-5 items-center justify-center see-more"
  >
    <plus v-if="!tableHidden" class="inline" />
    <minus v-else class="inline" />
    <p class="text-xl uppercase text-black font-extrabold inline noselect">
      {{ $t("pricing.message_detailed") }}
    </p>
  </div>

  <transition name="fade">
    <table v-if="tableHidden" class="text-left w-full container mx-auto">
      <thead class="flex text-white w-full head-table">
        <tr class="flex w-full header-row">
          <th class="p-4 w-1/4 text-xl header">Features</th>
          <th class="p-4 w-1/4 row text-xl header">Start</th>
          <th class="p-4 w-1/4 row text-xl header">Business</th>
          <th class="p-4 w-1/4 row text-xl header">Enterprise</th>
        </tr>
      </thead>
      <!-- Remove the nasty inline CSS fixed height on production and replace it with a CSS class — this is just for demonstration purposes! -->
      <tbody
        class="bg-grey-light flex flex-col items-center justify-between w-full"
      >
        <tr class="flex w-full">
          <td class="p-4 w-1/4">iOS and Android Apps</td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
        </tr>
        <tr class="flex w-full alternate">
          <td class="p-4 w-1/4">Number of Design Blocks</td>
          <td class="p-4 w-1/4 row">10</td>
          <td class="p-4 w-1/4 row">20</td>
          <td class="p-4 w-1/4 row">Soon</td>
        </tr>
        <tr class="flex w-full">
          <td class="p-4 w-1/4">Push Notifications</td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
        </tr>
        <tr class="flex w-full alternate">
          <td class="p-4 w-1/4">Real Time Sync</td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
        </tr>
        <tr class="flex w-full">
          <td class="p-4 w-1/4">Instantly updates</td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
        </tr>
        <tr class="flex w-full alternate">
          <td class="p-4 w-1/4">One page checkout</td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
        </tr>
        <tr class="flex w-full">
          <td class="p-4 w-1/4">Wishlist</td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
        </tr>
        <tr class="flex w-full alternate">
          <td class="p-4 w-1/4">Search, filter and sort</td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
        </tr>
        <tr class="flex w-full">
          <td class="p-4 w-1/4">
            Multi-Search Options (category, tags, products)
          </td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
        </tr>
        <tr class="flex w-full alternate">
          <td class="p-4 w-1/4">Size Guide</td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
        </tr>
        <tr class="flex w-full">
          <td class="p-4 w-1/4">Shipping Info</td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
        </tr>
        <tr class="flex w-full alternate">
          <td class="p-4 w-1/4">Aditional info on product detail</td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
        </tr>
        <tr class="flex w-full">
          <td class="p-4 w-1/4">User account and order follow up</td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
        </tr>
        <tr class="flex w-full alternate">
          <td class="p-4 w-1/4">
            Last seen products displayed on product detail page
          </td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
        </tr>
        <tr class="flex w-full">
          <td class="p-4 w-1/4">Icons and Fonts Personalization</td>
          <td class="p-4 w-1/4 row"><minus /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
        </tr>
        <tr class="flex w-full alternate">
          <td class="p-4 w-1/4">Analytics</td>
          <td class="p-4 w-1/4 row"><minus /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
        </tr>
        <tr class="flex w-full">
          <td class="p-4 w-1/4">Schedule Notifications</td>
          <td class="p-4 w-1/4 row"><minus /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
        </tr>
        <tr class="flex w-full alternate">
          <td class="p-4 w-1/4">
            Product back in stock Automated Notifications
          </td>
          <td class="p-4 w-1/4 row"><minus /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
        </tr>
        <tr class="flex w-full">
          <td class="p-4 w-1/4">Product in discount Automated Notifications</td>
          <td class="p-4 w-1/4 row"><minus /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
        </tr>
        <tr class="flex w-full alternate">
          <td class="p-4 w-1/4">Video Banner</td>
          <td class="p-4 w-1/4 row"><minus /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
        </tr>
        <tr class="flex w-full ">
          <td class="p-4 w-1/4">Last seen products</td>
          <td class="p-4 w-1/4 row"><minus /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
        </tr>
        <tr class="flex w-full alternate">
          <td class="p-4 w-1/4">Fast add to the cart</td>
          <td class="p-4 w-1/4 row"><minus /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
        </tr>
        <tr class="flex w-full ">
          <td class="p-4 w-1/4">Reviews</td>
          <td class="p-4 w-1/4 row"><minus /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
          <td class="p-4 w-1/4 row"><check-mark /></td>
        </tr>
      </tbody>
    </table>
  </transition>

  <div class="container mx-auto">
    <div class="store-content p-4 border-2 lg:flex-row flex-col m-28">
      <p class="store-text">
        {{ $t("pricing.message_fees") }}
      </p>
      <apple-dev class="mr-4" />
      <p class="mr-9">€99/{{ $t("pricing.message_year") }}</p>
      <google-dev class="mr-4" />
      <p>€25 {{ $t("pricing.message_fee") }}</p>
    </div>
  </div>
  <footer2 />
</template>
<script>
import { defineComponent } from "vue";
import HorizontalDisplay from "@/components/Pricing/HorizontalDisplay.vue";
import NavBar from "@/components/Navbar/Main.vue";
import CheckMark from "@/components/Icons/CheckMark.vue";
import Minus from "@/components/Icons/Minus.vue";
import Plus from "@/components/Icons/Plus.vue";
import AppleDev from "@/components/Icons/AppleDev.vue";
import GoogleDev from "@/components/Icons/GoogleDev.vue";
import StartNowButton from "@/components/StartNowButton.vue";
import Footer2 from "@/components/Footer/Main.vue";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default defineComponent({
  components: {
    HorizontalDisplay,
    NavBar,
    CheckMark,
    Minus,
    Plus,
    AppleDev,
    GoogleDev,
    StartNowButton,
    Footer2,
  },
  mounted() {
    ScrollTrigger.create({
      trigger: "#main-section",
      start: "top 6%",
      end: "bottom 130%",
      onEnter: () => {
        this.fillBG = true;
      },
      onEnterBack: () => {
        this.fillBG = false;
      },
    });
  },
  unmounted() {
    window.scrollTo(0, 0);
  },
  setup() {
    return {};
  },
  data() {
    return {
      tableHidden: false,
      fillBG: false,
    };
  },
});
</script>

<style scoped>
.main-button {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.bg-yellow {
  background-color: #fbbc04;
}
.title-banner {
  font-family: GothamBold;
  text-transform: uppercase;
  color: #263238;
  font-size: 50px;
  line-height: 55px;
}
.desc-banner {
  color: #263238;
  font-family: GothamBook;
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.store-text {
  color: #263238;
  font-family: GothamBook;
  font-size: 18px;
  margin-right: 40px;
}
.store-content > p {
  color: #263238;
  font-family: GothamBook;
  font-size: 18px;
}
.noselect {
  font-family: GothamMedium;
}
.header {
  font-family: GothamBook;
}
td {
  font-family: GothamBook;
}
.title {
  font-family: GothamMedium;
}
.sub-title {
  font-family: GothamMedium;
}
.red {
  background-color: #ff9796;
}
.row {
  display: flex;
  justify-content: center;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
								supported by Chrome and Opera */
}
.alternate {
  background-color: #26323811;
}
.head-table {
  background-color: #263238;
  border-radius: 5px 5px 0px 0px;
}
.store-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
_::-webkit-full-page-media,
_:future,
:root .text-2xl {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
_::-webkit-full-page-media,
_:future,
:root .text-6xl {
  margin-top: 20px;
}
_::-webkit-full-page-media,
_:future,
:root .see-more > p {
  margin-left: 10px;
  margin-top: 2px;
}
.title {
  text-align: center;
}
/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 769px) {
  .items-destak > span {
    text-align: center;
  }
  .title-banner {
    font-size: 40px;
    line-height: 45px;
    text-align: left;
  }
  .desc-banner {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }
  .store-text {
    text-align: center;
  }
  .header {
    padding: 5px !important;
    font-size: 1.1rem;
  }

  .w-11\/12 {
    width: 100% !important;
  }

  .content-1 {
    padding: 0px 20px;
    justify-content: flex-start !important;
    align-items: flex-start;
  }

  .red {
    margin: 0;
  }

  .main-button {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .store-content {
    flex-direction: column !important;
    margin: 10px !important;
    margin-top: 100px !important;
  }
  .store-content > p {
    margin-right: 0px !important;
    font-size: 1rem;
  }
  .store-content > svg {
    margin-right: 0px !important;
  }
  .max-w-md {
    width: 24rem !important;
  }
}
</style>
