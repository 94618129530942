<template>
  <nav-bar :fillBG="true" />
  <div id="processing">
    <div id="page_1">
        <div id="id1_1">
        <p class="p0 ft0">Data Processing Agreement</p>
        <p class="p1 ft1">
            The customer/client agreeing to these terms (“Customer”/”Client”) has
            entered into an agreement with N Apps, LDA. (“N Apps”) under which N
            Apps has agreed to provide services to Customer (as amended from time to
            time, the “Agreement”).
        </p>
        <p class="p2 ft2">WHEREAS</p>
        <p class="p3 ft2">
            <span class="ft2">(A)</span
            ><span class="ft3"
            >The Company (N Apps, LDA) acts as a Data Controller.</span
            >
        </p>
        <p class="p4 ft2">
            <span class="ft4">(B)</span
            ><span class="ft3"
            >The Company wishes to subcontract certain Services, which imply the
            processing of personal data, to the Data Processor.</span
            >
        </p>
        <p class="p5 ft1">
            <span class="ft4">(C)</span
            ><span class="ft5"
            >The Parties seek to implement a data processing agreement that
            complies with the requirements of the current legal framework in
            relation to data processing and with the Regulation (EU) 2016/679 of
            the European Parliament and of the Council of 27 April 2016 on the
            protection of natural persons with regard to the processing of
            personal data and on the free movement of such data, and repealing
            Directive 95/46/EC (General Data Protection Regulation).</span
            >
        </p>
        <p class="p6 ft2">
            <span class="ft2">(D)</span
            ><span class="ft6"
            >The Parties wish to lay down their rights and obligations.</span
            >
        </p>
        <p class="p3 ft2">IT IS AGREED AS FOLLOWS:</p>
        <p class="p7 ft2">1. Definitions and Interpretation</p>
        <p class="p8 ft2">
            Unless otherwise defined herein, capitalized terms and expressions used
            in this Agreement shall have the following meaning:
        </p>
        <p class="p9 ft2">
            <span class="ft2">1.1.</span
            ><span class="ft3"
            >“Addendum Effective Date” means, as applicable, (a) 05 April 2021, if
            the parties agreed to this Addendum prior to or on such date; or (b)
            the date on which the parties agreed to this Addendum, if such date is
            after 05 April 2021.</span
            >
        </p>
        <p class="p10 ft2">
            <span class="ft2">1.1.1</span
            ><span class="ft3"
            >“Agreement” means this Data Processing Agreement and all
            Schedules;</span
            >
        </p>
        <p class="p3 ft2">
            <span class="ft2">1.1.2</span
            ><span class="ft3"
            >“Customer/Client Personal Data” means any Personal Data Processed by
            a Contracted</span
            >
        </p>
        <p class="p11 ft2">
            Processor on behalf of Company pursuant to or in connection with the
            Principal Agreement;
        </p>
        <p class="p7 ft2">
            <span class="ft2">1.1.3</span
            ><span class="ft3">“Contracted Processor” means a Subprocessor;</span>
        </p>
        <p class="p12 ft2">
            <span class="ft2">1.1.4</span
            ><span class="ft3"
            >“Data Protection Laws” means EU Data Protection Laws and, to the
            extent applicable, the data protection or privacy laws of any other
            country;</span
            >
        </p>
        <p class="p13 ft2">
            <span class="ft2">1.1.5</span
            ><span class="ft3">“EEA” means the European Economic Area;</span>
        </p>
        <p class="p14 ft2">
            <span class="ft2">1.1.6</span
            ><span class="ft3"
            >“EU Data Protection Laws” means EU Directive 95/46/EC, as transposed
            into domestic legislation of each Member State and as amended,
            replaced or superseded from time to time, including by the GDPR and
            laws implementing or supplementing the GDPR;</span
            >
        </p>
        </div>
    </div>
    <div id="page_2">
        <div id="id2_1">
        <p class="p15 ft2">
            <span class="ft2">1.1.7</span
            ><span class="ft3"
            >“GDPR” means EU General Data Protection Regulation 2016/679;</span
            >
        </p>
        <p class="p3 ft2">
            <span class="ft2">1.1.8</span
            ><span class="ft3">“Data Transfer” means:</span>
        </p>
        <p class="p16 ft2">
            <span class="ft2">1.1.8.1</span
            ><span class="ft6"
            >a transfer of Customer/Client Personal Data from the Company to a
            Contracted Processor; or</span
            >
        </p>
        <p class="p17 ft1">
            <span class="ft2">1.1.8.2</span
            ><span class="ft8"
            >an onward transfer of Customer/Client Personal Data from a Contracted
            Processor to a Subcontracted Processor, or between two establishments
            of a Contracted Processor, in each case, where such transfer would be
            prohibited by Data Protection Laws (or by the terms of data transfer
            agreements put in place to address the data transfer restrictions of
            Data Protection Laws);</span
            >
        </p>
        <p class="p18 ft2">
            1.1.9 “Services” means the services and/or products to be provided by N
            Apps to Customer under the Agreement.
        </p>
        <p class="p2 ft2">
            <span class="ft2">1.1.10</span
            ><span class="ft6"
            >“Subprocessor” means any person appointed by or on behalf of
            Processor to process</span
            >
        </p>
        <p class="p19 ft2">
            Personal Data on behalf of the Company in connection with the Agreement.
        </p>
        <p class="p20 ft2">
            <span class="ft2">1.2</span
            ><span class="ft3"
            >The terms, “Commission”, “Controller”, “Data Subject”, “Member
            State”, “Personal Data”, “Personal Data Breach”, “Processing” and
            “Supervisory Authority” shall have the same meaning as in the GDPR,
            and their cognate terms shall be construed accordingly.</span
            >
        </p>
        <p class="p10 ft2">
            <span class="ft2">2.</span
            ><span class="ft3">Processing of Customer/Client Personal Data</span>
        </p>
        <p class="p3 ft2">2.1 Processor shall:</p>
        <p class="p21 ft2">
            <span class="ft2">2.1.1</span
            ><span class="ft3"
            >comply with all applicable Data Protection Laws in the Processing of
            Customer/Client Personal Data; and</span
            >
        </p>
        <p class="p22 ft2">
            <span class="ft2">2.1.2</span
            ><span class="ft3"
            >not Process Customer/Client Personal Data other than on the relevant
            Company’s documented instructions.</span
            >
        </p>
        <p class="p13 ft2">
            2.2 The Company instructs Processor to process Customer/Client Personal
            Data.
        </p>
        <p class="p3 ft2">3. Processor Personnel</p>
        <p class="p23 ft2">
            Processor shall take reasonable steps to ensure the reliability of any
            employee, agent or contractor of any Contracted Processor who may have
            access to the Customer/Client Personal Data, ensuring in each case that
            access is strictly limited to those individuals who need to know
        </p>
        <p class="p24 ft1">
            <span class="ft2">/</span
            ><span class="ft5"
            >access the relevant Customer/Client Personal Data, as strictly
            necessary for the purposes of the Principal Agreement, and to comply
            with Applicable Laws in the context of that individual’s duties to the
            Contracted Processor, ensuring that all such individuals are subject
            to confidentiality undertakings or professional or statutory
            obligations of confidentiality.</span
            >
        </p>
        <p class="p13 ft2">4. Security</p>
        </div>
    </div>
    <div id="page_3">
        <div id="id3_1">
        <p class="p25 ft10">
            <span class="ft2">4.1</span
            ><span class="ft9"
            >Taking into account the state of the art, the costs of implementation
            and the nature, scope, context and purposes of Processing as well as
            the risk of varying likelihood and severity for the rights and
            freedoms of natural persons, Processor shall in relation to the
            Customer/Client Personal Data implement appropriate technical and
            organizational measures to ensure a level of security appropriate to
            that risk, including, as appropriate, the measures referred to in
            Article 32(1) of the GDPR.</span
            >
        </p>
        <p class="p26 ft2">
            <span class="ft2">4.2</span
            ><span class="ft3"
            >In assessing the appropriate level of security, Processor shall take
            account in particular of the risks that are presented by Processing,
            in particular from a Personal Data Breach.</span
            >
        </p>
        <p class="p13 ft2">5. Subprocessing</p>
        <p class="p27 ft2">
            <span class="ft2">5.1</span
            ><span class="ft3"
            >Processor shall not appoint (or disclose any Customer/Client Personal
            Data to) any Subprocessor unless required or authorized by the
            Company.</span
            >
        </p>
        <p class="p13 ft2">6. Data Subject Rights</p>
        <p class="p28 ft1">
            <span class="ft2">6.1</span
            ><span class="ft5"
            >Taking into account the nature of the Processing, Processor shall
            assist the Company by implementing appropriate technical and
            organisational measures, insofar as this is possible, for the
            fulfilment of the Company obligations, as reasonably understood by
            Company, to respond to requests to exercise Data Subject rights under
            the Data Protection Laws.</span
            >
        </p>
        <p class="p13 ft2">
            <span class="ft2">6.2</span><span class="ft3">Processor shall:</span>
        </p>
        <p class="p29 ft2">
            <span class="ft2">6.2.1</span
            ><span class="ft3"
            >promptly notify Company if it receives a request from a Data Subject
            under any Data Protection Law in respect of Customer/Client Personal
            Data; and</span
            >
        </p>
        <p class="p30 ft1">
            <span class="ft2">6.2.2</span
            ><span class="ft5"
            >ensure that it does not respond to that request except on the
            documented instructions of Company or as required by Applicable Laws
            to which the Processor is subject, in which case Processor shall to
            the extent permitted by Applicable Laws inform Company of that legal
            requirement before the Contracted Processor responds to the
            request.</span
            >
        </p>
        <p class="p13 ft2">7. Personal Data Breach</p>
        <p class="p14 ft1">
            <span class="ft2">7.1</span
            ><span class="ft5"
            >Processor shall notify Company without undue delay upon Processor
            becoming aware of a Personal Data Breach affecting Customer/Client
            Personal Data, providing Company with sufficient information to allow
            the Company to meet any obligations to report or inform Data Subjects
            of the Personal Data Breach under the Data Protection Laws.</span
            >
        </p>
        <p class="p31 ft2">
            <span class="ft2">7.2</span><span class="ft3">Processor shall </span
            ><NOBR>co-operate</NOBR> with the Company and take reasonable commercial
            steps as are directed by Company to assist in the investigation,
            mitigation and remediation of each such Personal Data Breach.
        </p>
        <p class="p32 ft2">
            <span class="ft2">8.</span
            ><span class="ft3"
            >Data Protection Impact Assessment and Prior Consultation Processor
            shall provide reasonable assistance to the Company with any data
            protection impact assessments, and prior consultations with
            Supervising Authorities or other competent data privacy authorities,
            which</span
            >
        </p>
        </div>
    </div>
    <div id="page_4">
        <div id="id4_1">
        <p class="p33 ft1">
            Company reasonably considers to be required by article 35 or 36 of the
            GDPR or equivalent provisions of any other Data Protection Law, in each
            case solely in relation to Processing of Customer/Client Personal Data
            by, and taking into account the nature of the Processing and information
            available to, the Contracted Processors.
        </p>
        <p class="p13 ft2">
            9. Deletion or return of Customer/Client Personal Data
        </p>
        <p class="p3 ft2">
            9.1 Subject to this section 9 Processor shall promptly and in any event
            within
        </p>
        <p class="p34 ft2">
            10 business days of the date of cessation of any Services involving the
            Processing of Customer/Client Personal Data (the “Cessation Date”),
            delete and procure the deletion of all copies of those Customer/Client
            Personal Data.
        </p>
        <p class="p10 ft2">10. Audit rights</p>
        <p class="p35 ft1">
            <span class="ft2">10.1</span
            ><span class="ft5"
            >Subject to this section 10, Processor shall make available to the
            Company on request all information necessary to demonstrate compliance
            with this Agreement, and shall allow for and contribute to audits,
            including inspections, by the Company or an auditor mandated by the
            Company in relation to the Processing of the Customer/Client Personal
            Data by the Contracted Processors.</span
            >
        </p>
        <p class="p36 ft1">
            <span class="ft2">10.2</span
            ><span class="ft5"
            >Information and audit rights of the Company only arise under section
            10.1 to the extent that the Agreement does not otherwise give them
            information and audit rights meeting the relevant requirements of Data
            Protection Law.</span
            >
        </p>
        <p class="p2 ft2">11. Data Transfer</p>
        <p class="p37 ft10">
            <span class="ft2">11.1</span
            ><span class="ft9"
            >The Processor may not transfer or authorize the transfer of Data to
            countries outside the EU and/or the European Economic Area (EEA)
            without the prior written consent of the Company. If personal data
            processed under this Agreement is transferred from a country within
            the European Economic Area to a country outside the European Economic
            Area, the Parties shall ensure that the personal data are adequately
            protected. To achieve this, the Parties shall, unless agreed
            otherwise, rely on EU approved standard contractual clauses for the
            transfer of personal data.</span
            >
        </p>
        <p class="p38 ft2">12. General Terms</p>
        <p class="p39 ft1">
            <span class="ft2">12.1</span
            ><span class="ft5"
            >Confidentiality. Each Party must keep this Agreement and information
            it receives about the other Party and its business in connection with
            this Agreement (“Confidential Information”) confidential and must not
            use or disclose that Confidential Information without the prior
            written consent of the other Party except to the extent that:</span
            >
        </p>
        <p class="p40 ft2">(a) disclosure is required by law;</p>
        <p class="p11 ft2">
            (b) the relevant information is already in the public domain.
        </p>
        <p class="p41 ft2">
            <span class="ft2">12.2</span
            ><span class="ft3"
            >Notices. All notices and communications given under this Agreement
            must be in writing and will be delivered personally, sent by post or
            sent by email to the address or email address</span
            >
        </p>
        </div>
    </div>
    <div id="page_5">
        <div id="id5_1">
        <p class="p42 ft2">
            set out in the heading of this Agreement at such other address as
            notified from time to time by the Parties changing address.
        </p>
        <p class="p13 ft2">13. Governing Law and Jurisdiction</p>
        <p class="p3 ft2">
            <span class="ft2">13.1</span
            ><span class="ft3"
            >This Agreement is governed by the laws of Portugal.</span
            >
        </p>
        <p class="p43 ft2">
            <span class="ft2">13.2</span
            ><span class="ft3"
            >Any dispute arising in connection with this Agreement, which the
            Parties will not be able to resolve amicably, will be submitted to the
            exclusive jurisdiction of the courts of arbitral tribunals.</span
            >
        </p>
        </div>
    </div>
</div>
  <footer2 />
</template>
<script>
import { defineComponent } from "vue";
import NavBar from "@/components/Navbar/Main.vue";
import Footer2 from "@/components/Footer/Main.vue";

export default defineComponent({
  components: {
    NavBar,
    Footer2,
  },
  data: function () {
    return {};
  },
  unmounted() {
    window.scrollTo(0, 0);
  },
  mounted() {},
  methods: {},
  computed: {},
  setup() {
    return {};
  },
});
</script>

<style scoped>
body {margin-top: 0px;margin-left: 0px;}

#processing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#page_1 {position:relative; overflow: hidden;margin: 100px 0px 12px 96px;padding: 0px;border: none;width: 720px;}
#page_1 #id1_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 720px;overflow: hidden;}
#page_1 #id1_2 {border:none;margin: 92px 0px 0px 176px;padding: 0px;border:none;width: 544px;overflow: hidden;}





#page_2 {position:relative; overflow: hidden;margin: 99px 0px 12px 96px;padding: 0px;border: none;width: 720px;}
#page_2 #id2_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 720px;overflow: hidden;}
#page_2 #id2_2 {border:none;margin: 124px 0px 0px 176px;padding: 0px;border:none;width: 544px;overflow: hidden;}





#page_3 {position:relative; overflow: hidden;margin: 99px 0px 12px 96px;padding: 0px;border: none;width: 720px;}
#page_3 #id3_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 720px;overflow: hidden;}
#page_3 #id3_2 {border:none;margin: 96px 0px 0px 176px;padding: 0px;border:none;width: 544px;overflow: hidden;}





#page_4 {position:relative; overflow: hidden;margin: 99px 0px 12px 96px;padding: 0px;border: none;width: 720px;}
#page_4 #id4_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 720px;overflow: hidden;}
#page_4 #id4_2 {border:none;margin: 84px 0px 0px 176px;padding: 0px;border:none;width: 544px;overflow: hidden;}





#page_5 {position:relative; overflow: hidden;margin: 99px 0px 12px 96px;padding: 0px;border: none;width: 720px;}
#page_5 #id5_1 {border:none;margin: 0px 0px 0px 0px;padding: 0px;border:none;width: 720px;overflow: hidden;}
#page_5 #id5_2 {border:none;margin: 761px 0px 0px 176px;padding: 0px;border:none;width: 544px;overflow: hidden;}





.ft0{font: bold 19px 'GothamBook';line-height: 23px;}
.ft1{font: 16px 'GothamBook';line-height: 20px;}
.ft2{font: 16px 'GothamBook';line-height: 19px;}
.ft3{font: 16px 'GothamBook';margin-left: 4px;line-height: 19px;}
.ft4{font: 15px 'GothamBook';line-height: 18px;}
.ft5{font: 16px 'GothamBook';margin-left: 4px;line-height: 20px;}
.ft6{font: 16px 'GothamBook';margin-left: 3px;line-height: 19px;}
.ft7{font: 8px 'GothamBook';line-height: 10px;}
.ft8{font: 16px 'GothamBook';margin-left: 3px;line-height: 20px;}
.ft9{font: 16px 'GothamBook';margin-left: 4px;line-height: 21px;}
.ft10{font: 16px 'GothamBook';line-height: 21px;}

.p0{text-align: left;padding-left: 205px;margin-top: 0px;margin-bottom: 0px;}
.p1{text-align: left;padding-right: 103px;margin-top: 11px;margin-bottom: 0px;}
.p2{text-align: left;margin-top: 14px;margin-bottom: 0px;}
.p3{text-align: left;margin-top: 13px;margin-bottom: 0px;}
.p4{text-align: left;padding-right: 97px;margin-top: 13px;margin-bottom: 0px;}
.p5{text-align: left;padding-right: 96px;margin-top: 15px;margin-bottom: 0px;}
.p6{text-align: left;margin-top: 37px;margin-bottom: 0px;}
.p7{text-align: left;margin-top: 12px;margin-bottom: 0px;}
.p8{text-align: left;padding-right: 129px;margin-top: 13px;margin-bottom: 0px;}
.p9{text-align: left;padding-right: 103px;margin-top: 15px;margin-bottom: 0px;}
.p10{text-align: left;margin-top: 17px;margin-bottom: 0px;}
.p11{text-align: left;margin-top: 2px;margin-bottom: 0px;}
.p12{text-align: left;padding-right: 103px;margin-top: 13px;margin-bottom: 0px;}
.p13{text-align: left;margin-top: 15px;margin-bottom: 0px;}
.p14{text-align: left;padding-right: 110px;margin-top: 13px;margin-bottom: 0px;}
.p15{text-align: left;margin-top: 0px;margin-bottom: 0px;}
.p16{text-align: left;padding-right: 157px;margin-top: 13px;margin-bottom: 0px;}
.p17{text-align: left;padding-right: 99px;margin-top: 15px;margin-bottom: 0px;}
.p18{text-align: left;padding-right: 128px;margin-top: 16px;margin-bottom: 0px;}
.p19{text-align: left;margin-top: 3px;margin-bottom: 0px;}
.p20{text-align: left;padding-right: 99px;margin-top: 12px;margin-bottom: 0px;}
.p21{text-align: left;padding-right: 132px;margin-top: 13px;margin-bottom: 0px;}
.p22{text-align: left;padding-right: 153px;margin-top: 14px;margin-bottom: 0px;}
.p23{text-align: left;padding-right: 97px;margin-top: 2px;margin-bottom: 0px;}
.p24{text-align: left;padding-right: 104px;margin-top: 6px;margin-bottom: 0px;}
.p25{text-align: left;padding-right: 102px;margin-top: 0px;margin-bottom: 0px;}
.p26{text-align: left;padding-right: 117px;margin-top: 11px;margin-bottom: 0px;}
.p27{text-align: left;padding-right: 161px;margin-top: 13px;margin-bottom: 0px;}
.p28{text-align: left;padding-right: 102px;margin-top: 13px;margin-bottom: 0px;}
.p29{text-align: left;padding-right: 135px;margin-top: 12px;margin-bottom: 0px;}
.p30{text-align: left;padding-right: 101px;margin-top: 15px;margin-bottom: 0px;}
.p31{text-align: left;padding-right: 106px;margin-top: 15px;margin-bottom: 0px;}
.p32{text-align: left;padding-right: 101px;margin-top: 17px;margin-bottom: 0px;}
.p33{text-align: justify;padding-right: 124px;margin-top: 0px;margin-bottom: 0px;}
.p34{text-align: left;padding-right: 120px;margin-top: 13px;margin-bottom: 0px;}
.p35{text-align: left;padding-right: 101px;margin-top: 12px;margin-bottom: 0px;}
.p36{text-align: left;padding-right: 118px;margin-top: 16px;margin-bottom: 0px;}
.p37{text-align: left;padding-right: 98px;margin-top: 13px;margin-bottom: 0px;}
.p38{text-align: left;margin-top: 11px;margin-bottom: 0px;}
.p39{text-align: left;padding-right: 100px;margin-top: 13px;margin-bottom: 0px;}
.p40{text-align: left;margin-top: 4px;margin-bottom: 0px;}
.p41{text-align: left;padding-right: 106px;margin-top: 13px;margin-bottom: 0px;}
.p42{text-align: left;padding-right: 103px;margin-top: 0px;margin-bottom: 0px;}
.p43{text-align: left;padding-right: 114px;margin-top: 13px;margin-bottom: 0px;}

</style>