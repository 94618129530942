<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M31.3333 30.6667H0.666687C0.298687 30.6667 0 30.9654 0 31.3334C0 31.7014 0.298687 32.0001 0.666687 32.0001H31.3334C31.7014 32.0001 32.0001 31.7014 32.0001 31.3334C32 30.9654 31.7013 30.6667 31.3333 30.6667Z"
        fill="#263238"
      />
      <path
        d="M5.99994 22.6667H1.99994C1.63194 22.6667 1.33325 22.9654 1.33325 23.3334V31.3334C1.33325 31.7014 1.63194 32.0001 1.99994 32.0001H5.99994C6.36794 32.0001 6.66663 31.7014 6.66663 31.3334V23.3334C6.66663 22.9654 6.36794 22.6667 5.99994 22.6667ZM5.33325 30.6667H2.66663V24.0001H5.33331V30.6667H5.33325Z"
        fill="#263238"
      />
      <path
        d="M13.9999 16H9.99994C9.63194 16 9.33325 16.2987 9.33325 16.6667V31.3334C9.33325 31.7014 9.63194 32.0001 9.99994 32.0001H13.9999C14.3679 32.0001 14.6666 31.7014 14.6666 31.3334V16.6667C14.6666 16.2987 14.3679 16 13.9999 16ZM13.3333 30.6667H10.6666V17.3333H13.3333V30.6667Z"
        fill="#263238"
      />
      <path
        d="M21.9999 18.6667H17.9999C17.6319 18.6667 17.3333 18.9654 17.3333 19.3334V31.3334C17.3333 31.7014 17.6319 32.0001 17.9999 32.0001H21.9999C22.3679 32.0001 22.6666 31.7014 22.6666 31.3334V19.3334C22.6666 18.9654 22.3679 18.6667 21.9999 18.6667ZM21.3333 30.6668H18.6666V20.0001H21.3333V30.6668Z"
        fill="#263238"
      />
      <path
        d="M29.9999 10.6667H25.9999C25.6319 10.6667 25.3333 10.9654 25.3333 11.3334V31.3334C25.3333 31.7014 25.6319 32.0001 25.9999 32.0001H29.9999C30.3679 32.0001 30.6666 31.7014 30.6666 31.3334V11.3334C30.6666 10.9654 30.3679 10.6667 29.9999 10.6667ZM29.3333 30.6667H26.6666V12.0001H29.3333V30.6667Z"
        fill="#263238"
      />
      <path
        d="M3.99994 12C2.52925 12 1.33325 13.196 1.33325 14.6667C1.33325 16.1374 2.52925 17.3334 3.99994 17.3334C5.47063 17.3334 6.66663 16.1374 6.66663 14.6667C6.66663 13.196 5.47063 12 3.99994 12ZM3.99994 16C3.26394 16 2.66663 15.4013 2.66663 14.6667C2.66663 13.932 3.26394 13.3334 3.99994 13.3334C4.73594 13.3334 5.33325 13.9321 5.33325 14.6667C5.33325 15.4013 4.73594 16 3.99994 16Z"
        fill="#263238"
      />
      <path
        d="M11.9999 5.33325C10.5293 5.33325 9.33325 6.52925 9.33325 7.99994C9.33325 9.47062 10.5293 10.6666 11.9999 10.6666C13.4706 10.6666 14.6666 9.47062 14.6666 7.99994C14.6666 6.52925 13.4706 5.33325 11.9999 5.33325ZM11.9999 9.33325C11.2639 9.33325 10.6666 8.73456 10.6666 7.99994C10.6666 7.26525 11.2639 6.66662 11.9999 6.66662C12.7359 6.66662 13.3333 7.26531 13.3333 7.99994C13.3333 8.73462 12.7359 9.33325 11.9999 9.33325Z"
        fill="#263238"
      />
      <path
        d="M19.9999 8C18.5293 8 17.3333 9.196 17.3333 10.6667C17.3333 12.1374 18.5293 13.3334 19.9999 13.3334C21.4706 13.3334 22.6666 12.1374 22.6666 10.6667C22.6666 9.196 21.4706 8 19.9999 8ZM19.9999 12C19.2639 12 18.6666 11.4013 18.6666 10.6667C18.6666 9.932 19.2639 9.33337 19.9999 9.33337C20.7359 9.33337 21.3333 9.93206 21.3333 10.6667C21.3333 11.4013 20.7359 12 19.9999 12Z"
        fill="#263238"
      />
      <path
        d="M27.9999 0C26.5293 0 25.3333 1.196 25.3333 2.66669C25.3333 4.13737 26.5293 5.33337 27.9999 5.33337C29.4706 5.33337 30.6666 4.13737 30.6666 2.66669C30.6666 1.196 29.4706 0 27.9999 0ZM27.9999 4C27.2639 4 26.6666 3.40131 26.6666 2.66669C26.6666 1.932 27.2639 1.33337 27.9999 1.33337C28.7359 1.33337 29.3333 1.93206 29.3333 2.66669C29.3333 3.40131 28.7359 4 27.9999 4Z"
        fill="#263238"
      />
      <path
        d="M27.0588 3.60809C26.7988 3.34809 26.3761 3.34809 26.1161 3.60809L20.9428 8.7814C20.6828 9.0414 20.6828 9.46409 20.9428 9.72409C21.0735 9.8534 21.2441 9.91877 21.4148 9.91877C21.5841 9.91877 21.7548 9.85477 21.8855 9.72409L27.0588 4.55077C27.3188 4.29077 27.3188 3.86809 27.0588 3.60809Z"
        fill="#263238"
      />
      <path
        d="M18.3878 9.12802L14.1051 7.9067C13.7465 7.8027 13.3825 8.0107 13.2825 8.36539C13.1812 8.72008 13.3865 9.08808 13.7398 9.18939L18.0225 10.4107C18.0838 10.428 18.1451 10.436 18.2051 10.436C18.4958 10.436 18.7625 10.2453 18.8465 9.95202C18.9478 9.5987 18.7425 9.22933 18.3878 9.12802Z"
        fill="#263238"
      />
      <path
        d="M10.9602 8.83069C10.7309 8.54269 10.3122 8.49601 10.0242 8.72401L4.99749 12.732C4.70949 12.9627 4.66149 13.3813 4.89218 13.6693C5.02418 13.8346 5.21887 13.92 5.41349 13.92C5.5588 13.92 5.70549 13.872 5.82818 13.776L10.8549 9.76801C11.1429 9.53732 11.1909 9.11869 10.9602 8.83069Z"
        fill="#263238"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "AnalyticsIcon",
  data() {
    return {};
  },
  props: {
    fill: {
      default: "--color-primary",
    },
  },
};
</script>