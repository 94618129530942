<template>
  <div>
      <nav-bar :fillBG="fillBG" />
      <div class="flex           2xl:w-3/4
          xl:w-3/4
          w-11/12
          justify-center
          m-auto container
          box">
        <div class="col-1">
          <h1 class="not-found" >404</h1>
          <h1 class="oops" >Oops</h1>
          </div>
           <div class="col-2">
            <h4 class="subtitle" >{{$t("notfound.subtitle")}}</h4>
            <h3 class="title" >{{$t("notfound.title")}}</h3> 
           </div>
      </div>
      <footer-2 />
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import NavBar from "@/components/Navbar/Main.vue";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Logo from "@/components/Icons/Logo.vue";
import LogoWhite from "../components/Icons/LogoWhite.vue";
import Footer2 from "@/components/Footer/Main.vue";

export default defineComponent({
  components: {
    Footer2,
    NavBar,
    Logo,
    LogoWhite,
    ScrollTrigger,
  },
  data() {
    return {
      tl: null,
      animated: false,
      fillBG: false,
    };
  },
  methods: {},
  unmounted() {
    window.scrollTo(0, 0);
    ScrollTrigger.getAll().forEach((element) => {
      element.kill();
    });
  },
  mounted() {
    ScrollTrigger.create({
      trigger: ".header-about",
      start: "top -10%",
      end: "bottom 150%",
      onEnter: () => {
        this.fillBG = true;
      },
      onEnterBack: () => {
        this.fillBG = false;
      },
    });
  },
});
</script>

<style scoped>

.not-found {
  color: #FBBC04;
  font-family: GothamBold;
  font-size: 220px;
}

.oops {
    font-size: 90px;
    color: #263238;
    font-family: GothamBold;
    position: absolute;
    top: 200px;
}

.col-1 {
  flex: 1;
}

.col-2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: 50px;
  font-family: GothamMedium;
  color: #263238;
}

.subtitle {
  font-size: 30px;
  font-family: GothamMedium;
  color: #263238;
}

@media only screen and (max-width: 1024px) {
  .box {
    flex-direction: column;
  }
}

</style>
