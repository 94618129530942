<template>
<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.112 32.8125H21.2942C19.8056 32.8125 18.5938 31.6006 18.5938 30.112V19.1067C18.5938 17.6181 19.8056 16.4062 21.2942 16.4062H30.112C31.6006 16.4062 32.8125 17.6181 32.8125 19.1067V30.112C32.8125 31.6006 31.6006 32.8125 30.112 32.8125ZM21.2942 18.5938C21.0109 18.5938 20.7812 18.8234 20.7812 19.1067V30.112C20.7812 30.3953 21.0109 30.625 21.2942 30.625H30.112C30.3953 30.625 30.625 30.3953 30.625 30.112V19.1067C30.625 18.8234 30.3953 18.5938 30.112 18.5938H21.2942Z" fill="#263238"/>
<path d="M30.112 14.2188H21.2942C19.8056 14.2188 18.5938 13.0069 18.5938 11.5183V4.88797C18.5938 3.39937 19.8056 2.1875 21.2942 2.1875H30.112C31.6006 2.1875 32.8125 3.39937 32.8125 4.88797V11.5183C32.8125 13.0069 31.6006 14.2188 30.112 14.2188ZM21.2942 4.375C21.0109 4.375 20.7812 4.60469 20.7812 4.88797V11.5183C20.7812 11.8016 21.0109 12.0312 21.2942 12.0312H30.112C30.3953 12.0312 30.625 11.8016 30.625 11.5183V4.88797C30.625 4.60469 30.3953 4.375 30.112 4.375H21.2942Z" fill="#263238"/>
<path d="M13.7058 18.5938H4.88797C3.39937 18.5938 2.1875 17.3819 2.1875 15.8933V4.88797C2.1875 3.39937 3.39937 2.1875 4.88797 2.1875H13.7058C15.1944 2.1875 16.4062 3.39937 16.4062 4.88797V15.8933C16.4062 17.3819 15.1944 18.5938 13.7058 18.5938ZM4.88797 4.375C4.60469 4.375 4.375 4.60469 4.375 4.88797V15.8933C4.375 16.1766 4.60469 16.4062 4.88797 16.4062H13.7058C13.9891 16.4062 14.2188 16.1766 14.2188 15.8933V4.88797C14.2188 4.60469 13.9891 4.375 13.7058 4.375H4.88797Z" fill="#263238"/>
<path d="M13.7058 32.8125H4.88797C3.39937 32.8125 2.1875 31.6006 2.1875 30.112V23.4817C2.1875 21.9931 3.39937 20.7812 4.88797 20.7812H13.7058C15.1944 20.7812 16.4062 21.9931 16.4062 23.4817V30.112C16.4062 31.6006 15.1944 32.8125 13.7058 32.8125ZM4.88797 22.9688C4.60469 22.9688 4.375 23.1984 4.375 23.4817V30.112C4.375 30.3953 4.60469 30.625 4.88797 30.625H13.7058C13.9891 30.625 14.2188 30.3953 14.2188 30.112V23.4817C14.2188 23.1984 13.9891 22.9688 13.7058 22.9688H4.88797Z" fill="#263238"/>
</svg>


</template>
<script>
export default {
    name: 'DesignIcon',
    data() {
        return {}
    },
    props: {
        fill: {
            default: '--color-primary'
        }
    },
}
</script>