<template>
    Redirecting...
</template>
<script>
import { defineComponent, watch, computed, onMounted } from 'vue'
import { useStore } from '@/store'
import { useRouter } from 'vue-router'

export default defineComponent({
    setup() {
        const router = useRouter()
        const store = useStore()
        const isLoggedIn = computed(() => store.getters['auth/isLoggedIn'])
        const shop = computed(() => store.getters['auth/shop'])

        const redirect = () => {
            if (!isLoggedIn.value) {
                router.push('/login?dashboard=1')
            } else if (shop.value.id) {
                store.dispatch('auth/openDashboard', {
                    href: 'https://' + shop.value.id + '.napps-solutions.com'
                })
            }
        }

        watch([isLoggedIn, shop], (newValues, oldValues) => {
            redirect()
        })

        onMounted(() => {
            redirect()
        })
        return {}
  }
})
</script>
