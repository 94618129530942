<template>
  <nav-bar :fillBG="true"  />
  <div id="cookie">
    <div id="page_1">
      <div id="id1_1">
        <p class="p0 ft0">Cookie Policy</p>
        <p class="p1 ft1">
          N Apps, LDA (“N Apps”, “We”, “Us”, “Website”, “Our”) has created this
          Cookie policy. Cookies are simply tiny text files that are stored on
          your computer or mobile device by a website’s server. Each cookie is
          unique to your web browser. It will contain some anonymous information
          such as a unique identifier, the website’s domain name, and some
          digits and numbers. In this policy, all of these technologies will be
          called “Cookies”.
        </p>
        <p class="p2 ft1">
          N Apps uses Cookies for every product of its website. If you are a
          user of the N Apps website we may use Cookies after having your
          consent.
        </p>
        <p class="p3 ft2">The Reason We Use Cookies</p>
        <p class="p4 ft1">
          <span class="ft3">•</span
          ><span class="ft4">To verify your user account;</span>
        </p>
        <p class="p5 ft1">
          <span class="ft3">•</span
          ><span class="ft4">To determine the time you are logged in;</span>
        </p>
        <p class="p6 ft1">
          <span class="ft3">•</span
          ><span class="ft4">To secure your user account;</span>
        </p>
        <p class="p5 ft1">
          <span class="ft3">•</span
          ><span class="ft4"
            >To determine and fight against spam, malware, etc.</span
          >
        </p>
        <p class="p6 ft1">
          <span class="ft3">•</span
          ><span class="ft4">To determine users interest in showing ads;</span>
        </p>
        <p class="p6 ft1">
          <span class="ft3">•</span
          ><span class="ft4">To have the fullest experience possible;</span>
        </p>
        <p class="p5 ft1">
          <span class="ft3">•</span
          ><span class="ft4"
            >To Develop and protect new products, services, features, and
            functionality</span
          >
        </p>
        <p class="p7 ft5">Types of Cookies We Use</p>
        <p class="p8 ft5">
          <span class="ft6">o</span><span class="ft7">Necessary cookies</span>
        </p>
        <p class="p2 ft1">
          Necessary cookies allow us to offer you the best possible experience
          when accessing and navigating through our website and using its
          features. For example, these cookies let us recognize that you have
          created an account and have logged into that account.
        </p>
        <p class="p9 ft5">
          <span class="ft6">o</span
          ><span class="ft7">Functionality cookies</span>
        </p>
        <p class="p10 ft1">
          Functionality cookies let us operate the site by the choices you make.
          For example, we will recognize your username and remember how you
          customized the site during future visits.
        </p>
        <p class="p11 ft5">
          <span class="ft6">o</span><span class="ft7">Analytical cookies</span>
        </p>
        <p class="p2 ft1">
          These cookies enable us and <NOBR>third-party</NOBR> services to
          collect aggregated data for statistical purposes on how our visitors
          use the website. These cookies do not contain personal information
          such as names and email addresses and are used to help us improve your
          user experience of the website.
        </p>
        <p class="p12 ft5">Your Rights</p>
        <p class="p9 ft5">
          a. Enabling and disabling cookies and removing cookies
        </p>
        <p class="p13 ft9">
          If you want to enable, restrict or block the cookies that are set by
          our website, you can do so through your browser setting.
          Alternatively, you can visit
          <A href="http://www.internetcookies.org/"
            ><span class="ft8">www.internetcookies.org</span></A
          ><A href="http://www.internetcookies.org/">, </A>which contains
          comprehensive information on how to do this on a wide variety of
          browsers and devices.
        </p>
      </div>
    </div>
    <div id="page_2">
      <div id="id2_1">
        <p class="p15 ft1">
          You will find general information about cookies and details on how to
          delete cookies from your device.
        </p>
        <p class="p16 ft5">b. Right to access, correct, or delete your data</p>
        <p class="p17 ft1">
          You have the right to request access to and correction or deletion of
          your data (see our privacy policy for more information). To prevent
          abuse, we can ask you to identify yourself adequately with such a
          request.
        </p>
        <p class="p18 ft5">
          <span class="ft1">c.</span
          ><span class="ft11">Tips, questions and complaints</span>
        </p>
        <p class="p17 ft1">
          For more information about our privacy policy and the processing of
          your personal data, please refer to our privacy policy. If you have
          any questions or complaints regarding our cookie policy, we are happy
          to help.
        </p>
        <p class="p19 ft5">Contact Information</p>
        <p class="p20 ft1">
          If you have any questions about the Cookie Policy, please contact us
          at support@napps- solutions.com
        </p>
      </div>
    </div>
  </div>
  <footer2  />
</template>
<script>
import { defineComponent } from "vue";
import NavBar from "@/components/Navbar/Main.vue";
import Footer2 from "@/components/Footer/Main.vue";

export default defineComponent({
  components: {
    NavBar,
    Footer2,
  },
  data: function () {
    return {};
  },
  unmounted() {
    window.scrollTo(0, 0);
  },
  mounted() {},
  methods: {},
  computed: {},
  setup() {
    return {};
  },
});
</script>

<style scoped>

#cookie {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

body {
  margin-top: 0px;
  margin-left: 0px;
  font-family: GothamBook !important;
}

#page_1 {
  position: relative;
  overflow: hidden;
  margin: 100px 0px 12px 96px;
  padding: 0px;
  border: none;
  width: 720px;
}
#page_1 #id1_1 {
  border: none;
  margin: 0px 0px 0px 0px;
  padding: 0px;
  border: none;
  width: 720px;
  overflow: hidden;
}
#page_1 #id1_2 {
  border: none;
  margin: 106px 0px 0px 176px;
  padding: 0px;
  border: none;
  width: 544px;
  overflow: hidden;
}

#page_2 {
  position: relative;
  overflow: hidden;
  margin: 99px 0px 12px 96px;
  padding: 0px;
  border: none;
  width: 720px;
}
#page_2 #id2_1 {
  border: none;
  margin: 0px 0px 0px 0px;
  padding: 0px;
  border: none;
  width: 720px;
  overflow: hidden;
}
#page_2 #id2_2 {
  border: none;
  margin: 623px 0px 0px 176px;
  padding: 0px;
  border: none;
  width: 544px;
  overflow: hidden;
}

.ft0 {
  font: bold 21px "GothamBook";
  line-height: 26px;
}
.ft1 {
  font: 16px "GothamBook";
  line-height: 19px;
}
.ft2 {
  font: bold 16px "GothamBook";
  line-height: 16px;
}
.ft3 {
  font: 16px "GothamBook";
  line-height: 19px;
}
.ft4 {
  font: 16px "GothamBook";
  margin-left: 17px;
  line-height: 19px;
}
.ft5 {
  font: bold 16px "GothamBook";
  line-height: 19px;
}
.ft6 {
  font: 16px "GothamBook";
  line-height: 18px;
}
.ft7 {
  font: bold 16px "GothamBook";
  margin-left: 14px;
  line-height: 19px;
}
.ft8 {
  font: 15px "GothamBook";
  text-decoration: underline;
  color: #0563c1;
  line-height: 18px;
}
.ft9 {
  font: 15px "GothamBook";
  line-height: 18px;
}
.ft10 {
  font: 8px "GothamBook";
  color: #0563c1;
  line-height: 10px;
}
.ft11 {
  font: bold 16px "GothamBook";
  margin-left: 13px;
  line-height: 19px;
}
.ft12 {
  font: 8px "GothamBook";
  line-height: 10px;
}

.p0 {
  text-align: left;
  padding-left: 253px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p1 {
  text-align: justify;
  padding-right: 96px;
  margin-top: 19px;
  margin-bottom: 0px;
}
.p2 {
  text-align: justify;
  padding-right: 96px;
  margin-top: 2px;
  margin-bottom: 0px;
}
.p3 {
  text-align: left;
  margin-top: 18px;
  margin-bottom: 0px;
}
.p4 {
  text-align: left;
  padding-left: 24px;
  margin-top: 3px;
  margin-bottom: 0px;
}
.p5 {
  text-align: left;
  padding-left: 24px;
  margin-top: 2px;
  margin-bottom: 0px;
}
.p6 {
  text-align: left;
  padding-left: 24px;
  margin-top: 1px;
  margin-bottom: 0px;
}
.p7 {
  text-align: left;
  margin-top: 24px;
  margin-bottom: 0px;
}
.p8 {
  text-align: left;
  padding-left: 24px;
  margin-top: 18px;
  margin-bottom: 0px;
}
.p9 {
  text-align: left;
  padding-left: 24px;
  margin-top: 20px;
  margin-bottom: 0px;
}
.p10 {
  text-align: left;
  padding-right: 96px;
  margin-top: 2px;
  margin-bottom: 0px;
}
.p11 {
  text-align: left;
  padding-left: 24px;
  margin-top: 19px;
  margin-bottom: 0px;
}
.p12 {
  text-align: left;
  margin-top: 22px;
  margin-bottom: 0px;
}
.p13 {
  text-align: justify;
  padding-right: 91px;
  margin-top: 1px;
  margin-bottom: 0px;
}
.p14 {
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p15 {
  text-align: justify;
  padding-right: 97px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p16 {
  text-align: left;
  padding-left: 24px;
  margin-top: 21px;
  margin-bottom: 0px;
}
.p17 {
  text-align: justify;
  padding-right: 96px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p18 {
  text-align: left;
  padding-left: 24px;
  margin-top: 22px;
  margin-bottom: 0px;
}
.p19 {
  text-align: left;
  margin-top: 21px;
  margin-bottom: 0px;
}
.p20 {
  text-align: justify;
  padding-right: 96px;
  margin-top: 1px;
  margin-bottom: 0px;
}
</style>