<template>
  <a v-on:click="onDashboardClick" v-if="isLoggedIn && isShopConfigured">
    <button class="main-button">
      {{ $t("home.message_button1") }}
    </button>
  </a>
  <router-link v-else-if="isLoggedIn && !isShopConfigured" to="/shop/choose">
    <button class="main-button">
      {{ $t("home.message_button1") }}
    </button>
  </router-link>
  <router-link v-else to="/startnow">
    <button class="main-button">
      {{ $t("home.message_button1") }}
    </button>
  </router-link>
</template>
<script>
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  setup() {
    const store = useStore();

    const isLoggedIn = computed(() => store.getters["auth/isLoggedIn"]);
    const isShopConfigured = computed(
      () => store.getters["auth/isShopConfigured"]
    );
    const shop = computed(() => store.getters["auth/shop"]);

    const onDashboardClick = async () => {
      store.dispatch("auth/openDashboard", {
        href: "https://" + shop.value.id + ".napps-solutions.com",
      });
    };

    return { isLoggedIn, isShopConfigured, onDashboardClick };
  },
});
</script>

<style scoped>
.main-button {
  background-color: #263238;
  padding: 12px 40px;
  color: white;
  border-radius: 5px;
  font-family: GothamBook, "sans-serif";
  width: 380px;
  text-transform: uppercase;
}
.main-button:focus {
  outline: none;
}
.main-button:hover {
  opacity: .8;
}

@media only screen and (max-width: 440px) {
  .main-button {
    width: 90vw;
  }
}

</style>