<template>
  <div class="main">
    <div class="flex mx-auto container container0">
      <section class="flex w-full h-screen">
        <nav-bar :solidBackground="true" :fillBG="isMobile" />
        <div class="text-box">
          <div class="flex flex-col py-5 title-box">
            <span class="top-text">{{
              $t("home.message_home_top_title")
            }}</span>
            <p class="title-banner">
              {{ $t("product.title") }}
            </p>
            <p class="desc-banner">
              {{ $t("product.description") }}
            </p>
            <start-now-button style="width: 200px; height: auto" />
          </div>
        </div>
        <div id="first-section-loading-target" class="asset-box mobile">
          <img
           loading="lazy"
            class="img-web-banner"
            alt="Dashboard mockup"
            src="https://d3dx13u2x4z410.cloudfront.net/napps-mockup%202.png"
          />
        </div>
        <div id="first-section-loading-target" class="asset-box web">
          <img
             loading="lazy"
            class="img-web-banner"
            alt="Dashboard mockup"
            src="https://d3dx13u2x4z410.cloudfront.net/napps-mockup%202.png"
          />
        </div>
      </section>
    </div>
    <div class="flex mx-auto container container1">
      <section
        style="margin-bottom: 548px; padding-top: 180px"
        class="flex w-full"
      >
        <div class="headline-column w-2/5">
          <div class="headline">
            <h1 class="title-section">
              {{ $t("product.section_title") }}
            </h1>
          </div>
        </div>
        <div class="box-items">
          <div style="opacity: 1 !important" class="box-item box1">
            <div class="item-video">
              <video
                id="video-1"
                class="video-box first-part"
                width="220"
                height="430"
                preload="metadata"
                loop
                muted
                playsinline
              >
                <source
                  src="https://d3dx13u2x4z410.cloudfront.net/UserAccount.mp4"
                  type="video/mp4"
                />
                >
              </video>
            </div>
            <div class="item-text-section">
              <h3 class="item-title">
                {{ $t("product.account_title") }}
              </h3>
              <div class="item-body">
                <p>{{ $t("product.account_message") }}</p>
              </div>
            </div>
          </div>
          <div class="box-item top-space box2">
            <div class="item-video">
              <video
                id="video-2"
                class="video-box first-part"
                width="220"
                height="430"
                preload="metadata"
                loop
                muted
                playsinline
              >
                <source
                  src="https://d3dx13u2x4z410.cloudfront.net/Filter.mp4"
                  type="video/mp4"
                />
                >
              </video>
            </div>
            <div class="item-text-section">
              <h3 class="item-title">
                {{ $t("product.filter_title") }}
              </h3>
              <div class="item-body">
                <p>{{ $t("product.filter_message") }}</p>
              </div>
            </div>
          </div>
          <div class="box-item box3">
            <div class="item-video">
              <video
                id="video-3"
                class="video-box first-part"
                width="220"
                height="430"
                preload="none"
                loop
                muted
                playsinline
              >
                <source
                  src="https://d3dx13u2x4z410.cloudfront.net/ProductDetail.mp4"
                  type="video/mp4"
                />
                > >
              </video>
            </div>
            <div class="item-text-section">
              <h3 class="item-title">
                {{ $t("product.product_title") }}
              </h3>
              <div class="item-body">
                <p>{{ $t("product.product_message") }}</p>
              </div>
            </div>
          </div>
          <div class="box-item top-space box4">
            <div class="item-video">
              <video
                id="video-4"
                class="video-box first-part"
                width="220"
                height="430"
                preload="none"
                loop
                muted
                playsinline
              >
                <source
                  src="https://d3dx13u2x4z410.cloudfront.net/Wishlist_Video.mp4"
                  type="video/mp4"
                />
                >
              </video>
            </div>
            <div class="item-text-section">
              <h3 class="item-title">
                {{ $t("product.wishlist_title") }}
              </h3>
              <div class="item-body">
                <p>{{ $t("product.wishlist_message") }}</p>
              </div>
            </div>
          </div>
          <div id="second-section-loading-target" class="box-item box5">
            <div class="item-video">
              <video
                id="video-5"
                class="video-box first-part"
                width="220"
                height="430"
                preload="none"
                loop
                muted
                playsinline
              >
                <source
                  src="https://d3dx13u2x4z410.cloudfront.net/Carrinho_Video.mp4"
                  type="video/mp4"
                />
                >
              </video>
            </div>
            <div class="item-text-section">
              <h3 class="item-title">{{ $t("product.cart_title") }}</h3>
              <div class="item-body">
                <p>{{ $t("product.cart_message") }}</p>
              </div>
            </div>
          </div>
          <div class="box-item top-space box6">
            <div class="item-video">
              <video
                id="video-6"
                class="video-box first-part"
                width="220"
                height="430"
                preload="none"
                loop
                muted
                playsinline
              >
                <source
                  src="https://d3dx13u2x4z410.cloudfront.net/Checkout_Video.mp4"
                  type="video/mp4"
                />
                >
              </video>
            </div>
            <div class="item-text-section">
              <h3 class="item-title">
                {{ $t("product.checkout_title") }}
              </h3>
              <div class="item-body">
                <p>{{ $t("product.checkout_message") }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="flex mx-auto container container2">
      <section
        style="margin-bottom: 548px; padding-top: 180px"
        class="flex w-full"
      >
        <div class="headline-column w-2/5">
          <div class="headline">
            <h1 class="title-section red-section">
              {{ $t("product.push_title") }}
            </h1>
          </div>
        </div>
        <div class="box-items">
          <div style="opacity: 1 !important" class="box-item box-item2 box2-1">
            <div class="item-video">
              <video
                id="video-2-1"
                class="video-box second-part"
                width="220"
                height="430"
                loop
                muted
                playsinline
              >
                <source
                  src="https://d3dx13u2x4z410.cloudfront.net/PromotionNotification.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div class="item-text-section">
              <h3 class="item-title red-section">
                {{ $t("product.push_title") }}
              </h3>
              <div class="item-body">
                <p class="red-section">
                  {{ $t("product.push_message") }}
                </p>
              </div>
            </div>
          </div>
          <div class="box-item top-space box-item2 box2-2">
            <div class="item-video">
              <video
                id="video-2-2"
                class="video-box second-part"
                width="220"
                height="430"
                loop
                muted
                playsinline
              >
                <source
                  src="https://d3dx13u2x4z410.cloudfront.net/AbandonedCart.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div class="item-text-section">
              <h3 class="item-title red-section">
                {{ $t("product.abandoned_title") }}
              </h3>
              <div class="item-body">
                <p class="red-section">
                  {{ $t("product.abandoned_message") }}
                </p>
              </div>
            </div>
          </div>
          <div class="box-item box-item2 box2-3">
            <div id="push-video-loading-target" class="item-video">
              <video
                id="video-2-3"
                class="video-box second-part"
                width="220"
                height="430"
                loop
                muted
                playsinline
              >
                <source
                  src="https://d3dx13u2x4z410.cloudfront.net/BackinStock.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div class="item-text-section">
              <h3 class="item-title red-section">
                {{ $t("product.stock_title") }}
              </h3>
              <div class="item-body">
                <p class="red-section">
                  {{ $t("product.stock_message") }}
                </p>
              </div>
            </div>
          </div>
          <div class="box-item box-item2 top-space box2-4">
            <div class="item-video">
              <video
                id="video-2-4"
                class="video-box second-part"
                width="220"
                height="430"
                loop
                muted
                playsinline
              >
                <source
                  src="https://d3dx13u2x4z410.cloudfront.net/SegmentedPush.mp4"
                  type="video/mp4"
                />
                >
              </video>
            </div>
            <div class="item-text-section">
              <h3 class="item-title red-section">
                {{ $t("product.segmented_title") }}
              </h3>
              <div class="item-body">
                <p class="red-section">
                  {{ $t("product.segmented_message") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-if="!isMobile" class="mx-auto container container3 web">
      <section
        style="margin-bottom: 148px; padding-top: 180px"
        class="flex w-full flex-col"
      >
        <div class="flex">
          <h1 class="title-section green-section">
            {{ $t("product.section_title3") }}
          </h1>
        </div>
        <div class="web-box-items">
          <div class="web-box-item flex">
            <div class="web-box-text items-center justify-center">
              <h3 class="item-title web-text-title green-section">
                {{ $t("product.create_push_title") }}
              </h3>
              <div class="item-body web-text">
                <p class="green-secton green-text">
                  {{ $t("product.create_push_message") }}
                </p>
              </div>
            </div>
            <div class="web-box-video">
              <video
                class="video-web-box"
                preload="none"
                loop
                muted
                playsinline
              >
                <source
                  src="https://d3dx13u2x4z410.cloudfront.net/CreatePush.mp4"
                  type="video/mp4"
                />
                >
              </video>
            </div>
          </div>
          <div class="web-box-item flex">
            <div class="web-box-video">
              <video
                class="video-web-box"
                preload="none"
                loop
                muted
                playsinline
              >
                <source
                  src="https://d3dx13u2x4z410.cloudfront.net/Drag-Drop.mp4"
                  type="video/mp4"
                />
                >
              </video>
            </div>
            <div class="web-box-text items-center justify-center">
              <h3 class="item-title web-text-title green-section">
                {{ $t("product.drag_title") }}
              </h3>
              <div class="item-body web-text">
                <p class="green-secton green-text">
                  {{ $t("product.drag_message") }}
                </p>
              </div>
            </div>
          </div>
          <div class="web-box-item flex">
            <div class="web-box-text items-center justify-center">
              <h3 class="item-title web-text-title green-section">
                {{ $t("product.branding_title") }}
              </h3>
              <div class="item-body web-text">
                <p class="green-secton green-text">
                  {{ $t("product.branding_message") }}
                </p>
              </div>
            </div>
            <div class="web-box-video">
              <video
                class="video-web-box"
                preload="none"
                loop
                muted
                playsinline
              >
                <source
                  src="https://d3dx13u2x4z410.cloudfront.net/Branding.mp4"
                  type="video/mp4"
                />
                >
              </video>
            </div>
          </div>
          <!---
          <div class="web-box-item flex">
            <div class="web-box-text items-center justify-center">
              <h3 class="item-title web-text-title green-section">
                {{ $t("product.message_settings_title") }}
              </h3>
              <div class="item-body web-text">
                <p class="green-secton green-text">
                  {{ $t("product.message_marketing_message") }}
                </p>
              </div>
            </div>
            <div class="web-box-video">
              <video class="video-web-box" muted loop>
                <source
                  src="https://d3e54v103j8qbb.cloudfront.net/videos/zendesk.mp4"
                  type="video/mp4"
                />
                >
              </video>
            </div>
          </div>!-->
        </div>
      </section>
    </div>
    <footer2 />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import NavBar from "@/components/Navbar/Main.vue";
import StartNowButton from "@/components/StartNowButton.vue";
import Footer2 from "@/components/Footer/Main.vue";

gsap.registerPlugin(ScrollTrigger);

export default defineComponent({
  components: {
    NavBar,
    StartNowButton,
    Footer2,
  },
  setup() {
    return {};
  },
  unmounted() {
    window.scrollTo(0, 0);
    ScrollTrigger.getAll().forEach((element) => {
      element.kill();
    });
  },
  data: () => {
    return {
      isMobile: false,
    };
  },
  mounted() {
    this.isMobile = window.innerWidth < 1050;
    var self = this;

    this.$nextTick(function () {
      //1550px
      ScrollTrigger.matchMedia({
        "(min-width: 700px)": function () {
          ScrollTrigger.create({
            trigger: ".box1",
            start: "top 50%",
            end: "bottom 70%",
            markers: true,
            onEnter: () => {
              gsap.to(".box1", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-1");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box1", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-1");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box1", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-1");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box1", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-1");
              vid.play();
            },
            markers: false,
          });

          ScrollTrigger.create({
            trigger: ".box2",
            start: "top 35%",
            end: "bottom 70%",
            onEnter: () => {
              gsap.to(".box2", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box2", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box2", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box2", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2");
              vid.play();
            },
            markers: false,
          });

          ScrollTrigger.create({
            trigger: ".box3",
            start: "top 35%",
            end: "bottom 70%",
            onEnter: () => {
              gsap.to(".box3", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-3");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box3", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-3");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box3", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-3");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box3", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-3");
              vid.play();
            },
            markers: false,
          });

          ScrollTrigger.create({
            trigger: ".box4",
            start: "top 35%",
            end: "bottom 70%",
            onEnter: () => {
              gsap.to(".box4", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-4");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box4", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-4");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box4", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-4");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box4", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-4");
              vid.play();
            },
            markers: false,
          });
          ScrollTrigger.create({
            trigger: ".box5",
            start: "top 35%",
            end: "bottom 70%",
            onEnter: () => {
              gsap.to(".box5", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-5");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box5", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-5");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box5", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-5");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box5", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-5");
              vid.play();
            },
            markers: false,
          });
          ScrollTrigger.create({
            trigger: ".box6",
            start: "top 35%",
            end: "bottom 70%",
            onEnter: () => {
              gsap.to(".box6", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-6");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box6", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-6");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box6", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-6");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box6", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-6");
              vid.play();
            },
            markers: false,
          });

          ScrollTrigger.create({
            trigger: ".box2-1",
            start: "top 50%",
            end: "bottom 70%",
            onEnter: () => {
              gsap.to(".box2-1", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2-1");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box2-1", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2-1");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box2-1", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2-1");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box2-1", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2-1");
              vid.play();
            },
            markers: false,
          });

          ScrollTrigger.create({
            trigger: ".box2-2",
            start: "top 35%",
            end: "bottom 70%",
            onEnter: () => {
              gsap.to(".box2-2", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2-2");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box2-2", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2-2");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box2-2", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2-2");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box2-2", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2-2");
              vid.play();
            },
            markers: false,
          });

          ScrollTrigger.create({
            trigger: ".box2-3",
            start: "top 35%",
            end: "bottom 70%",
            onEnter: () => {
              gsap.to(".box2-3", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2-3");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box2-3", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2-3");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box2-3", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2-3");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box2-3", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2-3");
              vid.play();
            },
            markers: false,
          });

          ScrollTrigger.create({
            trigger: ".box2-4",
            start: "top 35%",
            end: "bottom 70%",
            onEnter: () => {
              gsap.to(".box2-4", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2-4");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box2-4", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2-4");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box2-4", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2-4");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box2-4", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2-4");
              vid.play();
            },
            markers: false,
          });
          if (!self.isMobile) {
            ScrollTrigger.create({
              trigger: ".container3",
              start: "top 35%",
              end: "bottom 45%",
              markers: false,
              onEnter: () => {
                gsap.to(".main", { backgroundColor: "#263238", duration: 0.3 });
                gsap.to("#nav-bar-background", {
                  backgroundColor: "#263238",
                  duration: 0.3,
                });
                gsap.to(".logo-white", {
                  display: "block",
                  duration: 0.0,
                });
                gsap.to(".logo-dark", {
                  display: "none",
                  duration: 0.0,
                });
                gsap.to("a", {
                  color: "white",
                  duration: 0.3,
                });
                gsap.to(".button-nav", {
                  border: "1px solid white",
                  duration: 0.3,
                });
                gsap.to(".button-fill", {
                  backgroundColor: "#FFFFFF",
                  duration: 0.3,
                });
                gsap.to(".button-fill", {
                  border: "1px solid white",
                  duration: 0.3,
                });
                gsap.to(".button-fill", {
                  color: "#263238",
                  duration: 0.3,
                });
              },
              onEnterBack: () => {
                gsap.to(".main", { backgroundColor: "#263238", duration: 0.3 });
                gsap.to(".mobile-navbar-expanded", {
                  backgroundColor: "unset",
                  duration: 0.3,
                });
                gsap.to("#nav-bar-background", {
                  backgroundColor: "#263238",
                  duration: 0.3,
                });
                gsap.to(".logo-white", {
                  display: "block",
                  duration: 0.0,
                });
                gsap.to(".logo-dark", {
                  display: "none",
                  duration: 0.0,
                });
                gsap.to("a", {
                  color: "white",
                  duration: 0.3,
                });
                gsap.to(".button-outline", {
                  border: "1px solid white",
                  duration: 0.3,
                });
                gsap.to(".button-fill", {
                  border: "1px solid white",
                  duration: 0.3,
                });
                gsap.to(".button-fill", {
                  backgroundColor: "white",
                  duration: 0.3,
                });
                gsap.to(".button-fill", {
                  color: "#263238",
                  duration: 0.3,
                });
              },
              onLeave: () => {
                gsap.to(".main", { backgroundColor: "#F8F8F8", duration: 0.3 });
                gsap.to(".mobile-navbar-expanded", {
                  backgroundColor: "#F8F8F8",
                  duration: 0.3,
                });
                gsap.to("#nav-bar-background", {
                  backgroundColor: "#F8F8F8",
                  duration: 0.3,
                });
                gsap.to(".logo-white", {
                  display: "none",
                  duration: 0.0,
                });
                gsap.to(".logo-dark", {
                  display: "block",
                  duration: 0.0,
                });
                gsap.to("a", {
                  color: "black",
                  duration: 0.3,
                });
                gsap.to(".button-nav", {
                  border: "1px solid #263238",
                  duration: 0.3,
                });
              },
              markers: false,
            });
          }
        },
        "(min-width: 1550px)": function () {
          ScrollTrigger.create({
            trigger: ".box1",
            start: "top 40%",
            end: "bottom 90%",
            onEnter: () => {
              gsap.to(".box1", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-1");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box1", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-1");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box1", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-1");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box1", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-1");
              vid.play();
            },
            markers: false,
          });

          ScrollTrigger.create({
            trigger: ".box2",
            start: "top 40%",
            end: "bottom 60%",
            onEnter: () => {
              gsap.to(".box2", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box2", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box2", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box2", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2");
              vid.play();
            },
            markers: false,
          });

          ScrollTrigger.create({
            trigger: ".box3",
            start: "top 50%",
            end: "bottom 80%",
            onEnter: () => {
              gsap.to(".box3", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-3");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box3", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-3");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box3", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-3");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box3", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-3");
              vid.play();
            },
            markers: false,
          });

          ScrollTrigger.create({
            trigger: ".box4",
            start: "top 40%",
            end: "bottom 60%",
            onEnter: () => {
              gsap.to(".box4", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-4");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box4", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-4");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box4", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-4");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box4", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-4");
              vid.play();
            },
            markers: false,
          });
          ScrollTrigger.create({
            trigger: ".box5",
            start: "top 60%",
            end: "bottom 60%",
            onEnter: () => {
              gsap.to(".box5", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-5");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box5", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-5");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box5", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-5");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box5", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-5");
              vid.play();
            },
            markers: false,
          });
          ScrollTrigger.create({
            trigger: ".box6",
            start: "top 60%",
            end: "bottom 60%",
            onEnter: () => {
              gsap.to(".box6", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-6");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box6", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-6");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box6", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-6");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box6", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-6");
              vid.play();
            },
            markers: false,
          });

          ScrollTrigger.create({
            trigger: ".box2-1",
            start: "top 50%",
            end: "bottom 60%",
            onEnter: () => {
              gsap.to(".box2-1", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2-1");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box2-1", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2-1");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box2-1", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2-1");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box2-1", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2-1");
              vid.play();
            },
            markers: false,
          });

          ScrollTrigger.create({
            trigger: ".box2-2",
            start: "top 20%",
            end: "bottom 60%",
            onEnter: () => {
              gsap.to(".box2-2", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2-2");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box2-2", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2-2");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box2-2", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2-2");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box2-2", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2-2");
              vid.play();
            },
            markers: false,
          });

          ScrollTrigger.create({
            trigger: ".box2-3",
            start: "top 30%",
            end: "bottom 60%",
            onEnter: () => {
              gsap.to(".box2-3", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2-3");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box2-3", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2-3");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box2-3", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2-3");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box2-3", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2-3");
              vid.play();
            },
            markers: false,
          });

          ScrollTrigger.create({
            trigger: ".box2-4",
            start: "top 30%",
            end: "bottom 60%",
            onEnter: () => {
              gsap.to(".box2-4", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2-4");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box2-4", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2-4");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box2-4", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2-4");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box2-4", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2-4");
              vid.play();
            },
            markers: false,
          });

          if (!self.isMobile) {
            ScrollTrigger.create({
              trigger: ".container3",
              start: "top 35%",
              end: "bottom 60%",
              onEnter: () => {
                gsap.to(".main", { backgroundColor: "#263238", duration: 0.3 });
                gsap.to("#nav-bar-background", {
                  backgroundColor: "#263238",
                  duration: 0.3,
                });
                gsap.to(".logo-white", {
                  display: "block",
                  duration: 0.0,
                });
                gsap.to(".logo-dark", {
                  display: "none",
                  duration: 0.0,
                });
                gsap.to("a", {
                  color: "white",
                  duration: 0.0,
                });
                gsap.to(".button-outline", {
                  border: "1px solid white",
                  duration: 0.3,
                });
                gsap.to(".button-fill", {
                  border: "1px solid white",
                  duration: 0.3,
                });
                gsap.to(".button-fill", {
                  backgroundColor: "white",
                  duration: 0.3,
                });
                gsap.to(".button-fill", {
                  color: "#263238",
                  duration: 0.3,
                });
              },
              onEnterBack: () => {
                gsap.to(".main", { backgroundColor: "#263238", duration: 0.3 });
                gsap.to("#nav-bar-background", {
                  backgroundColor: "#263238",
                  duration: 0.3,
                });
                gsap.to(".logo-white", {
                  display: "block",
                  duration: 0.0,
                });
                gsap.to(".logo-dark", {
                  display: "none",
                  duration: 0.0,
                });
                gsap.to("a", {
                  color: "white",
                  duration: 0.0,
                });
                gsap.to(".button-outline", {
                  border: "1px solid white",
                  duration: 0.3,
                });
                gsap.to(".button-fill", {
                  border: "1px solid white",
                  duration: 0.3,
                });
                gsap.to(".button-fill", {
                  backgroundColor: "white",
                  duration: 0.3,
                });
                gsap.to(".button-fill", {
                  color: "#263238",
                  duration: 0.3,
                });
              },
              onLeave: () => {
                gsap.to(".main", { backgroundColor: "#F8F8F8", duration: 0.3 });
                gsap.to("#nav-bar-background", {
                  backgroundColor: "#F8F8F8",
                  duration: 0.3,
                });
                gsap.to(".logo-white", {
                  display: "none",
                  duration: 0.0,
                });
                gsap.to(".logo-dark", {
                  display: "block",
                  duration: 0.0,
                });
                gsap.to("a", {
                  color: "black",
                  duration: 0.3,
                });
                gsap.to(".button-outline", {
                  border: "1px solid #263238",
                  duration: 0.3,
                });
                gsap.to(".button-fill", {
                  border: "1px solid #263238",
                  duration: 0.3,
                });
                gsap.to(".button-fill", {
                  backgroundColor: "#263238",
                  duration: 0.3,
                });
                gsap.to(".button-fill", {
                  color: "white",
                  duration: 0.3,
                });
              },
              markers: false,
            });
          }
        },
      });
      //End of 1550px queries
      if (!self.isMobile) {
        ScrollTrigger.create({
          trigger: ".container2",
          start: "top 70%",
          end: "bottom 70%",
          onEnterBack: () => {
            gsap.to(".main", { backgroundColor: "#F8F8F8", duration: 0.3 });
            gsap.to("#nav-bar-background", {
              backgroundColor: "#F8F8F8",
              duration: 0.3,
            });
            gsap.to(".logo-white", {
              display: "none",
              duration: 0.0,
            });
            gsap.to(".logo-dark", {
              display: "block",
              duration: 0.0,
            });

            //nav-item
            gsap.to(".logo-dark", {
              display: "block",
              duration: 0.0,
            });
          },
          markers: false,
        });

        ScrollTrigger.create({
          trigger: ".container2",
          start: "top 45%",
          end: "bottom 45%",
          onEnter: () => {
            gsap.to(".main", { backgroundColor: "#F8F8F8", duration: 0.3 });
            gsap.to("#nav-bar-background", {
              backgroundColor: "#F8F8F8",
              duration: 0.3,
            });
            gsap.to(".logo-white", {
              display: "none",
              duration: 0.0,
            });
            gsap.to("a", {
              color: "black",
              duration: 0.3,
            });
            gsap.to(".logo-dark", {
              display: "block",
              duration: 0.0,
            });
            gsap.to(".button-outline", {
              border: "1px solid #263238",
              duration: 0.3,
            });
            gsap.to(".button-fill", {
              border: "1px solid #263238",
              duration: 0.3,
            });
            gsap.to(".button-fill", {
              backgroundColor: "#263238",
              duration: 0.3,
            });
            gsap.to(".button-fill", {
              color: "white",
              duration: 0.3,
            });
          },
          onEnterBack: () => {
            gsap.to(".main", { backgroundColor: "#F8F8F8", duration: 0.3 });
            gsap.to("#nav-bar-background", {
              backgroundColor: "#F8F8F8",
              duration: 0.3,
            });
            gsap.to(".logo-white", {
              display: "none",
              duration: 0.0,
            });
            gsap.to("a", {
              color: "black",
              duration: 0.3,
            });
            gsap.to(".logo-dark", {
              display: "block",
              duration: 0.0,
            });
            gsap.to(".button-outline", {
              border: "1px solid #263238",
              duration: 0.3,
            });
            gsap.to(".button-fill", {
              border: "1px solid #263238",
              duration: 0.3,
            });
            gsap.to(".button-fill", {
              backgroundColor: "#263238",
              duration: 0.3,
            });
            gsap.to(".button-fill", {
              color: "white",
              duration: 0.3,
            });
          },
          markers: false,
        });

        ScrollTrigger.create({
          trigger: ".container1",
          start: "top 70%",
          end: "bottom 70%",
          markers: false,
          onEnterBack: () => {
            gsap.to(".main", { backgroundColor: "#263238", duration: 0.3 });
            gsap.to("#nav-bar-background", {
              backgroundColor: "#263238",
              duration: 0.3,
            });
            gsap.to(".logo-white", {
              display: "block",
              duration: 0.0,
            });
            gsap.to("a", {
              color: "white",
              duration: 0.3,
            });
            gsap.to(".logo-dark", {
              display: "none",
              duration: 0.0,
            });
            gsap.to(".button-outline", {
              border: "1px solid white",
              duration: 0.3,
            });
            gsap.to(".button-fill", {
              border: "1px solid white",
              duration: 0.3,
            });
            gsap.to(".button-fill", {
              backgroundColor: "white",
              duration: 0.3,
            });
            gsap.to(".button-fill", {
              color: "#263238",
              duration: 0.3,
            });
          },
        });

        ScrollTrigger.create({
          trigger: ".container0",
          start: "top 25%",
          end: "bottom 25%",
          onEnterBack: () => {
            gsap.to(".main", { backgroundColor: "#F8F8F8", duration: 0.3 });
            gsap.to("#nav-bar-background", {
              backgroundColor: "#F8F8F8",
              duration: 0.3,
            });
            gsap.to(".logo-white", {
              display: "none",
              duration: 0.0,
            });
            gsap.to("a", {
              color: "black",
              duration: 0.3,
            });
            gsap.to(".logo-dark", {
              display: "block",
              duration: 0.0,
            });
            gsap.to(".button-outline", {
              border: "1px solid #263238",
              duration: 0.3,
            });
            gsap.to(".button-fill", {
              border: "1px solid #263238",
              duration: 0.3,
            });
            gsap.to(".button-fill", {
              backgroundColor: "#263238",
              duration: 0.3,
            });
            gsap.to(".button-fill", {
              color: "white",
              duration: 0.3,
            });
          },
          onLeave: () => {
            gsap.to(".main", { backgroundColor: "#263238", duration: 0.3 });
            gsap.to("#nav-bar-background", {
              backgroundColor: "#263238",
              duration: 0.3,
            });
            gsap.to(".logo-white", {
              display: "block",
              duration: 0.0,
            });
            gsap.to("a", {
              color: "white",
              duration: 0.3,
            });
            gsap.to(".logo-dark", {
              display: "none",
              duration: 0.0,
            });
            gsap.to(".button-outline", {
              border: "1px solid white",
              duration: 0.3,
            });
            gsap.to(".button-fill", {
              border: "1px solid white",
              duration: 0.3,
            });
            gsap.to(".button-fill", {
              backgroundColor: "white",
              duration: 0.3,
            });
            gsap.to(".button-fill", {
              color: "#263238",
              duration: 0.3,
            });
          },
          markers: false,
        });
      }
      ScrollTrigger.matchMedia({
        // desktop
        "(max-width: 1024px)": function () {
          ScrollTrigger.create({
            trigger: ".box1",
            start: "top 20%",
            end: "bottom 50%",
            onEnter: () => {
              gsap.to(".box1", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-1");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box1", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-1");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box1", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-1");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box1", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-1");
              vid.play();
            },
            markers: false,
          });

          ScrollTrigger.create({
            trigger: ".box2",
            start: "top 20%",
            end: "bottom 50%",
            onEnter: () => {
              gsap.to(".box2", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box2", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box2", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box2", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2");
              vid.play();
            },
            markers: false,
          });

          ScrollTrigger.create({
            trigger: ".box3",
            start: "top 30%",
            end: "bottom 50%",
            onEnter: () => {
              gsap.to(".box3", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-3");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box3", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-3");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box3", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-3");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box3", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-3");
              vid.play();
            },
            markers: false,
          });

          ScrollTrigger.create({
            trigger: ".box4",
            start: "top 30%",
            end: "bottom 50%",
            onEnter: () => {
              gsap.to(".box4", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-4");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box4", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-4");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box4", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-4");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box4", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-4");
              vid.play();
            },
            markers: false,
          });
          ScrollTrigger.create({
            trigger: ".box5",
            start: "top 30%",
            end: "bottom 50%",
            onEnter: () => {
              gsap.to(".box5", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-5");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box5", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-5");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box5", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-5");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box5", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-5");
              vid.play();
            },
            markers: false,
          });
          ScrollTrigger.create({
            trigger: ".box6",
            start: "top 30%",
            end: "bottom 50%",
            onEnter: () => {
              gsap.to(".box6", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-6");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box6", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-6");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box6", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-6");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box6", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-6");
              vid.play();
            },
            markers: false,
          });

          ScrollTrigger.create({
            trigger: ".box2-1",
            start: "top 50%",
            end: "bottom 50%",
            onEnter: () => {
              gsap.to(".box2-1", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2-1");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box2-1", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2-1");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box2-1", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2-1");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box2-1", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2-1");
              vid.play();
            },
            markers: false,
          });

          ScrollTrigger.create({
            trigger: ".box2-2",
            start: "top 20%",
            end: "bottom 50%",
            onEnter: () => {
              gsap.to(".box2-2", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2-2");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box2-2", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2-2");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box2-2", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2-2");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box2-2", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2-2");
              vid.play();
            },
            markers: false,
          });

          ScrollTrigger.create({
            trigger: ".box2-3",
            start: "top 30%",
            end: "bottom 50%",
            onEnter: () => {
              gsap.to(".box2-3", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2-3");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box2-3", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2-3");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box2-3", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2-3");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box2-3", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2-3");
              vid.play();
            },
            markers: false,
          });

          ScrollTrigger.create({
            trigger: ".box2-4",
            start: "top 30%",
            end: "bottom 50%",
            onEnter: () => {
              gsap.to(".box2-4", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2-4");
              vid.play();
            },
            onLeave: () => {
              gsap.to(".box2-4", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2-4");
              vid.pause();
            },
            onLeaveBack: () => {
              gsap.to(".box2-4", { opacity: 0.4, duration: 0.5 });
              let vid = document.getElementById("video-2-4");
              vid.pause();
            },
            onEnterBack: () => {
              gsap.to(".box2-4", { opacity: 1, duration: 0.5 });
              let vid = document.getElementById("video-2-4");
              vid.play();
            },
            markers: false,
          });
          if (!self.isMobile) {
            ScrollTrigger.create({
              trigger: ".container3",
              start: "top 45%",
              end: "bottom 75%",
              onEnter: () => {
                gsap.to(".main", { backgroundColor: "#263238", duration: 0.3 });
                gsap.to(".logo-white", {
                  display: "block",
                  duration: 0.0,
                });
                gsap.to("a", {
                  color: "white",
                  duration: 0.3,
                });
                gsap.to(".logo-dark", {
                  display: "none",
                  duration: 0.0,
                });
                gsap.to(".button-nav", {
                  border: "1px solid white",
                  duration: 0.3,
                });
              },
              onEnterBack: () => {
                gsap.to(".main", { backgroundColor: "#263238", duration: 0.3 });
                gsap.to(".logo-white", {
                  display: "block",
                  duration: 0.0,
                });
                gsap.to("a", {
                  color: "white",
                  duration: 0.3,
                });
                gsap.to(".button-nav", {
                  border: "1px solid white",
                  duration: 0.3,
                });
                gsap.to(".logo-dark", {
                  display: "none",
                  duration: 0.0,
                });
              },
              onLeave: () => {
                gsap.to(".main", { backgroundColor: "#F8F8F8", duration: 0.3 });
                gsap.to(".logo-white", {
                  display: "none",
                  duration: 0.0,
                });
                gsap.to("a", {
                  color: "black",
                  duration: 0.3,
                });
                gsap.to(".button-nav", {
                  border: "1px solid #263238",
                  duration: 0.3,
                });
                gsap.to(".logo-dark", {
                  display: "block",
                  duration: 0.0,
                });
              },
              markers: false,
            });
          }
          ScrollTrigger.create({
            trigger: ".container0",
            start: "top 25%",
            end: "bottom 75%",
            onEnterBack: () => {
              gsap.to(".main", { backgroundColor: "#F8F8F8", duration: 0.3 });
            },
            onLeave: () => {
              gsap.to(".main", { backgroundColor: "#263238", duration: 0.3 });
            },
            markers: false,
          });
          ScrollTrigger.create({
            trigger: ".container2",
            start: "top 25%",
            end: "bottom 75%",
            onEnter: () => {
              gsap.to(".main", { backgroundColor: "#F8F8F8", duration: 0.3 });
            },
            onLeaveBack: () => {
              gsap.to(".main", { backgroundColor: "#263238", duration: 0.3 });
            },
            markers: false,
          });
        },
      });

      ScrollTrigger.create({
        trigger: "#first-section-loading-target",
        start: "bottom 80%",
        markers: false,
        onEnter: () => {
          const elements = document.getElementsByClassName("first-part");
          for (let i = 0; i < elements.length; i++) {
            const video = elements[i];
            if (video.readyState !== 4) {
              video.load();
            }
          }
        },
      });
      ScrollTrigger.create({
        trigger: "#second-section-loading-target",
        start: "top",
        markers: false,
        onEnter: () => {
          const elements = document.getElementsByClassName("second-part");
          for (let i = 0; i < elements.length; i++) {
            const video = elements[i];
            if (video.readyState !== 4) {
              video.load();
            }
          }
        },
      });
      ScrollTrigger.create({
        trigger: "#push-video-loading-target",
        start: "top",
        markers: false,
        onEnter: () => {
          const elements = document.getElementsByClassName("video-web-box");
          for (let i = 0; i < elements.length; i++) {
            const video = elements[i];
            if (video.readyState !== 4) {
              video.play();
            }
          }
        },
      });
    });
  },
});
</script>

<style scoped>


.bg-yellow {
  background-color: #fbbc04;
}

.title-banner {
  font-family: GothamBold;
  text-transform: uppercase;
  color: #263238;
  font-size: 50px;
  line-height: 55px;
}

.box-item2 > .item-video {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.text-black {
  font-family: GothamMedium;
}

.main {
  background-color: #ffffff;
}

.desc-banner {
  color: #263238;
  font-family: GothamBook;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.top-text {
  text-transform: uppercase;
  color: #fbbc04;
  font-family: GothamBook;
  font-size: 18px;
  margin-bottom: 5px;
}

.mobile {
  display: none;
}

.container0 > section {
  flex-flow: row wrap;
  flex: 2;
  display: flex;
  -webkit-box-flex: 2;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  align-items: center;
}

.text-box {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 50%;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
}

.asset-box {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 50%;
  position: relative;
}

.asset-box.web {
  align-items: center;
  justify-content: center;
  display: flex;
}

.img-web-banner {
  position: absolute;
  width: auto;
  height: auto;
  border-radius: 20px;
}

.video-banner {
  position: absolute;
  width: auto;
  height: 60vh;
  right: 0px;
  top: 245px;
  border-radius: 10px;
}

.box-items {
  flex-flow: row wrap;
  flex: 2;
  display: flex;
  -webkit-box-flex: 2;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
}
.box-item {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  min-height: 758px;
  width: 50%;
  position: relative;
  text-align: center;
  opacity: 0.3;
}
.title-section {
  font-size: 38px;
  font-weight: 700;
  letter-spacing: -0.00658em;
  line-height: 1.15789;
  font-family: GothamBold, "sans-serif";
  color: #f8f8f8;
}

.item-body > p {
  text-align: center;
  color: #f8f8f8;
  font-family: GothamBook;
}

.headline-column {
  flex: 1;
  -webkit-box-flex: 1;
}

.headline {
  left: 0;
  padding-right: 60px;
  position: -webkit-sticky;
  position: sticky;
  text-align: left;
  top: 120px;
  padding-right: 80px;
}

.item-video {
  margin-left: auto;
  margin-right: auto;
  max-width: 320px;
  background-color: rgba(253, 253, 253, 0.2);
  padding: 30px 0;
  min-height: 500px;
}

.item-text-section {
  margin-left: auto;
  margin-right: auto;
  max-width: 260px;
}

.item-title {
  padding-top: 24px;
  padding-bottom: 8px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  font-family: GothamMedium;
  color: #f8f8f8;
}
.top-space {
  top: 260px;
}

.video-box {
  border-radius: 8px;
  overflow: hidden !important;
  display: inline-block;
  position: relative;
  min-height: 430px;
}

.red-section {
  color: #263238 !important;
}

p {
  text-align: left;
}
.web-box-items {
  flex-flow: row wrap;
  flex: 2;
  display: flex;
  -webkit-box-flex: 2;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
}
.web-box-item {
  display: flex;
  margin-top: 50px;
  margin-bottom: 50px;
}
_::-webkit-full-page-media,
_:future,
:root .web-box-item {
  max-height: 700px;
}

_::-webkit-full-page-media,
_:future,
:root .text-xl {
  margin-top: 15px;
  margin-bottom: 15px;
}

_::-webkit-full-page-media,
_:future,
:root .text-6xl {
  margin-top: 10px;
}
.web-box-text {
  flex: 1;
  display: flex;
  flex-flow: column;
  -webkit-box-flex: 1;
}
.web-box-video {
  flex: 2;
  display: flex;
  -webkit-box-flex: 2;
  padding: 50px;
  background-color: rgba(255, 255, 255, 0.2);
}
.video-web-box {
  border-radius: 8px;
  overflow: hidden !important;
  display: inline-block;
  position: relative;
  height: auto;
  width: 60vw;
}
.green-text {
  font-family: GothamMedium;
  text-align: center;
}
.green-section {
  color: #f8f8f8;
}
.web-text-title {
  padding-top: 24px;
  padding-bottom: 8px;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.4;
  font-family: Gotham, "sans-serif";
}
.web-text {
  padding-top: 14px;
  padding-bottom: 8px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  font-family: Gotham, "sans-serif";
  color: #534c6f;
  max-width: 350px;
}
@media screen and (max-width: 1560px) {
  .title-box {
    margin-top: 2rem !important;
  }
  .img-web-banner {
    height: auto;
    position: relative !important;
    top: 25%;
    left: 0px;
  }
  .video-banner {
    height: 60vh !important;
    top: 180px !important;
    right: 500px;
  }
  .video-banner {
    display: none;
  }
  .web-text-title  {
    text-align: center;
  }
}
@media screen and (max-width: 1050px) {
  .title-section {
    font-size: 30px;
    line-height: 45px;
    font-weight: 400;
  }
  .container1 > section {
    flex-direction: column;
  }
  .container2 > section {
    flex-direction: column;
  }
  .title-banner {
    font-size: 30px;
    line-height: 40px;
  }
  .container0 > section {
    height: fit-content;
  }
  .container0 > div {
    height: auto;
  }
  .img-web-banner {
    margin-top: 50px;
    margin-bottom: 300px;
  }
  .main-button {
    margin-left: 0px;
  }
  .img-web-banner {
    height: 250px;
  }
  .asset-box {
    width: 100%;
    margin-bottom: 30px;
    margin-top: 10px !important;
  }
  .main {
    padding-right: 0px;
    padding-left: 0px;
  }
  .text-xl {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .desc-banner {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    max-width: 90%;
  }
  .text-box {
    top: 10%;
    margin-right: 5px;
    margin-left: 5px;
    width: 100%;
  }
  .title-box {
    margin-top: 0px !important;
  }
  .web {
    display: none !important;
  }
  .mobile {
    display: flex;
  }
  .box-items {
    flex-flow: column;
    align-items: center;
  }
  .top-space {
    top: 0px;
  }
  .headline-column {
    margin: 0px;
    width: 100%;
  }
  .headline-column > .headline {
    text-align: center;
    padding: 0px;
    font-size: 30px;
  }
  section > div {
    margin-top: 50px !important;
    margin-left: 20px;
    margin-right: 20px;
  }
  .video-banner {
    display: none;
  }
  .item-video {
    padding: 40px 10px 40px 10px;
    min-height: 400px;
  }
  .box-item {
    width: 100%;
    opacity: 1 !important;
  }
  .container1 > section {
    padding-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .web-box-item {
    flex-direction: column;
  }
  .web-box-video {
    padding: 20px;
  }
  .video-web-box {
    width: 100vw !important;
  }
  section {
    margin-bottom: 0px !important;
  }
  .title-box {
    margin-bottom: 0px;
  }
  .web-text-title {
    font-size: 22px;
  }
  .web-text > p {
    font-size: 16px;
  }
  .text-6xl {
    margin-top: 5px;
    font-size: 40px;
  }
  _::-webkit-full-page-media,
  _:future,
  :root .web-box-item {
    max-height: 300px !important;
  }
}
</style>
